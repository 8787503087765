import {
  SubscriberResponse,
  SubscriptionList,
  useNewsletterSubscriberSA,
  useSubscribeToNewsletterSA,
  useUnsubscribeToNewsletterSA,
} from '@va/dashboard/api-client/newsletter-preferences';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useCallback, useEffect, useState } from 'react';
import { DashboardAppWorker } from '@va/util/misc';

const initialNewsletterState = {
  standalonePromotions: undefined,
  standaloneNewsletter: undefined,
};

export const useNewsletterPreferencesSa = () => {
  const [newsletterSubscription, setNewsletterSubscription] = useState<SubscriberResponse>(initialNewsletterState);
  const { showSuccessNotification, showErrorNotification } = useAddNotification();
  const { data: currentSubscription, mutate: mutateSubscription } = useNewsletterSubscriberSA();
  const { execute: executeSubscribe } = useSubscribeToNewsletterSA();
  const { execute: executeUnsubscribe } = useUnsubscribeToNewsletterSA();

  const isSubscribedByType = useCallback(
    (type: keyof typeof SubscriptionList) => newsletterSubscription[type],
    [newsletterSubscription],
  );

  useEffect(() => {
    if (!currentSubscription) return;
    setNewsletterSubscription(currentSubscription);
  }, [currentSubscription]);

  const onNewsletterChange = useCallback(
    async (value: keyof typeof SubscriptionList) => {
      const payload = { lists: [SubscriptionList[value]] };

      try {
        if (isSubscribedByType(value)) {
          await executeUnsubscribe(payload);
          sendNewsletterEvent(false);
        } else {
          await executeSubscribe(payload);
          sendNewsletterEvent(true);
        }
        showSuccessNotification();
      } catch (err) {
        showErrorNotification();
      } finally {
        mutateSubscription();
      }
    },
    [
      executeSubscribe,
      executeUnsubscribe,
      isSubscribedByType,
      mutateSubscription,
      showErrorNotification,
      showSuccessNotification,
    ],
  );

  return { onNewsletterChange, isSubscribedByType };
};

function sendNewsletterEvent(value: boolean) {
  DashboardAppWorker.trackEvent({
    action: 'onchange',
    category: 'toggle',
    label: 'Newsletter',
    value: value.toString(),
  });
}
