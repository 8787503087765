import { TEST_IDS } from '@va/constants';
import { EraseIcon } from '@va/icons';
import { useInnerOnChange } from '@va/util/hooks';
import classNames from 'classnames';
import { ChangeEvent, HTMLInputTypeAttribute, useCallback, useEffect, useState } from 'react';
import './FilterInput.scss';

type FilterInputProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  clearField?: () => void;
  value: string | number;
  name?: string;
  className?: string;
  type?: HTMLInputTypeAttribute;
  size?: number;
};

export const FilterInput = ({
  onChange,
  value,
  name,
  clearField,
  className,
  type,
  size: initialSize = 10,
}: FilterInputProps) => {
  const [size, setSize] = useState(initialSize);
  const { innerOnChange, innerValue } = useInnerOnChange(onChange, 500, value);

  const changeInputSize = useCallback(
    (value: string | number) => {
      if (!value) {
        setSize(initialSize);
        return;
      }
      const newSize = String(value).length;
      if (newSize > initialSize) {
        setSize(newSize);
      } else {
        setSize(initialSize);
      }
    },
    [initialSize],
  );

  useEffect(() => {
    changeInputSize(value);
  }, [changeInputSize, initialSize, value]);

  return (
    <div
      className={classNames(
        'flex items-center ring-1 ring-transparent focus-within:ring-primary active:ring-primary bg-white px-3 h-full',
        className,
      )}
    >
      <input
        type={type}
        size={size}
        onChange={(e) => {
          changeInputSize(e.target.value);
          innerOnChange(e);
        }}
        className='filter-input text-12 w-full font-medium h-full bg-transparent'
        value={innerValue}
        name={name}
      />
      {clearField && (
        <button type='button' onClick={clearField} data-testid={TEST_IDS.generic.buttons.erase}>
          <EraseIcon color='#969696' />
        </button>
      )}
    </div>
  );
};
