import { RadioButtonThick } from '@va/icons';
import { useTranslate } from '@va/localization';
import { PrivacyLevelsEnum } from '@va/standalone/shared/types';
import {
  fontWeights,
  GrayGradientDivider,
  Paragraph,
  paragraphSizes,
  ParagraphWithTooltip,
} from '@va/ui/design-system';
import classNames from 'classnames';
import { useCallback } from 'react';
import { LevelIcon, LockerIcon } from './DataPrivacyIllustration';

type DataPrivacyCardProps = {
  onClick: (value: PrivacyLevelsEnum) => void;
  selected: boolean;
  value: PrivacyLevelsEnum;
  baseKey: string;
};

export const DataPrivacyCard = ({ onClick, selected, value, baseKey }: DataPrivacyCardProps) => {
  const translate = useTranslate();

  const levelIconColor = useCallback(
    (level: PrivacyLevelsEnum) => {
      if (selected) {
        if (value >= level) return '#FFFFFF';
        return '#6DA8FC';
      }
      if (value >= level) return '#2478F0';
      return '#F0F0F0';
    },
    [selected, value],
  );

  return (
    <div
      onClick={() => {
        onClick(value);
      }}
      className={classNames(
        'w-[300px] relative bg-white-snow hover:bg-gray-concrete rounded-30 p-3 flex justify-between flex-col items-center cursor-pointer text-center break-words shrink-0',
        { 'bg-gray-concrete': selected },
      )}
    >
      <>
        <RadioButtonThick selected={selected} className='absolute top-18px right-18px' />
        <div
          className={classNames('relative h-60px w-60px rounded-full p-1 flex justify-center items-center my-4', {
            'bg-blue-pure': selected,
            'bg-white': !selected,
          })}
        >
          <div className='grid grid-cols-2 gap-0.5'>
            <LevelIcon color={levelIconColor(PrivacyLevelsEnum.completePrivacy)} />
            <LevelIcon className='rotate-90' color={levelIconColor(PrivacyLevelsEnum.defaultPrivacy)} />
            <LevelIcon className='-rotate-90' color={levelIconColor(PrivacyLevelsEnum.GDPRMode)} />
            <LevelIcon className='rotate-180' color={levelIconColor(PrivacyLevelsEnum.basicPrivacy)} />
          </div>
          <LockerIcon color={selected ? '#FFFFFF' : '#969696'} className='absolute' level={value} />
        </div>
        <Paragraph size={paragraphSizes.large} colorClassName='text-gray-code' weight={fontWeights.semibold}>
          {translate(`${baseKey}.title`)}
        </Paragraph>
        <Paragraph size={paragraphSizes.tiny} colorClassName='text-gray-charcoal' weight={fontWeights.medium}>
          {translate(`${baseKey}.subtitle`)}
        </Paragraph>
      </>
      <div className='rounded-18 bg-white p-4 mt-4 h-3/5 text-gray-charcoal w-full break-words'>
        <Paragraph className='mb-4' weight={fontWeights.medium}>
          {translate(`${baseKey}.info1`)}
        </Paragraph>
        <GrayGradientDivider className='-ml-4 my-3' />

        <Paragraph size={paragraphSizes.tiny} weight={fontWeights.semibold}>
          {translate(`${baseKey}.info2`)}
        </Paragraph>
        <GrayGradientDivider className='-ml-4 my-3' />

        <ParagraphWithTooltip size={paragraphSizes.tiny} colorClassName='text-gray-devil' weight={fontWeights.medium}>
          {translate('onboarding.privacyLevels.modeComplies')}
        </ParagraphWithTooltip>
        <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.medium}>
          {translate(`${baseKey}.info3`)}
        </ParagraphWithTooltip>
        <GrayGradientDivider className='-ml-4 my-3' />

        <ParagraphWithTooltip size={paragraphSizes.tiny} colorClassName='text-gray-devil' weight={fontWeights.medium}>
          {translate('onboarding.privacyLevels.dataTracking')}
        </ParagraphWithTooltip>
        <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.semibold} className='mb-4'>
          {translate(`${baseKey}.info4`)}
        </ParagraphWithTooltip>
      </div>
    </div>
  );
};
