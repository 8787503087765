import { IconProps } from './index';

export const CloseIconWithCircle = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3033 3.9467C17.2322 6.87563 17.2322 11.6244 14.3033 14.5533C11.3744 17.4822 6.62563 17.4822 3.6967 14.5533C0.767767 11.6244 0.767767 6.87563 3.6967 3.9467C6.62563 1.01777 11.3744 1.01777 14.3033 3.9467ZM15.364 2.88604C18.8787 6.40076 18.8787 12.0992 15.364 15.614C11.8492 19.1287 6.15076 19.1287 2.63604 15.614C-0.87868 12.0992 -0.87868 6.40076 2.63604 2.88604C6.15076 -0.62868 11.8492 -0.62868 15.364 2.88604ZM11.6516 5.5377L12.7123 6.59836L10.0606 9.25001L12.7123 11.9017L11.6516 12.9623L8.99998 10.3107L6.34833 12.9623L5.28767 11.9017L7.93932 9.25001L5.28767 6.59836L6.34833 5.5377L8.99998 8.18935L11.6516 5.5377Z'
        fill={color ?? '#3C3C3C'}
      />
    </svg>
  );
};
