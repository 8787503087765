import { patch, post, remove } from '@va/http-client';
import { getUserId } from '@va/standalone/shared/helpers';
import { getAgencyId } from '@va/standalone/shared/selectors';
import { useAsyncFunction } from '@va/util/hooks';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useUploadAgencyLogo = () => {
  const userId = getUserId();

  const asyncFn = useCallback(
    async (file: File) => {
      return post(`/users/${userId}/agencyui/logo`, {}, { file: file });
    },
    [userId],
  );

  return useAsyncFunction<typeof asyncFn, { fileId: string }>(asyncFn);
};

export const useDeleteAgencyLogo = () => {
  const agencyId = useSelector(getAgencyId);
  const userId = getUserId();

  const asyncFn = useCallback(async () => {
    return remove(`/users/${userId}/agencyui/${agencyId}/logo`);
  }, [userId, agencyId]);

  return useAsyncFunction<typeof asyncFn, { fileId: string }>(asyncFn);
};

export const useEditAgencyUi = () => {
  const userId = getUserId();
  const agencyId = useSelector(getAgencyId);
  const asyncFn = useCallback(
    async (data: any) => {
      return patch(`/users/${userId}/agencyui/${agencyId}`, {}, data);
    },
    [userId, agencyId],
  );

  return useAsyncFunction<typeof asyncFn>(asyncFn);
};
