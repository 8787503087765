// meters per degree of latitude
const degLat = 110574;
// meters per degree of longitude at the Equator
const degLng = 111321 * Math.abs(Math.cos(degLat * (Math.PI / 180)));

const getItemsInCircle = function (items) {
  for (let i = 0; i < items.length; i++) {
    let coordsInCircle = getCoordsInCircle(items[i].position[0], items[i].position[1]);
    items[i].position[0] = coordsInCircle.lat;
    items[i].position[1] = coordsInCircle.lng;
  }

  return items;
};

const getItemsInSquare = function (items) {
  for (let i = 0; i < items.length; i++) {
    let coordsInCircle = getCoordsInSquare(items[i].position[0], items[i].position[1]);
    items[i].position[0] = coordsInCircle.lat;
    items[i].position[1] = coordsInCircle.lng;
  }

  return items;
};

const getCoordsInCircle = function (lat, lng) {
  let angle = Math.random() * 2 * Math.PI;
  let radius = Math.random() * 500; // meters
  return {
    lat: lat + (radius / degLat) * Math.cos(angle),
    lng: lng + (radius / degLng) * Math.sin(angle),
  };
};

const getCoordsInSquare = function (lat, lng) {
  let halfSquareSide = Math.random() * 500; // meters
  return {
    lat: lat + (halfSquareSide / degLat) * Math.pow(-1, Math.floor(Math.random() * 10)),
    lng: lng + (halfSquareSide / degLng) * Math.pow(-1, Math.floor(Math.random() * 10)),
  };
};

export const coordinates = {
  getItemsInCircle: getItemsInCircle,
  getItemsInSquare: getItemsInSquare,
};
