import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/xml/xml.js';
import 'codemirror/theme/neo.css';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import './CodeEditor.scss';

const DEFAULT_CSS_OPTIONS = {
  theme: 'neo',
  readOnly: true,
  mode: 'xml',
  lineNumbers: true,
  styleActiveLine: true,
  lineWrapping: true,
};

type CodeEditorType = {
  value: string;
};

export const CodeEditor = ({ value }: CodeEditorType) => {
  return <CodeMirror value={`${value}`} options={DEFAULT_CSS_OPTIONS} />;
};
