import PropTypes from 'prop-types';

/**
 * @return {null|element}
 */
export function OS(props) {
  const os = props.type ? props.type : '';
  switch (os.toLowerCase().trim()) {
    case 'macos':
    case 'macosx':
    case 'macintosh':
    case 'macos mavericks':
    case 'macos yosemite':
    case 'macos el capitan':
    case 'macos sierra':
    case 'macos high sierra':
    case 'macos mojave':
    case 'macos catalina':
    case 'macos big sur':
    case 'ios':
    case 'ipados':
      return <Apple className={props.className} />;
    case 'win95':
    case 'win98':
    case 'win2000':
    case 'winnt':
    case 'winrt8':
    case 'winrt8.1':
    case 'win32':
    case 'win7':
    case 'win8':
    case 'win8.1':
    case 'win10':
    case 'winxp':
    case 'windows xp':
    case 'winvista':
    case 'windows':
    case 'windows 10':
    case 'windows 7':
    case 'windows 8':
    case 'windows 8.1':
    case 'winmobile':
    case 'winphone':
    case 'winphone7':
    case 'winphone7.5':
    case 'winphone8':
    case 'winphone8.1':
    case 'winphone10':
      return <Windows className={props.className} />;
    case 'linux':
    case 'linux smartphone os':
    case 'ubuntu':
      return <Linux className={props.className} />;
    case 'android':
    case 'android pie':
    case 'android oreo':
    case 'android for googletv':
      return <Android className={props.className} />;
    case 'rim os':
    case 'rim tablet os':
      return <BlackBerry className={props.className} />;
    case 'chromeos':
      return <ChromeOS className={props.className} />;
    case 'unknown':
    case 'default browser':
      return <UnknownOS className={props.className} />;
    case null:
    case undefined:
      return null;
    default:
      return <UnknownOS className={props.className} />;
  }
}

OS.propTypes = {
  className: PropTypes.string.isRequired,
  type: PropTypes.string,
};

function ChromeOS(props) {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        d='M22,7.5h-7.8c1.4,0.9,2.4,2.5,2.4,4.4c0,0.4-0.1,0.8-0.1,1.1v0.1c-0.1,0.6-0.3,1.1-0.6,1.6L11,23.3h0.4
	c6.3,0,11.4-5,11.4-11.4C22.9,10.3,22.6,8.8,22,7.5z M12.5,22.5l4.2-7.3c0.4-0.7,0.6-1.3,0.7-1.9l0,0c0.1-0.4,0.1-0.9,0.1-1.3
	c0-1.3-0.5-2.6-1.2-3.6h5.2c0.4,1.1,0.6,2.3,0.6,3.6C22.1,17.4,17.8,22,12.5,22.5z'
      />
      <path
        d='M11.6,17c-1.4,0-2.8-0.6-3.7-1.6c-0.4-0.4-0.7-0.7-1-1.1l-0.1-0.2L1.9,5.9c-1.1,1.7-1.7,3.8-1.7,6
	c0,5.8,4.3,10.6,9.9,11.2l3.9-6.8C13.3,16.8,12.4,17,11.6,17z M9.7,22.3C4.7,21.5,1,17.1,1,11.9c0-1.5,0.3-3,1-4.4l4.2,7.1l0.1,0.2
	c0.3,0.5,0.6,0.8,1,1.2c1.1,1.1,2.7,1.8,4.3,1.8c0.2,0,0.5,0,0.7,0L9.7,22.3z'
      />
      <path
        d='M11.6,0.5c-3.7,0-7.1,1.8-9.1,4.6l3.8,6.6c0.1,0.1,0.1,0.2,0.1,0.3v-0.2c0-2.5,1.9-4.7,4.2-5.1h0.2
	c0.3,0,0.7-0.1,1.1-0.1h9.6C19.7,3.1,15.9,0.5,11.6,0.5z M10.8,6h-0.2C8.5,6.3,6.8,7.8,6.1,9.7L3.4,5.2c2-2.5,5-3.9,8.2-3.9
	c3.4,0,6.6,1.7,8.6,4.5H12C11.6,5.9,11.3,5.9,10.8,6z'
      />
      <path
        d='M11.6,7.8c-2.2,0-4.1,1.9-4.1,4.1S9.3,16,11.6,16c2.2,0,4.1-1.8,4.1-4.1S13.8,7.7,11.6,7.8z M11.6,15.2
	c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3h0.1c1.8,0,3.2,1.5,3.2,3.3C14.9,13.7,13.4,15.2,11.6,15.2z'
      />
    </svg>
  );
}

function Apple(props) {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        d='M15.5,1.4c-0.1,0.6-0.5,1.2-1,1.7l0,0l0,0c-0.4,0.5-0.9,0.9-1.4,1.1c0.1-0.6,0.5-1.2,1-1.8l0,0l0,0
		C14.4,2,14.9,1.6,15.5,1.4 M16.6,0.1h-0.1c-1.1,0.1-2.5,0.8-3.2,1.7c-1,1.1-1.4,2.3-1.3,3.6c0.1,0,0.2,0,0.2,0l0,0
		c1.5,0,2.5-0.8,3.1-1.6C16.3,2.7,16.7,1.5,16.6,0.1L16.6,0.1z'
      />
      <path
        d='M16.3,6.8c0.2,0,0.3,0,0.5,0h0.1H17c1.1,0.2,1.9,0.5,2.5,1.1c-1.6,1.3-2.2,3.1-2,5.3c0.2,2.1,1.2,3.7,2.9,4.8
		c-0.4,1-1,1.9-1.5,2.7v0.1v0.1L18.8,21c-0.7,0.9-1.3,1.7-2.2,1.7c-0.5,0-0.9-0.1-1.5-0.4H15h-0.1c-0.1,0-0.2-0.1-0.4-0.1
		c-0.5-0.2-1.2-0.4-2-0.4c-0.9,0-1.7,0.3-2.3,0.5L10,22.2l0,0l0,0c-0.4,0.2-0.9,0.3-1.3,0.4H8.2H8c-0.6-0.2-1.1-0.7-1.4-1.2l0,0l0,0
		c-1.9-2.4-3-5.1-3.2-8v-1c0.1-2.1,1-4,2.5-4.8l0,0l0,0C6.4,7.3,7.2,7,8,7h0.5C9.1,7,9.6,7.1,10,7.3l0.2,0.1h0.1c0,0,0.1,0,0.2,0.1
		c0.5,0.3,1,0.4,1.5,0.4s1-0.1,1.4-0.4c0,0,0.1,0,0.1-0.1h0.1L14,7.3C14.6,7.1,15.5,6.8,16.3,6.8 M16.3,5.8c-1,0-1.9,0.3-2.7,0.6
		l-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.2-0.6,0.3-1,0.3s-0.9-0.2-1.3-0.4c-0.1,0-0.2-0.1-0.3-0.1C10,6.2,9.4,6,8.7,5.9
		c-0.2,0-0.4,0-0.6,0C7,5.9,6,6.3,5.4,6.7c-1.8,1-2.9,3.2-3,5.6v1.1c0.2,3.2,1.4,6.1,3.4,8.6c0.5,0.7,1.4,1.5,2.4,1.7v-0.1h0.5
		c0.6-0.1,1.1-0.3,1.6-0.5c0.6-0.2,1.3-0.5,2.2-0.5c0.8,0,1.4,0.3,2,0.5c0.6,0.3,1.2,0.5,1.9,0.5c1.5,0,2.4-1.2,3.1-2.2l0.1-0.2
		c0.8-1.2,1.5-2.4,1.9-3.7c-1.9-0.9-2.9-2.4-3.1-4.4c-0.2-2.2,0.6-3.8,2.5-5c-0.8-1.2-2.1-1.9-3.8-2.2C16.8,5.8,16.5,5.8,16.3,5.8
		L16.3,5.8z'
      />
    </svg>
  );
}

function Android(props) {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        d='M15.6,2.8l0.6-0.9c0.1-0.1,0.1-0.3,0.1-0.5S16.2,1.1,16,1c-0.3-0.2-0.7-0.1-0.9,0.2l-0.8,1.1
      c-1.4-0.4-2.9-0.4-4.2,0L9.4,1.1C9.2,0.8,8.8,0.7,8.5,0.9S8.1,1.5,8.3,1.8l0.6,0.9c-1.5,1-2.5,2.6-2.5,4.2c0,0.3,0.2,0.5,0.5,0.5
      h10.9c0.3,0,0.5-0.2,0.5-0.5C18.2,5.3,17.2,3.7,15.6,2.8z M7.4,6.4c0.1-1.2,1.1-2.4,2.4-3c0.1,0,0.1-0.1,0.2-0.1
      c0.1,0,0.1,0,0.2,0c1.3-0.5,2.9-0.5,4.3,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0.1,0.2,0.1c1.2,0.6,2.1,1.8,2.3,3H7.4z M17.7,7.9H6.8
      c-0.3,0-0.5,0.2-0.5,0.5v8.8c0,0.9,0.8,1.7,1.7,1.7h0.4v2.3c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6v-2.3h1.1v2.3
      c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6v-2.3h0.4c0.9,0,1.7-0.8,1.7-1.7V8.3C18.2,8.1,18,7.9,17.7,7.9z M17.2,17.1
      c0,0.4-0.3,0.8-0.8,0.8h-0.9c-0.1,0-0.3,0.1-0.4,0.2C15,18.2,15,18.4,15,18.5s0,0.1,0,0.2v2.5c0,0.4-0.3,0.7-0.7,0.7
      s-0.7-0.3-0.7-0.7v-2.5c0-0.1,0-0.1,0-0.2s0-0.3-0.1-0.4s-0.2-0.2-0.4-0.2h-2.2c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.4
      s0,0.1,0,0.2v2.5c0,0.4-0.3,0.7-0.7,0.7S9,21.6,9,21.2v-2.5c0-0.1,0-0.1,0-0.2s0-0.3-0.1-0.4s-0.2-0.2-0.4-0.2H8.1
      c-0.4,0-0.8-0.3-0.8-0.8V8.8h9.9V17.1L17.2,17.1z M4.4,8.1c-0.9,0-1.6,0.7-1.6,1.6v5c0,0.9,0.7,1.6,1.6,1.6S6,15.6,6,14.7v-5
      C6.1,8.8,5.3,8.1,4.4,8.1z M5.1,14.7c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-5C3.7,9.3,4,9,4.4,9s0.7,0.3,0.7,0.7V14.7z
       M20.1,8.1c-0.9,0-1.6,0.7-1.6,1.6v5c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6v-5C21.8,8.8,21,8.1,20.1,8.1z M20.8,14.7
      c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-5c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7V14.7z M14.5,4.3
      c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6C15.1,4.6,14.8,4.3,14.5,4.3z M10.3,4.3c-0.3,0-0.6,0.3-0.6,0.6
      s0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6C10.8,4.6,10.6,4.3,10.3,4.3z'
      />
    </svg>
  );
}

function Linux(props) {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        id='XMLID_9_'
        d='M21.1,19.6c-0.6-0.3-1.2-0.7-1.1-1.5c0-0.8-0.6-1.3-0.6-1.3s0.5-1.7,0-3.1s-2.1-3.7-3.3-5.4
      C14.9,6.5,16,4.6,14.8,2c-1.1-2.5-4-2.4-5.6-1.3s-1.1,3.8-1,5c0.1,1.3,0,2.2-0.1,2.5C8,8.6,7,9.8,6.3,10.8s-1.2,3.1-1.6,4
      c-0.5,0.9-0.1,1.6-0.1,1.6S4.3,16.5,4,17.1s-0.8,0.8-1.7,1s-0.9,0.8-0.7,1.5s0,1-0.3,1.9s1,1.1,2.3,1.3c1.3,0.2,2.7,1,3.9,1.1
      C8.6,24,9,23,9,23s1.3-0.3,2.8-0.3c1.4,0,2.8,0.3,2.8,0.3s0.3,0.6,0.7,0.9c0.5,0.3,1.5,0.3,2.2-0.4c0.7-0.7,2.5-1.6,3.5-2.2
      C21.9,20.7,21.8,19.9,21.1,19.6z M12.4,3.1c0.6,0,1.2,0.6,1.2,1.4c0,0.6-0.3,1-0.6,1.3c-0.1,0-0.2-0.1-0.3-0.1
      C12.8,5.6,13,5.3,13,4.9s-0.3-0.8-0.6-0.8s-0.6,0.4-0.6,0.8c0,0.2,0,0.3,0.1,0.4c-0.2-0.1-0.4-0.2-0.5-0.2c-0.1-0.2-0.1-0.4-0.1-0.6
      C11.2,3.7,11.7,3.1,12.4,3.1z M10.8,5.6c0.3,0,1.2,0.4,1.5,0.5s0.7,0.3,0.6,0.5c0,0.2-0.2,0.2-0.6,0.5c-0.4,0.3-1.4,0.8-1.7,0.9
      c-0.3,0-0.5-0.1-0.8-0.3C9.5,7.5,8.9,7,9,6.7c0,0,0.5-0.4,0.7-0.5C9.9,5.9,10.5,5.5,10.8,5.6z M9.4,3.3c0.5,0,0.9,0.6,0.9,1.3
      c0,0.1,0,0.3,0,0.4C10.2,5,10,5.1,9.9,5.2c0,0.1-0.1,0.2-0.2,0.2C9.8,5.2,9.8,5,9.8,4.8c0-0.4-0.3-0.7-0.6-0.6
      C8.9,4.2,8.8,4.6,8.9,5C9,5.4,9.2,5.7,9.5,5.7l0,0C9.4,5.8,9.3,5.9,9.1,6C8.8,5.8,8.5,5.3,8.5,4.7C8.5,3.9,8.9,3.3,9.4,3.3z
       M8.4,22.2c-0.1,0.5-0.7,0.9-0.7,0.9c-0.6,0.2-2.1-0.5-2.9-0.8C4.1,22,2.3,21.9,2,21.7c-0.2-0.3,0.1-0.9,0.2-1.4
      C2.3,19.7,2,19.4,2.1,19s1.3-0.4,1.7-0.7c0.4-0.3,0.5-1,0.9-1.3c0.4-0.2,1,0.5,1.3,1c0.3,0.4,1.3,2.2,1.7,2.7
      C8.2,21.1,8.5,21.7,8.4,22.2z M15,17c-0.1,0.5-0.1,2.4-0.1,2.4s-1.2,1.6-3,1.9s-2.7,0.1-2.7,0.1l-1-1.2c0,0,0.8-0.1,0.7-0.9
      s-2.4-1.9-2.8-2.8c-0.4-1-0.1-2.6,0.5-3.4c0.5-0.8,0.9-2.6,1.4-3.2C8.5,9.2,8.9,8,8.7,7.4c0,0,1.1,1.3,1.9,1.1
      c0.8-0.2,2.5-1.5,2.8-1.3s2.5,5.2,2.7,6.7c0.2,1.6-0.2,2.8-0.2,2.8S15.1,16.5,15,17z M20.8,20.5c-0.4,0.3-2.3,1.1-2.9,1.7
      s-1.3,1.1-1.8,1s-0.9-0.8-0.7-1.7c0.2-0.9,0.4-1.9,0.4-2.5s-0.1-1.4,0-1.5s0.4-0.1,0.4-0.1s-0.1,1.1,0.6,1.4s1.6-0.1,1.9-0.4
      s0.5-0.7,0.5-0.7s0.3,0.1,0.3,0.6s0.2,1.1,0.6,1.4C20.4,19.9,21.1,20.2,20.8,20.5z'
      />
    </svg>
  );
}

function Windows(props) {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 24 24'
      {...props}
    >
      <path d='M8.9,13.6v6.8l-7.3-1v-5.7h7.3 M9.9,12.6H0.6v7.6l9.3,1.3L9.9,12.6L9.9,12.6z' />
      <path d='M9,3.5v6.9H1.6V4.6L9,3.5 M10,2.4L0.6,3.7v7.7H10V2.4L10,2.4z' />
      <path d='M22.4,13.6v8.6L12,20.7v-7.1H22.4 M23.4,12.6H11v9l12.4,1.7V12.6L23.4,12.6z' />
      <path d='M22.4,1.7v8.8H12V3.2L22.4,1.7 M23.4,0.6L11,2.3v9.2h12.4V0.6L23.4,0.6z' />
    </svg>
  );
}

export function BlackBerry(props) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 24 24'
      xmlSpace='preserve'
      {...props}
    >
      <path
        d='M8.6,5.1C8.3,4.7,7.8,4.5,7.2,4.5H3.4C3.2,4.5,3,4.6,3,4.8L2.2,8.2c0,0.1,0,0.3,0.1,0.4s0.2,0.2,0.3,0.2h3.8
  c1.1,0,2.2-0.9,2.5-2V6.7C9,6,8.9,5.5,8.6,5.1z M8,6.4v0.1C7.8,7.2,7,7.8,6.3,7.8H3.1l0.6-2.5h3.5c0.3,0,0.6,0.1,0.7,0.3
  C8.1,5.8,8.1,6.1,8,6.4z'
      />
      <path
        d='M7.3,10.5C7,10.1,6.5,9.9,5.9,9.9H2.1c-0.2,0-0.4,0.1-0.4,0.3l-0.8,3.4c0,0.1,0,0.3,0.1,0.4
  c0.1,0.1,0.2,0.2,0.3,0.2h3.8c1.1,0,2.2-0.9,2.5-2v-0.1C7.7,11.5,7.6,10.9,7.3,10.5z M6.8,11.8L6.8,11.8c-0.2,0.8-1,1.4-1.7,1.4
  H1.9l0.6-2.5H6c0.3,0,0.6,0.1,0.7,0.3C6.8,11.3,6.9,11.5,6.8,11.8z'
      />
      <path
        d='M16.2,5.1c-0.3-0.4-0.8-0.6-1.4-0.6H11c-0.2,0-0.4,0.1-0.4,0.3L9.8,8.2c0,0.1,0,0.3,0.1,0.4s0.2,0.2,0.3,0.2
  H14c1.1,0,2.2-0.9,2.5-2V6.7C16.6,6,16.5,5.5,16.2,5.1z M15.6,6.4v0.1c-0.2,0.7-1,1.3-1.7,1.3h-3.2l0.6-2.5h3.5
  c0.3,0,0.6,0.1,0.7,0.3C15.7,5.8,15.7,6.1,15.6,6.4z'
      />
      <path
        d='M14.9,10.5c-0.3-0.4-0.8-0.6-1.4-0.6H9.7c-0.2,0-0.4,0.1-0.4,0.3l-0.8,3.4c0,0.1,0,0.3,0.1,0.4
  c0.1,0.1,0.2,0.2,0.3,0.2h3.8c1.1,0,2.2-0.9,2.5-2v-0.1C15.3,11.5,15.2,10.9,14.9,10.5z M14.4,11.8L14.4,11.8
  c-0.2,0.8-1,1.4-1.7,1.4H9.5l0.6-2.5h3.5c0.3,0,0.6,0.1,0.7,0.3C14.4,11.3,14.4,11.5,14.4,11.8z'
      />
      <path
        d='M22.9,8c-0.3-0.4-0.8-0.6-1.4-0.6h-3.8c-0.2,0-0.4,0.1-0.4,0.3L16.4,11c0,0.1,0,0.3,0.1,0.4
  c0.1,0.1,0.2,0.2,0.3,0.2h3.8c1.1,0,2.2-0.9,2.5-2V9.5C23.3,9,23.2,8.4,22.9,8z M22.4,9.3L22.4,9.3c-0.2,0.8-1,1.4-1.7,1.4h-3.2
  l0.6-2.5h3.5c0.3,0,0.6,0.1,0.7,0.3C22.4,8.7,22.4,9,22.4,9.3z'
      />
      <path
        d='M22,13.5c-0.3-0.4-0.8-0.6-1.4-0.6h-3.8c-0.2,0-0.4,0.1-0.4,0.3l-0.8,3.4c0,0.1,0,0.3,0.1,0.4
  c0.1,0.1,0.2,0.2,0.3,0.2h3.8c1.1,0,2.2-0.9,2.5-2v-0.1C22.5,14.4,22.4,13.9,22,13.5z M21.5,14.8L21.5,14.8
  c-0.2,0.8-1,1.4-1.7,1.4h-3.2l0.6-2.5h3.5c0.3,0,0.6,0.1,0.7,0.3C21.5,14.2,21.6,14.5,21.5,14.8z'
      />
      <path
        d='M13.2,16c-0.3-0.4-0.8-0.6-1.4-0.6H8c-0.2,0-0.4,0.1-0.4,0.3l-0.8,3.4c0,0.1,0,0.3,0.1,0.4
  c0.1,0.1,0.2,0.2,0.3,0.2H11c1.1,0,2.2-0.9,2.5-2v-0.1C13.6,17,13.5,16.4,13.2,16z M12.7,17.3L12.7,17.3c-0.2,0.8-1,1.4-1.7,1.4
  H7.8l0.6-2.5h3.5c0.3,0,0.6,0.1,0.7,0.3C12.7,16.7,12.7,17,12.7,17.3z'
      />
    </svg>
  );
}

export function UnknownOS(props) {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 20 20'
      xmlSpace='preserve'
      {...props}
    >
      <g>
        <path
          d='M10,14.2c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6C10.6,14,10.3,14.2,10,14.2z M10,13.5
        c-0.1,0-0.2,0.1-0.2,0.2c0,0.2,0.4,0.2,0.4,0C10.2,13.5,10.1,13.5,10,13.5z'
        />
      </g>
      <g>
        <path d='M13.4,18H6.6l1.8-3.3h3.3L13.4,18z M7.9,17.2h4.2l-0.9-1.7H8.8L7.9,17.2z' />
      </g>
      <path d='M0.8,2.5v9v0.7v3.3h18.3v-3.3v-0.7v-9H0.8z M18.4,14.7H1.6v-2.5h16.8V14.7z M1.6,11.5V3.3h16.8v8.2H1.6z' />
      <g>
        <path
          d='M8.5,4.6C8.9,4.2,9.4,4,10.1,4c0.6,0,1.1,0.2,1.5,0.5c0.4,0.3,0.5,0.8,0.5,1.3c0,0.3-0.1,0.6-0.2,0.8
        S11.5,7.1,11,7.5c-0.3,0.3-0.5,0.5-0.6,0.7c-0.1,0.2-0.1,0.5-0.1,0.9H9.5c0-0.4,0.1-0.8,0.2-1s0.3-0.6,0.7-0.9l0.4-0.4
        c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.4,0.2-0.6c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.9-0.3c-0.5,0-0.9,0.2-1.1,0.6
        C8.8,5.5,8.8,5.8,8.8,6.1H7.9C7.9,5.5,8.1,5,8.5,4.6z M9.5,9.8h0.9v0.9H9.5V9.8z'
        />
      </g>
    </svg>
  );
}
