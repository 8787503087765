import { useLocale } from '@va/localization';
import { Chart, ChartConfiguration, ChartData } from 'chart.js';
import classNames from 'classnames';
import { assign, isNil } from 'lodash';
import { CSSProperties, memo, ReactNode, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { buildTicks, limitTicksCallback } from './utils';

export type LineChartConfigOptions = {
  limitTicks?: boolean;
  tickFontSize?: number;
  stacked?: boolean;
};

export type LineChartProps = {
  id: string;
  chartData: ChartData<'line'>;
  wrapperStyle?: CSSProperties;
  tooltipFn?: (tooltipCtx: any) => ReactNode;
  className?: string;
  configOptions?: LineChartConfigOptions;
  isLoading?: boolean;
};

export const LineChart = memo(
  ({ id, chartData, wrapperStyle, tooltipFn, className, configOptions = {}, isLoading }: LineChartProps) => {
    const canvasRef = useRef<HTMLCanvasElement | null>();
    const containerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<Chart<'line'>>();
    const { locale } = useLocale();
    const { limitTicks, tickFontSize } = configOptions;
    const [tooltipContext, setTooltipContext] = useState<any>();

    useEffect(() => {
      if (!canvasRef.current || !containerRef.current) return;
      const canvasContext = canvasRef.current.getContext('2d');
      if (!canvasContext) return;

      const hasSingleLabel = chartData?.datasets[0]?.data?.length === 1;

      const config: ChartConfiguration<'line'> = {
        data: { ...chartData, datasets: [...chartData.datasets], labels: [...chartData.labels!] },
        type: 'line',
        options: {
          locale: locale,
          maintainAspectRatio: false,
          responsive: true,
          animation: false,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          plugins: {
            datalabels: {
              display: false,
            },
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            tooltip: {
              mode: 'index',
              position: 'average',
              enabled: !tooltipFn,
              external: (context) => setTooltipContext(context),
            },
          },
          scales: {
            x: {
              offset: true,
              ticks: {
                ...buildTicks('#696969', tickFontSize),
                maxRotation: 0,
                autoSkip: true,
                major: {
                  enabled: true,
                },
              },
              afterBuildTicks: (axis) => limitTicksCallback(axis, limitTicks),
            },
            y: {
              stacked: configOptions?.stacked,
              type: 'linear',
              min: 0,
              border: {
                display: false,
              },
              grid: {
                color: 'transparent',
              },
              position: 'left',
              ticks: {
                ...buildTicks('#969696', tickFontSize),
              },
            },
            y2: {
              type: 'linear',
              position: 'right',
              grid: {
                color: 'transparent',
              },
              border: {
                display: false,
              },
              afterDataLimits: (axis) => {
                const yScale = axis.chart.scales['y'];
                if (!yScale) return;
                axis.min = yScale.min;
                axis.max = yScale.max;
              },
              ticks: {
                ...buildTicks('#969696', tickFontSize),
              },
            },
          },
          elements: {
            point: {
              radius: hasSingleLabel ? 2 : 0,
              hoverRadius: 4,
              hoverBorderWidth: 3,
              hoverBorderColor: '#fff',
            },
          },
        },
      };

      if (!chartRef.current) {
        chartRef.current = new Chart<'line'>(canvasContext, config);
        // Initial chart creation
        return;
      }

      // If chart exists, update it
      updateChart(chartRef.current, config, containerRef.current);
    }, [limitTicks, locale, tickFontSize, tooltipFn, chartData, configOptions?.stacked]);

    return (
      <div ref={containerRef} className={classNames('relative', className)} style={wrapperStyle}>
        {isLoading && <Skeleton className='h-full rounded-24' />}
        <canvas
          className={classNames({ 'invisible absolute': isLoading })}
          id={id}
          ref={(ref) => {
            canvasRef.current = ref;
          }}
        />
        {tooltipContext && !isLoading && tooltipFn && tooltipFn(tooltipContext)}
      </div>
    );
  },
);

function updateChart(chart: Chart<'line'>, config: ChartConfiguration<'line'>, containerElement: HTMLDivElement) {
  if (isNil(chart?.data?.datasets) || isNil(config?.data?.datasets)) return;

  // Resize chart
  const containerRect = containerElement.getBoundingClientRect();
  const containerHeight = containerRect.height;
  const containerWidth = containerRect.width;
  chart.resize(containerWidth, containerHeight);

  // Update chart options
  if (config.options) {
    chart.options = config.options;
  }

  // Update chart labels
  if (chart.data.labels) {
    chart.data.labels = config.data.labels;
  }

  if (chart.data.datasets.length !== config.data.datasets.length) {
    handleLengthChange(chart, config);
    chart.update();
    return;
  }

  for (let i = 0; i < config.data.datasets.length; i++) {
    const dataset = config.data.datasets[i];
    assign(chart.data.datasets[i], dataset);
  }

  // Re-draw chart
  chart.update();
}

function handleLengthChange(chart: Chart<'line'>, config: ChartConfiguration<'line'>) {
  let cfgDatasets = [...config.data.datasets];

  for (let i = 0; i < chart.data.datasets.length; i++) {
    const chartDataset = chart.data.datasets[i];

    const foundIndex = cfgDatasets.findIndex(
      (configDataset) =>
        configDataset.label === chartDataset.label && configDataset?.isCurrent === chartDataset?.isCurrent,
    );

    if (foundIndex > -1) {
      assign(chart.data.datasets[i], cfgDatasets[foundIndex]);
      cfgDatasets = cfgDatasets.filter((_, index) => index !== foundIndex);
    } else {
      chart.data.datasets = chart.data.datasets.filter((_, index) => index !== i);
      i--;
    }
  }

  chart.data.datasets = [...chart.data.datasets, ...cfgDatasets];
}
