import { Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { FilterBlock } from '../../FilterBlock';
import { FilterSelectDropdown } from '../../FilterSelectDropdown';
import { useFiltersContext } from '../../filters-context';
import { SingleSelectFilterInputProps } from './SingleSelectFilterInput';

export const SingleSelectFilterInputApplied: React.FC<SingleSelectFilterInputProps> = ({
  useOptions,
  filter,
  showSearchInput = true,
}) => {
  const { updateAppliedFilter } = useFiltersContext();

  const { id, label, operator, values, operatorOptions } = filter;

  const { data: options = [] } = useOptions();

  const selectedOption = useMemo(() => options.find((option) => values.includes(option.value)), [options, values]);

  const value = values[0];

  return (
    <FilterBlock
      filterId={id}
      fieldName={label}
      operator={operator}
      operatorOptions={operatorOptions}
      input={
        <FilterSelectDropdown
          showSearchInput={showSearchInput}
          triggerClassName='input-wrapper'
          options={options}
          selectedOptions={!isNil(value) ? { [value]: true } : {}}
          onChange={(option) => {
            updateAppliedFilter(id, { values: [option.value] });
          }}
        >
          <div className='flex items-center gap-2'>
            {selectedOption?.icon}
            <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
              {selectedOption?.label ?? '-'}
            </Paragraph>
          </div>
        </FilterSelectDropdown>
      }
    />
  );
};
