import classNames from 'classnames';
import React from 'react';
import { IconProps } from '.';

export const MinusStarIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='20'
      height='18'
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 2.04063L12.0698 6.52794L12.2456 6.90915L12.6625 6.95858L17.5698 7.54042L13.9417 10.8956L13.6335 11.1806L13.7153 11.5924L14.6784 16.4393L10.3663 14.0256L10 13.8205L9.63367 14.0256L5.3216 16.4393L6.28467 11.5924L6.36649 11.1806L6.05827 10.8956L2.43019 7.54042L7.33747 6.95858L7.75437 6.90915L7.93021 6.52794L10 2.04063Z'
        stroke={color}
        strokeWidth='1.5'
      />
      <path fillRule='evenodd' clipRule='evenodd' d='M8.49951 9V10.5H11.4995V9H8.49951Z' fill={color} />
    </svg>
  );
};


