import { apiStatus, CONSENT_FLAG } from '@va/constants';
import { setUserFlag, updateWebsiteFlags } from '@va/dashboard/actions/api';
import { setConsentInSession } from '@va/dashboard/actions/ui';
import { getRequestStatus } from '@va/dashboard/selectors/api';
import { hasConsentFlag } from '@va/dashboard/selectors/app';
import ToggleSwitch from '@va/deprecated/components/ToggleSwitch';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import { getApiRequestStatus } from '@va/standalone/shared/selectors';
import { isWixApp } from '@va/util/helpers';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

class ConsentSettingsItem extends PureComponent {
  constructor(props) {
    super(props);
    this.onSwitchChange = this.onSwitchChange.bind(this);
  }

  onSwitchChange(val) {
    isWixApp()
      ? this.props.updateWebsiteFlags({
          [this.props.priorConsent]: val,
        })
      : this.props.setUserFlag(this.props.priorConsent, val);
    if (val === false) this.props.setConsentInSession(CONSENT_FLAG.YOUTUBE, false);
  }

  render() {
    const { translate, inProgress, priorConsent, consentFlag } = this.props;
    return (
      <div className='flex justify-between py-10 xs:flex-col'>
        <div className='w-1/4 sm:w-1/2 xs:w-full text-3xl font-medium mb-2 mr-2'>
          {translate(`consent.${priorConsent}.title`)}
        </div>
        <div className='w-3/4 sm:w-1/2 xs:w-full flex flex-col'>
          <div className='flex mb-2'>
            <ToggleSwitch
              checked={consentFlag || false}
              handleChange={this.onSwitchChange}
              height={24}
              width={40}
              onColor={window.PRIMARY_COLOR}
              onHandleColor={window.PRIMARY_COLOR}
              withoutBorder
              disabled={inProgress}
            />
            <div className='ml-2 text-lg leading-24'>
              {translate(`option.btn.${consentFlag ? 'enabled' : 'disabled'}`)}
            </div>
          </div>
          <div className='text-sm leading-5 font-light'>{translate(`consent.${priorConsent}.description`)}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const apiRequestStatus = isWixApp()
    ? getRequestStatus(state, 'updateWebsiteFlags')
    : getApiRequestStatus(state, 'setUserFlag');
  return {
    consentFlag: hasConsentFlag(state, ownProps.priorConsent),
    inProgress: apiRequestStatus === apiStatus.IN_PROGRESS,
  };
};

const mapDispatchToProps = {
  updateWebsiteFlags: updateWebsiteFlags,
  setUserFlag: setUserFlag,
  setConsentInSession: setConsentInSession,
};

ConsentSettingsItem.propTypes = {
  priorConsent: PropTypes.oneOf(Object.values(CONSENT_FLAG)),
  // connect
  updateWebsiteFlags: PropTypes.func.isRequired,
  setUserFlag: PropTypes.func.isRequired,
  setConsentInSession: PropTypes.func.isRequired,
  consentFlag: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ConsentSettingsItem));
