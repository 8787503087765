import { tabNames } from '@va/constants';
import { requestLatestVisitorsMap } from '@va/dashboard/actions/api';
import { setActiveTab, setGlobalFilter, setTabProperty } from '@va/dashboard/actions/ui';
import { getLatestVisitorsMap, getWebsiteUrl, isFreeWixWebsite } from '@va/dashboard/selectors/core';
import { getGlobalFilter, getTabProperty } from '@va/dashboard/selectors/ui';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import { areFiltersEqual } from '@va/util/helpers';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import LatestVisitorsMap from './LatestVisitorsMap';

export const PROPERTY_MAP_CENTER = 'mapCenter';
const MAX_ZOOM_CLUSTER = 13;

export class LatestVisitorsContainer extends Component {
  constructor(props) {
    super(props);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.refreshFunc = this.refreshFunc.bind(this);
  }

  componentDidMount() {
    this.props.setActiveTab(tabNames.latestVisitorsMap);
  }

  componentWillUnmount() {
    this.props.setTabProperty(tabNames.latestVisitorsMap, PROPERTY_MAP_CENTER);
  }

  componentDidUpdate(prevProps) {
    if (!areFiltersEqual(this.props.filter, prevProps.filter)) {
      this.refreshFunc();
    }
  }

  onFilterChange(rawFilter) {
    const filter = {
      from: rawFilter.startDate,
      until: rawFilter.endDate,
    };

    this.props.setGlobalFilter(filter);
    this.props.setTabProperty(tabNames.latestVisitorsMap, PROPERTY_MAP_CENTER);
  }

  refreshFunc() {
    this.props.getVisitorsMap(this.props.filter);
  }

  render() {
    return (
      <LatestVisitorsMap
        isWixFree={this.props.isWixFree}
        translate={this.props.translate}
        websiteUrl={this.props.websiteUrl}
        filter={{
          startDate: this.props.filter.from,
          endDate: this.props.filter.until,
          onChange: this.onFilterChange,
        }}
        refreshFunc={this.refreshFunc}
        map={{
          data: {
            center: this.props.map.center,
            zoom: this.props.map.zoom,
            ...this.props.map.data,
          },
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const filter = getGlobalFilter(state);
  const mapCenter = getTabProperty(state, tabNames.latestVisitorsMap, PROPERTY_MAP_CENTER);

  return {
    websiteUrl: getWebsiteUrl(state),
    filter: filter,
    isWixFree: isFreeWixWebsite(state),
    map: {
      center: mapCenter,
      zoom: mapCenter ? MAX_ZOOM_CLUSTER + 2 : undefined,
      data: getLatestVisitorsMap(state, ownProps.isWix),
    },
  };
};

const mapDispatchToProps = {
  getVisitorsMap: requestLatestVisitorsMap,
  setTabProperty: setTabProperty,
  setGlobalFilter: setGlobalFilter,
  setActiveTab: setActiveTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(LatestVisitorsContainer));

LatestVisitorsContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  getVisitorsMap: PropTypes.func.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  setTabProperty: PropTypes.func.isRequired,
  websiteUrl: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    from: PropTypes.instanceOf(moment),
    until: PropTypes.instanceOf(moment),
  }),
  map: PropTypes.shape({
    data: PropTypes.object.isRequired,
    center: PropTypes.any,
    zoom: PropTypes.number,
  }),
};
