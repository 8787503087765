import { PRIVACY_FEATURES } from '@va/standalone/shared/types';
import {
  addNumberSeparator,
  formatSessionDuration,
  getParameterByName,
  getTimeFromSeconds,
  getTypeOfVisitor,
  isObject,
  isObjectEmpty,
  isSafari,
  toQueryString,
} from '@va/util/helpers';
import moment from 'moment';
import 'moment-timezone';
import 'whatwg-fetch';
import { createException } from './Exceptions';
import BaseConfig from './config';

// TODO Move to constants
export const EXPORT_FORMAT = {
  CSV: 'csv',
  XLSX: 'xlsx',
};

// TODO Move to constants
export const urlViews = {
  URL: 'url',
  TITLE: 'title',
};

export const jsonRequestHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const readOnly = getParameterByName('read-only');

export const checkStatus = (response: any) => {
  if (response.status === 500) {
    throw new Error(response.status);
  }

  if (response.ok) {
    // return data or json deserialization error
    return response.status === 202 || response.status === 204 ? {} : response.json();
  }

  // return error message from api or default http text
  return response.json().then(
    (json: any) => {
      if (json.error === 'AUTHENTICATION_EXPIRED_JWT_TOKEN') {
        // reloading the window triggers another initialize -> refreshing the auth token
        window.location.reload();
        return;
      }
      throw createException(json.error);
    },
    () => {
      throw new Error(response.statusText, response.status);
    },
  );
};

const call = (url: string, method: string, data: any, options?: Record<string, any>, containsFiles?: boolean) => {
  const baseUrl = BaseConfig.getApiBaseUrl();
  url = baseUrl.replace(/\/?$/, '').concat(url);
  const authHeaders = BaseConfig.getAuthProvider()();
  const requestHeaders = containsFiles
    ? { ...authHeaders }
    : {
        ...authHeaders,
        ...jsonRequestHeaders,
        'X-Timezone': window.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
  const request: Record<string, unknown> = {
    method: method,
    headers: requestHeaders,
  };

  if (options && isObject(options)) {
    // copy all keys from options
    Object.keys(options).forEach((key) => (request[key] = options[key]));
  }

  if (containsFiles) {
    const formData = new FormData();

    for (const name in data) {
      if (name !== 'files') formData.append(name, data[name]);
    }

    if (data.files) {
      for (let i = 0; i < data.files.length; i++) {
        formData.append(`file-${i}`, data.files[i]);
      }
    }

    request.body = formData;
  } else if (Array.isArray(data) || (isObject(data) && method.toUpperCase() !== 'GET')) {
    request.body = JSON.stringify(data);
  }

  if (readOnly) {
    // add read-only parameter to url query string
    url = url.includes('?') ? `${url}&read-only=${readOnly}` : `${url}?read-only=${readOnly}`;
  }
  return fetch(url, request).then((res) => checkStatus(res));
};

function dataURItoBlob(dataURI: string) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

const callWithBase64 = (url: string, method: string, data: any, options?: Record<string, any>) => {
  url = BaseConfig.getApiBaseUrl().replace(/\/?$/, '').concat(url);
  const authHeaders = BaseConfig.getAuthProvider()();
  const requestHeaders = { ...authHeaders };
  const request: Record<string, any> = {
    method: method,
    headers: requestHeaders,
  };

  if (options && isObject(options)) {
    // copy all keys from options
    Object.keys(options).forEach((key) => (request[key] = options[key]));
  }

  const formData = new FormData();
  const file = dataURItoBlob(data.signatureImage);

  formData.append('signatureImage', file);

  request.body = formData;

  if (readOnly) {
    // add read-only parameter to url query string
    url = url.includes('?') ? `${url}&read-only=${readOnly}` : `${url}?read-only=${readOnly}`;
  }

  return fetch(url, request).then(checkStatus);
};

export const get = (
  url: string,
  queryData?: Record<string, any>,
  options?: Record<string, any>,
  isDirect?: boolean,
): Promise<unknown> => {
  if (isDirect) {
    const request = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return fetch(url, request).then(checkStatus);
  }
  queryData = queryData || {};
  return call(`${url}${!isObjectEmpty(queryData) ? '?' + toQueryString(queryData) : ''}`, 'GET', {}, options);
};

export const post = (url: string, queryData: Record<string, unknown>, data: any, options?: Record<string, any>) => {
  data = data || {};
  const containsFiles = (data.files && data.files.length > 0) || data.file;

  return call(
    `${url}${!isObjectEmpty(queryData) ? '?' + toQueryString(queryData) : ''}`,
    'POST',
    data,
    options,
    containsFiles,
  );
};

export const postBase64 = (
  url: string,
  queryData: Record<string, unknown>,
  data: any,
  options?: Record<string, any>,
) => {
  data = data || {};
  return callWithBase64(
    `${url}${!isObjectEmpty(queryData) ? '?' + toQueryString(queryData) : ''}`,
    'POST',
    data,
    options,
  );
};

export const patch = (
  url: string,
  queryData?: Record<string, unknown>,
  data?: any,
  options?: Record<string, unknown>,
) => {
  data = data || {};
  return call(`${url}${!isObjectEmpty(queryData) ? '?' + toQueryString(queryData) : ''}`, 'PATCH', data, options);
};

export const put = (url: string, queryData: Record<string, unknown>, data: any, options?: Record<string, unknown>) => {
  data = data || {};
  return call(`${url}${!isObjectEmpty(queryData) ? '?' + toQueryString(queryData) : ''}`, 'PUT', data, options);
};

export const remove = (
  url: string,
  queryData?: Record<string, unknown>,
  data?: any,
  options?: Record<string, unknown>,
) => {
  data = data || {};
  queryData = queryData || {};
  return call(`${url}${!isObjectEmpty(queryData) ? '?' + toQueryString(queryData) : ''}`, 'DELETE', data, options);
};

type VisitorsData = Array<{
  pageVisit: {
    url: string;
    title: string;
    unixTs: number;
  };
  ip: string;
  device: string;
  countryCode: string;
  status: number;
  platform: string;
  browser: string;
  pageVisitsInSession: number;
  companyOrgName: string;
  companyOrgType: string;
  hasRecording: boolean;
  duration: number;
  sessionExtras: {
    seen: boolean;
  };
  adCampaignSource: string;
  adCampaignMedium: string;
  adCampaignTerm: string;
  adCampaignKey: string;
  adCampaignName: string;
}>;

export function processExportVisitorsDataXLS(
  data: VisitorsData,
  translate: any,
  timezone: string,
  urlviewFlag: string,
  isTrackingEnabled: (featureName: PRIVACY_FEATURES, countryCode: string) => boolean,
) {
  const spreadsheetData: Array<unknown> = [];

  data.forEach((visitor) => {
    const pageUrlOrTitle = urlviewFlag === urlViews.URL ? visitor.pageVisit.url : visitor.pageVisit.title;
    const isTrackingIP = isTrackingEnabled(PRIVACY_FEATURES.ipAddressTracking, visitor.countryCode);
    const isTrackingVisitorHistory = isTrackingEnabled(PRIVACY_FEATURES.individualPageHistory, visitor.countryCode);
    spreadsheetData.push([
      { value: translate(`country.name.${visitor.countryCode}`), type: 'string' },
      { value: isTrackingIP ? visitor.ip : '-', type: 'string' },
      {
        value: translate(`card.latestVisits.${getTypeOfVisitor(visitor.status)}`),
        type: 'string',
      },
      {
        value: translate(`panels.latestVisitors.deviceType.${visitor.device}`),
        type: 'string',
      },
      { value: visitor.platform, type: 'string' },
      { value: visitor.browser, type: 'string' },
      { value: isTrackingVisitorHistory ? pageUrlOrTitle : '-', type: 'string' },
      {
        value: translate('panels.latestVisitors.xPages', {
          count: visitor.pageVisitsInSession,
        }),
        type: 'string',
      },
      {
        value: processTimeForExport(visitor.pageVisit.unixTs, timezone),
        type: 'string',
      },
      {
        value: visitor.companyOrgName,
        type: 'string',
      },
      {
        value: visitor.companyOrgType,
        type: 'string',
      },
      {
        value: visitor.hasRecording,
        type: 'string',
      },
      {
        value: formatSessionDuration(visitor.duration),
        type: 'string',
      },
      {
        value: visitor.sessionExtras.seen,
        type: 'string',
      },
      {
        value: visitor.adCampaignSource,
        type: 'string',
      },
      {
        value: visitor.adCampaignMedium,
        type: 'string',
      },
      {
        value: visitor.adCampaignTerm,
        type: 'string',
      },
      {
        value: visitor.adCampaignKey,
        type: 'string',
      },
      {
        value: visitor.adCampaignName,
        type: 'string',
      },
    ]);
  });

  return spreadsheetData;
}

type VisitsData = Array<{
  page: {
    url: string;
    title: string;
  };
  countryCode: string;
  ip: string;
  device: string;
  platform: string;
  browser: string;
  unixTs: number;
  status: number;
  pagesCount: number;
  sessionDuration: number;
}>;

export function processExportVisitsDataXLS(
  data: VisitsData,
  translate: any,
  timezone: string,
  urlviewFlag: string,
  locale: string,
  isTrackingEnabled: (featureName: PRIVACY_FEATURES, countryCode: string) => boolean,
) {
  const spreadsheetData: Array<unknown> = [];

  data.forEach((visit) => {
    const isTrackingIp = isTrackingEnabled(PRIVACY_FEATURES.ipAddressTracking, visit.countryCode);
    const isTrackingVisitorHistory = isTrackingEnabled(PRIVACY_FEATURES.individualPageHistory, visit.countryCode);
    const pageUrlOrTitle = urlviewFlag === urlViews.URL ? visit.page.url : visit.page.title;

    const pageUrl = () => {
      if (!isTrackingVisitorHistory) return '-';
      return visit.page ? pageUrlOrTitle : 'Home';
    };
    spreadsheetData.push([
      { value: translate(`country.name.${visit.countryCode}`), type: 'string' },
      { value: isTrackingIp ? visit.ip : '-', type: 'string' },
      { value: processVisitStatus(visit, translate), type: 'string' },
      {
        value: translate(`panels.latestVisitors.deviceType.${visit.device}`),
        type: 'string',
      },
      { value: visit.platform, type: 'string' },
      { value: visit.browser, type: 'string' },
      { value: pageUrl(), type: 'string' },
      { value: processTimeForExport(visit.unixTs, timezone), type: 'string' },
      { value: addNumberSeparator(visit.pagesCount, locale), type: 'string' },
      { value: formatSessionDuration(visit.sessionDuration), type: 'string' },
    ]);
  });

  return spreadsheetData;
}

export function getHeaderForExportVisitors(translate: any, format: string) {
  if (format === EXPORT_FORMAT.CSV) {
    return (
      translate('panels.latestVisitors.column.visitorLocation') +
      ',' +
      translate('panels.latestVisitors.column.ip') +
      ',' +
      translate('panels.latestVisitors.column.status') +
      ',' +
      translate('panels.latestVisitors.column.device') +
      ',' +
      translate('panels.latestVisitors.column.os-short') +
      ',' +
      translate('panels.latestVisitors.column.browser') +
      ',' +
      translate('panels.latestVisitors.column.lastVisitedPage') +
      ',' +
      translate('panels.latestVisitors.column.otherPagesVisitedInSession') +
      ',' +
      translate('panels.latestVisitors.column.time') +
      ',' +
      translate('panels.latestVisitors.column.companyOrgName') +
      ',' +
      translate('panels.latestVisitors.column.companyOrgType') +
      ',' +
      translate('panels.latestVisitors.column.visitHasRecording') +
      ',' +
      translate('panels.latestVisitors.column.visitDuration') +
      ',' +
      translate('panels.latestVisitors.column.recordingSeen') +
      ',' +
      translate('panels.latestVisitors.column.adCampaignSource') +
      ',' +
      translate('panels.latestVisitors.column.adCampaignMedium') +
      ',' +
      translate('panels.latestVisitors.column.adCampaignTerm') +
      ',' +
      translate('panels.latestVisitors.column.adCampaignKey') +
      ',' +
      translate('panels.latestVisitors.column.adCampaignName') +
      '\n'
    );
  }
  return [
    [
      {
        value: translate('panels.latestVisitors.column.visitorLocation'),
        type: 'string',
      },
      { value: translate('panels.latestVisitors.column.ip'), type: 'string' },
      {
        value: translate('panels.latestVisitors.column.status'),
        type: 'string',
      },
      {
        value: translate('panels.latestVisitors.column.device'),
        type: 'string',
      },
      {
        value: translate('panels.latestVisitors.column.os-short'),
        type: 'string',
      },
      {
        value: translate('panels.latestVisitors.column.browser'),
        type: 'string',
      },
      {
        value: translate('panels.latestVisitors.column.lastVisitedPage'),
        type: 'string',
      },
      {
        value: translate('panels.latestVisitors.column.otherPagesVisitedInSession'),
        type: 'string',
      },
      {
        value: translate('panels.latestVisitors.column.time'),
        type: 'string',
      },

      { value: translate('panels.latestVisitors.column.companyOrgName'), type: 'string' },
      { value: translate('panels.latestVisitors.column.companyOrgType'), type: 'string' },
      { value: translate('panels.latestVisitors.column.visitHasRecording'), type: 'string' },
      { value: translate('panels.latestVisitors.column.visitDuration'), type: 'string' },
      { value: translate('panels.latestVisitors.column.recordingSeen'), type: 'string' },

      { value: translate('panels.latestVisitors.column.adCampaignSource'), type: 'string' },
      { value: translate('panels.latestVisitors.column.adCampaignMedium'), type: 'string' },
      { value: translate('panels.latestVisitors.column.adCampaignTerm'), type: 'string' },
      { value: translate('panels.latestVisitors.column.adCampaignKey'), type: 'string' },
      { value: translate('panels.latestVisitors.column.adCampaignName'), type: 'string' },
    ],
  ];
}

export function getHeaderForExportVisits(translate: any, format: string) {
  if (format === EXPORT_FORMAT.CSV) {
    return (
      translate('panels.latestVisitors.column.visitorLocation') +
      ',' +
      translate('panels.latestVisitors.column.ip') +
      ',' +
      translate('panels.latestVisitors.column.status') +
      ',' +
      translate('panels.latestVisitors.column.device') +
      ',' +
      translate('panels.latestVisitors.column.os') +
      ',' +
      translate('panels.latestVisitors.column.browser') +
      ',' +
      translate('panels.latestVisitors.column.page') +
      ',' +
      translate('panels.latestVisitors.column.time') +
      ',' +
      translate('panels.latestVisitors.column.pagesCount') +
      ',' +
      translate('panels.latestVisitors.column.sessionDuration') +
      '\n'
    );
  }
  return [
    [
      {
        value: translate('panels.latestVisitors.column.visitorLocation'),
        type: 'string',
      },
      { value: translate('panels.latestVisitors.column.ip'), type: 'string' },
      {
        value: translate('panels.latestVisitors.column.status'),
        type: 'string',
      },
      {
        value: translate('panels.latestVisitors.column.device'),
        type: 'string',
      },
      { value: translate('panels.latestVisitors.column.os'), type: 'string' },
      {
        value: translate('panels.latestVisitors.column.browser'),
        type: 'string',
      },
      { value: translate('panels.latestVisitors.column.page'), type: 'string' },
      { value: translate('panels.latestVisitors.column.time'), type: 'string' },
      { value: translate('panels.latestVisitors.column.pagesCount'), type: 'string' },
      { value: translate('panels.latestVisitors.column.sessionDuration'), type: 'string' },
    ],
  ];
}

function processVisitStatus(
  visit: {
    status: number;
    pagesCount: number;
    sessionDuration: number;
  },
  translate: any,
) {
  const visitorType = translate(`card.latestVisits.${getTypeOfVisitor(visit.status)}`);
  const sessionDuration =
    visit.pagesCount === 1
      ? translate('panels.latestVisitors.export.pageVisited')
      : translate('panels.latestVisitors.export.pagesVisitedIn', {
          pagesCount: visit.pagesCount,
          sessionDuration: translate(...getTimeFromSeconds(visit.sessionDuration)),
        });

  return visitorType + ': ' + sessionDuration;
}

function processTimeForExport(unixTs: number, timezone: string) {
  return moment(unixTs * 1000)
    .tz(timezone)
    .format();
}

/**
 * needed for escaping CSV values that might contain commas and break the table layout
 * @param value
 */
function wrapWithDoubleQuotes(value: string | number | boolean) {
  return `"${value}"`;
}

export function processExportVisitsData(
  data: VisitsData,
  translate: any,
  timezone: string,
  urlviewFlag: string,
  locale: string,
  isTrackingEnabled: (featureName: PRIVACY_FEATURES, countryCode: string) => boolean,
) {
  let appendCsv = '';

  data.forEach(function (visit) {
    const isTrackingIP = isTrackingEnabled(PRIVACY_FEATURES.ipAddressTracking, visit.countryCode);
    const isTrackingVisitorHistory = isTrackingEnabled(PRIVACY_FEATURES.individualPageHistory, visit.countryCode);

    const pageUrl = () => {
      const pageUrlOrTitle = urlviewFlag === urlViews.URL ? visit.page.url : visit.page.title;
      if (!isTrackingVisitorHistory) return '-';
      return visit.page ? pageUrlOrTitle : 'Home';
    };
    appendCsv +=
      wrapWithDoubleQuotes(translate(`country.name.${visit.countryCode}`)) +
      ',' +
      wrapWithDoubleQuotes(isTrackingIP ? visit.ip : '-') +
      ',' +
      wrapWithDoubleQuotes(processVisitStatus(visit, translate)) +
      ',' +
      wrapWithDoubleQuotes(translate(`panels.latestVisitors.deviceType.${visit.device}`)) +
      ',' +
      wrapWithDoubleQuotes(visit.platform) +
      ',' +
      wrapWithDoubleQuotes(visit.browser) +
      ',' +
      wrapWithDoubleQuotes(pageUrl()) +
      ',' +
      wrapWithDoubleQuotes(processTimeForExport(visit.unixTs, timezone)) +
      ',' +
      wrapWithDoubleQuotes(addNumberSeparator(visit.pagesCount, locale)) +
      ',' +
      wrapWithDoubleQuotes(formatSessionDuration(visit.sessionDuration)) +
      '\n';
  });

  return appendCsv;
}

export function processExportVisitorsData(
  data: VisitorsData,
  translate: any,
  timezone: string,
  urlviewFlag: string,
  isTrackingEnabled: (featureName: PRIVACY_FEATURES, countryCode: string) => boolean,
) {
  let appendCsv = '';

  data.forEach(function (visitor) {
    const isTrackingIP = isTrackingEnabled(PRIVACY_FEATURES.ipAddressTracking, visitor.countryCode);
    const isTrackingVisitorHistory = isTrackingEnabled(PRIVACY_FEATURES.individualPageHistory, visitor.countryCode);
    const ipValue = isTrackingIP ? visitor.ip : '-';
    const pageUrlOrTitle = urlviewFlag === urlViews.URL ? visitor.pageVisit.url : visitor.pageVisit.title;

    appendCsv +=
      wrapWithDoubleQuotes(translate(`country.name.${visitor.countryCode}`)) +
      ',' +
      wrapWithDoubleQuotes(ipValue) +
      ',' +
      wrapWithDoubleQuotes(translate(`card.latestVisits.${getTypeOfVisitor(visitor.status)}`)) +
      ',' +
      wrapWithDoubleQuotes(translate(`panels.latestVisitors.deviceType.${visitor.device}`)) +
      ',' +
      wrapWithDoubleQuotes(visitor.platform) +
      ',' +
      wrapWithDoubleQuotes(visitor.browser) +
      ',' +
      wrapWithDoubleQuotes(isTrackingVisitorHistory ? pageUrlOrTitle : '-') +
      ',' +
      wrapWithDoubleQuotes(
        translate('panels.latestVisitors.xPages', {
          count: visitor.pageVisitsInSession,
        }),
      ) +
      ',' +
      wrapWithDoubleQuotes(processTimeForExport(visitor.pageVisit.unixTs, timezone)) +
      ',' +
      wrapWithDoubleQuotes(visitor.companyOrgName) +
      ',' +
      wrapWithDoubleQuotes(visitor.companyOrgType) +
      ',' +
      wrapWithDoubleQuotes(visitor.hasRecording) +
      ',' +
      formatSessionDuration(visitor.duration) +
      ',' +
      wrapWithDoubleQuotes(visitor.sessionExtras.seen) +
      ',' +
      wrapWithDoubleQuotes(visitor.adCampaignSource) +
      ',' +
      wrapWithDoubleQuotes(visitor.adCampaignMedium) +
      ',' +
      wrapWithDoubleQuotes(visitor.adCampaignTerm) +
      ',' +
      wrapWithDoubleQuotes(visitor.adCampaignKey) +
      ',' +
      wrapWithDoubleQuotes(visitor.adCampaignName) +
      '\n';
  });

  return appendCsv;
}

export function processExportHeatmapRawData(
  data: Array<{
    selector: string;
    visible: boolean;
    pixelsScrolled: number;
    count: number;
    percent: number;
  }>,
) {
  let appendCsv = '';

  data.forEach((item) => {
    appendCsv +=
      item.selector +
      ',' +
      (typeof item.visible === 'boolean' ? item.visible : item.pixelsScrolled ? item.pixelsScrolled + ',' : '') +
      item.count +
      ',' +
      item.percent +
      '\n';
  });

  return appendCsv;
}

export function downloadResponse(data: string, filename: string, mime = 'text/csv') {
  return new Promise((resolve, reject) => {
    const blob = new Blob([data], { type: mime });
    if (isSafari(window.navigator.userAgent)) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result as string;

        if (!base64data) {
          reject();
          return;
        }

        resolve({
          href: 'data:attachment/csv' + base64data.slice(base64data.search(/[,;]/)),
          download: filename,
        });
      };
    } else {
      const blobURL = window.URL.createObjectURL(blob);
      downloadData(blobURL, filename);
      resolve(void 0);
    }
  });
}

export function downloadData(dataURL: string, filename: string) {
  const tempLink = document.createElement('a');
  document.body.appendChild(tempLink);
  tempLink.setAttribute('href', dataURL);
  tempLink.setAttribute('download', filename);
  tempLink.click();
  document.body.removeChild(tempLink);
}

export const getPollLogoUrl = (websiteId: string, pollId: string) => {
  return `${BaseConfig.getApiBaseUrl()}/websites/${websiteId}/polls/${pollId}/logo`;
};

export const getAgencyLogoUrl = (userId: string, agencyId: string) => {
  return `${BaseConfig.getApiBaseUrl()}/users/${userId}/agencyui/${agencyId}/logo?_=${+new Date()}`;
};

export const getSurveyLogoUrl = (websiteId: string, surveyId: string) => {
  return `${BaseConfig.getApiBaseUrl()}/websites/${websiteId}/surveys/${surveyId}/logo`;
};

export function getDownloadAgreementLink(token: string, fileName: string) {
  const apiBaseUrl = BaseConfig.getApiBaseUrl();
  return apiBaseUrl + `/agreements/download/${fileName}?access-token=${token}`;
}
