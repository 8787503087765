import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import NotificationSystem from 'react-notification-system';

function getNotificationSystemStyle() {
  return {
    NotificationItem: {
      DefaultStyle: {
        position: 'fixed',
        margin: '20px 0 0',
        padding: '60px 67px 60px 75px',
        height: '300px',
        width: '500px',
        right: 0,
        borderRadius: '18px 0 0 18px',
        border: 0,
        opacity: 0.9,
        backgroundColor: '#111111',
        isVisible: {
          opacity: 0.9,
        },
      },
    },
    Dismiss: {
      DefaultStyle: {
        right: '30px',
        top: '30px',
        width: '18px',
        height: '18px',
        fontFamily: `${window.fontFamily}, sans-serif`,
        fontWeight: 300,
        fontSize: '22px',
        backgroundColor: 'transparent',
        color: '#ffffff',
        borderRadius: 0,
      },
    },
  };
}

export default class NotificationSystemWrapperComponent extends PureComponent {
  componentDidUpdate(prevProps) {
    const { translate, notification } = this.props;
    if (!prevProps.notification && this.props.notification) {
      //Clear all open notifications before adding a new one.
      this.notificationSystem.clearNotifications();
      const processedNotification = {
        ...notification,
        title: translate(notification.title),
      };
      this.notificationSystem.addNotification(processedNotification);
      this.props.removeNotificationFromStore();
    }
    if (!prevProps.needToClearNotifications && this.props.needToClearNotifications) {
      this.notificationSystem.clearNotifications();
      this.props.resetClearNotifications();
    }
  }

  render() {
    return <NotificationSystem ref={(node) => (this.notificationSystem = node)} style={getNotificationSystemStyle()} />;
  }
}

NotificationSystemWrapperComponent.propTypes = {
  notification: PropTypes.any,
  needToClearNotifications: PropTypes.bool.isRequired,
  resetClearNotifications: PropTypes.func.isRequired,
  removeNotificationFromStore: PropTypes.func.isRequired,
};
