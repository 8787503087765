import React from 'react';
import PropTypes from 'prop-types';
import { UnknownOS } from './OS';

export function Device(props) {
  const device = props.type ? props.type : '';
  switch (device.toLowerCase()) {
    case 'desktop':
      return <Desktop className={props.className}/>;
    case 'mobile':
      return <Tablet className={props.className}/>;
    case 'phone':
      return <Mobile className={props.className}/>;
    case 'unknown':
      return <UnknownOS className={props.className} />;
    default:
      return <span>{device}</span>;
  }
}

Device.propTypes = {
  'className': PropTypes.string.isRequired,
  'type': PropTypes.string,
};

function Desktop(props) {
  return <svg viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M36 17H0V20H36V17ZM35 18H1V19H35V18Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M32 0H4V18H32V0ZM31 1H5V17H31V1Z"/>
  </svg>
}

function Tablet(props) {
  return <svg width="31" height="20" viewBox="0 0 31 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M31 0H0V20H31V0ZM30 1H1V19H30V1Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M27 12C28.1055 12 29 11.1046 29 10C29 8.89539 28.1055 8 27 8C25.8945 8 25 8.89539 25 10C25 11.1046 25.8945 12 27 12ZM27 11C27.5527 11 28 10.5522 28 10C28 9.60181 27.7676 9.25793 27.4316 9.09705C27.3008 9.03479 27.1543 9 27 9C26.4473 9 26 9.44775 26 10C26 10.5522 26.4473 11 27 11Z"/>
  </svg>
}

function Mobile(props) {
  return <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 20L12 0L9.53674e-07 -5.24537e-07L7.94466e-08 20L12 20ZM11 19L11 1L1 1L1 19L11 19Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4 16C4 17.1046 4.89543 18 6 18C7.10457 18 8 17.1046 8 16C8 14.8954 7.10457 14 6 14C4.89543 14 4 14.8954 4 16ZM5 16C5 16.5523 5.44772 17 6 17C6.55228 17 7 16.5523 7 16C7 15.4477 6.55228 15 6 15C5.44772 15 5 15.4477 5 16Z"/>
  </svg>
}
