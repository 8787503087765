import { Table } from '@tanstack/react-table';
import { DataTablePaginationV2 } from '@va/ui/design-system';
import { Portal } from '@va/util/components';

export const PaginationV8 = <T,>({
  table,
  containerId,
  showCountInfo,
  showPageSizeSelector,
  showPaginationBtns,
}: {
  table: Table<T>;
  containerId?: string;
  showCountInfo?: boolean;
  showPageSizeSelector?: boolean;
  showPaginationBtns?: boolean;
}) => {
  const state = table.getState();

  const pag = (
    <DataTablePaginationV2
      total={table.getRowCount()}
      currentPage={state.pagination.pageIndex}
      pageSize={state.pagination.pageSize}
      setCurrentPage={table.setPageIndex}
      setPageSize={table.setPageSize}
      canNextPage={table.getCanNextPage()}
      canPreviousPage={table.getCanPreviousPage()}
      totalPages={table.getPageCount()}
      showCountInfo={showCountInfo}
      showPageSizeSelector={showPageSizeSelector}
      showPaginationBtns={showPaginationBtns}
    />
  );

  if (containerId) {
    return <Portal elementSelector={`#${containerId}`}>{pag}</Portal>;
  }

  return pag;
};
