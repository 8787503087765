import { MinusIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, InputMessage, Paragraph, TextInput, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { isNil } from 'lodash';
import { ChangeEvent, forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { filterValidationKeys } from '../../constants';
import { FilterInputComponentRefType } from '../../filter-types';
import { useFiltersContext } from '../../filters-context';
import { useFilterValidation } from '../../useFilterValidation';
import { PickOperatorBtn } from '../PickOperatorBtn';
import { useOperator } from '../useOperator';
import { MultiSelectFields } from './MultiSelectFields';
import { TextFilter, TextFilterValue } from './TextFilter';

export const TextFilterInput = forwardRef<FilterInputComponentRefType, { filter: TextFilter }>(({ filter }, ref) => {
  const { inputProps, operator: initialOperator, operatorOptions, values } = filter;
  const [allValues, setAllValues] = useState<TextFilterValue[]>(values.length === 0 ? [''] : values);

  const { applySingleNewFilter } = useFiltersContext();

  const { operator, setOperator } = useOperator(initialOperator);

  const onSubmit = useCallback(() => {
    const clone = Object.assign({}, filter);
    clone.values = allValues;
    clone.operator = operator;
    applySingleNewFilter(clone);
  }, [allValues, applySingleNewFilter, filter, operator]);

  const { handleSubmit, error, validate } = useFilterValidation({
    value: allValues,
    onSubmit: onSubmit,
    validationFunc: (val) => {
      const hasUndefinedValues = val.filter((item) => {
        return isNil(item) || (typeof item === 'string' && !item.replace(/\s/g, '').length);
      });

      if (hasUndefinedValues.length > 0 || val.length === 0) {
        return filterValidationKeys.required;
      }
    },
  });

  useImperativeHandle(ref, () => ({ submit: handleSubmit, error }));

  const placeholder = useMemo(() => {
    const p = inputProps?.placeholder;
    if (!p) return '';
    if (typeof p === 'string') {
      return p;
    }

    if (typeof p === 'function') {
      return p(operator);
    }
  }, [inputProps?.placeholder, operator]);

  return (
    <div className='space-y-3 bg-white'>
      <PickOperatorBtn onOperatorChange={setOperator} operatorOptions={operatorOptions} selectedOperator={operator} />

      <MultiSelectFields
        filter={filter}
        placeholder={placeholder}
        error={error}
        setAllValues={(value) => setAllValues(value)}
        allValues={allValues}
      />
    </div>
  );
});

export const OrOption = ({
  placeholder,
  onChange,
  value,
  error,
  onRemove,
  clearField,
}: {
  placeholder?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  error?: string;
  onRemove: () => void;
  clearField: () => void;
}) => {
  const translate = useTranslate();
  return (
    <div className='flex flex-col'>
      <div className='flex gap-[2px]'>
        <Paragraph
          weight={fontWeights.medium}
          size={paragraphSizes.tiny}
          className='h-12 rounded-l-xl flex items-center justify-center px-3 bg-white-snow'
        >
          {translate('all.filters.combinationalOperators.or')}
        </Paragraph>
        <TextInput
          data-testid='text-filter-input'
          debouncedInput={true}
          value={value}
          clearField={clearField}
          onChange={onChange}
          placeholder={placeholder}
          wrapperClassName='w-full'
          heightClass='h-12'
          inputWrapperClassName='!rounded-none'
        />
        <Button
          icon={(_, color) => <MinusIcon color={color} />}
          color='tertiary'
          className='rounded-l-none'
          onClick={onRemove}
        />
      </div>
      {error && <InputMessage error={error} />}
    </div>
  );
};
