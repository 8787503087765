import { fontWeightClassName, fontWeights } from '@va/ui/design-system';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { HeadingProps } from './types';

export const Heading4 = ({
  weight = fontWeights.normal,
  colorClassName = 'text-gray-code',
  className,
  children,
}: PropsWithChildren<HeadingProps>) => {
  const classes = classNames(
    `text-2xl leading-33 -tracking-0021 font-primary`,
    fontWeightClassName(weight),
    colorClassName,
    className,
  );

  return <h4 className={classes}>{children}</h4>;
};
