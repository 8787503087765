import { TEST_IDS } from '@va/constants';
import { BackIconRounded, FilterIcon, RightArrowIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { TestAttributes } from '@va/types/component';
import { Filter, NestedFiltersPage, isFilter, isNestedFiltersPage } from '@va/types/filters';
import { SearchFilterV2 } from '@va/ui/components/inputs';
import {
  Button,
  Paragraph,
  ParagraphWithTooltip,
  SelectableButtonsGroup,
  buildSelectableButton,
  fontWeights,
  paragraphSizes,
} from '@va/ui/design-system';
import { getResponsiveTooltipHeight } from '@va/util/helpers';
import { useSubmitOnEnter, useWindowDimensions } from '@va/util/hooks';
import { useCustomizationContext, usePermissionsProvider } from '@va/util/misc';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGlobalFiltersConfig } from './ctx';
import { FilterOptions, useFiltersContext } from './filters-context';

type Tab = {
  label: string;
  component: ({ closeDropdown }: { closeDropdown: () => void }) => JSX.Element;
  disabled: boolean;
} & TestAttributes;

const buildTab = (tab: Tab) => tab;

export const AvailableFiltersDropdown = ({ closeDropdown }: { closeDropdown: () => void }) => {
  const [activeTab, setActiveTab] = useState(0);

  const { templatesEnabled } = useFiltersContext();
  const { TemplatesTab } = useGlobalFiltersConfig();

  const translate = useTranslate();

  const tabs = useMemo(() => {
    const list = [
      buildTab({
        label: translate('all.filters.addNewFilter'),
        component: AddNewFilterTab,
        disabled: false,
        'data-testid': TEST_IDS.generic.filters.newFilterTabBtn,
      }),
    ];

    if (TemplatesTab) {
      list.push(
        buildTab({
          label: translate('all.filterTemplates.tabName'),
          component: TemplatesTab,
          disabled: !templatesEnabled,
          'data-testid': TEST_IDS.generic.filters.filterTemplatesTabBtn,
        }),
      );
    }

    return list;
  }, [TemplatesTab, templatesEnabled, translate]);

  const ActiveTabComponent = useMemo(() => tabs[activeTab]?.component, [activeTab, tabs]);

  const tabButtonOptions = useMemo(
    () =>
      tabs.map((tab, index) =>
        buildSelectableButton({
          label: tab.label,
          value: index,
          className: '!flex-1',
          disabled: tab.disabled,
          'data-testid': tab['data-testid'],
        }),
      ),
    [tabs],
  );

  useEffect(() => {
    // We need this if a tab is added or removed dynamically
    if (!ActiveTabComponent) {
      setActiveTab((prev) => prev - 1);
    }
  }, [ActiveTabComponent]);

  return (
    <div
      data-testid={TEST_IDS.generic.filters.dropdownContainer}
      className='p-1.5 space-y-1 bg-white text-gray-charcoal rounded-18px shadow-overlay w-auto md:w-480px'
    >
      {tabs.length > 0 && (
        <SelectableButtonsGroup
          flexDirectionClass='flex-row !gap-0'
          buttons={tabButtonOptions}
          selectedValue={activeTab}
          small={true}
          onChange={(val) => {
            setActiveTab(val as number);
          }}
        />
      )}
      {ActiveTabComponent && <ActiveTabComponent closeDropdown={closeDropdown} />}
    </div>
  );
};

const AddNewFilterTab = ({ closeDropdown }: { closeDropdown: () => void }) => {
  const { allFilterOptions } = useFiltersContext();

  const [titles, setTitles] = useState<string[]>([]);
  const [filters, setFilters] = useState<FilterOptions[]>([
    allFilterOptions.sort((a, b) => (a.label > b.label ? 1 : -1)),
  ]);
  const [selectedFilter, setSelectedFilter] = useState<Filter | undefined>(undefined);

  const filtersOnCurrentPage = filters[filters.length - 1];
  const titleOnCurrentPage = titles[titles.length - 1];

  const onClose = useCallback(() => {
    closeDropdown();
    setSelectedFilter(undefined);
  }, [closeDropdown]);

  return (
    <>
      {!selectedFilter && (
        <AvailableFiltersList
          closeDropdown={onClose}
          onFilterClick={(item) => {
            if (isNestedFiltersPage(item)) {
              setFilters((prev) => [...prev, item.filters]);
              setTitles((prev) => [...prev, item.label]);
              return;
            }
            setSelectedFilter(item);
          }}
          title={titleOnCurrentPage}
          onBackClick={() => {
            if (filters.length > 1) {
              setFilters((prev) => {
                return prev.slice(0, prev.length - 1);
              });
              setTitles((prev) => {
                return prev.slice(0, prev.length - 1);
              });
            }
          }}
          availableFilters={filtersOnCurrentPage}
        />
      )}
      {selectedFilter && (
        <IndividualFilter
          onBackClick={() => {
            setSelectedFilter(undefined);
          }}
          filter={selectedFilter}
          onClose={onClose}
        />
      )}
    </>
  );
};

const AvailableFiltersList = ({
  availableFilters,
  onFilterClick,
  closeDropdown,
  onBackClick,
  title,
}: {
  availableFilters: FilterOptions;
  onFilterClick: (filter: Filter | NestedFiltersPage) => void;
  closeDropdown: () => void;
  onBackClick?: () => void;
  title?: string;
}) => {
  const [filterValue, setFilterValue] = useState('');

  const { height } = useWindowDimensions();
  const { isFilterApplied } = useFiltersContext();

  const translate = useTranslate();

  const filteredOptions = useMemo(() => {
    if (!filterValue) return availableFilters;

    const filters: FilterOptions = [];

    const findRec = (options: FilterOptions) => {
      options.forEach((item) => {
        const isNested = isNestedFiltersPage(item);
        const labelMatches = item.label.toLowerCase().includes(filterValue.toLowerCase());

        //If filter not nested and label matches, add filter to the array;
        //If filter is nested and label matches, add parent filter to array if searchValue doesn't match any child filter
        if (labelMatches) {
          if (
            !isNested ||
            item.filters.every((filter) => !filter.label.toLowerCase().includes(filterValue.toLowerCase()))
          ) {
            filters.push(isNested ? (item as NestedFiltersPage) : (item as Filter));
          }
        }

        if (isNested) {
          findRec(item.filters);
        }
      });
    };

    findRec(availableFilters);

    return filters;
  }, [availableFilters, filterValue]);

  return (
    <div>
      {title && onBackClick && <SubFilterTabHeader onBackClick={onBackClick} title={title} />}
      <SearchFilterV2 onChange={setFilterValue} shouldClearField={!filterValue} />
      <ul
        style={{ height: getResponsiveTooltipHeight(height) }}
        className='mt-1 px-2 overflow-auto min-h-[100px] max-h-[250px] scrollbar scrollbar-thumb'
      >
        {filteredOptions.map((item, index) => {
          if (isFilter(item) && isFilterApplied(item.id)) {
            return null;
          }

          return (
            <li
              data-testid={TEST_IDS.helpers.createListItemId(item.id)}
              key={index}
              onClick={() => {
                onFilterClick(item);
                setFilterValue('');
              }}
              className='cursor-pointer flex rounded-12 items-center gap-3 p-2 hover:bg-white-snow'
            >
              <div className='flex overflow-hidden items-center gap-2'>
                <FilterIcon className='w-3 h-3' color='#969696' />
                <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.medium}>
                  {item.label}
                </ParagraphWithTooltip>
              </div>
              <RightArrowIcon className='ml-auto shrink-0 w-4 h-4' color='#969696' />
            </li>
          );
        })}
        {filteredOptions.length === 0 && (
          <Paragraph className='py-3' weight={fontWeights.medium}>
            {translate('filters.noFiltersFound')}
          </Paragraph>
        )}
      </ul>
      <FilterActionButtons onClose={closeDropdown} />
    </div>
  );
};

const IndividualFilter = ({
  filter,
  onBackClick,
  onClose,
}: {
  filter: Filter;
  onBackClick: () => void;
  onClose: () => void;
}) => {
  const { expandFilters } = useFiltersContext();
  const inputRef = useRef<{ submit: () => void }>(null);

  const Input = filter.input;

  const handleSubmit = useCallback(() => {
    try {
      inputRef.current?.submit();
      expandFilters();
      onClose();
      // eslint-disable-next-line no-empty
    } catch {}
  }, [onClose, expandFilters]);

  useSubmitOnEnter(handleSubmit);

  return (
    <div>
      <SubFilterTabHeader title={filter.label} onBackClick={onBackClick} />
      <Input ref={inputRef} filter={filter} {...filter?.inputProps} />
      <FilterActionButtons onClose={onClose} onSubmit={handleSubmit} />
    </div>
  );
};

export const FilterActionButtons = ({
  onClose,
  onSubmit,
  submitButtonDisabled,
  wrapperClassName,
}: {
  onClose?: () => void;
  onSubmit?: () => void;
  submitButtonDisabled?: boolean;
  wrapperClassName?: string;
}) => {
  const translate = useTranslate();

  const { canEditFilters } = usePermissionsProvider();
  const { getCustomValue } = useCustomizationContext();

  return (
    <div className={classNames('flex gap-2 w-full mt-2', wrapperClassName)}>
      {onClose && (
        <Button
          data-testid={TEST_IDS.generic.buttons.close}
          color='tertiary'
          text={translate('button.close')}
          className='w-full !text-sm !p-2 rounded-lg'
          onClick={onClose}
        />
      )}
      {onSubmit && (
        <Button
          data-testid={TEST_IDS.generic.buttons.submit}
          color='primary'
          tooltip={
            !canEditFilters &&
            getCustomValue('disabledFiltersMessage', translate('all.defaultWarnings.cantEditFilters'))
          }
          disabled={submitButtonDisabled || !canEditFilters}
          text={translate('button.submit')}
          className='w-full !text-sm !p-2 rounded-lg'
          onClick={onSubmit}
        />
      )}
    </div>
  );
};

export const SubFilterTabHeader = ({ onBackClick, title }: { onBackClick: () => void; title: string }) => {
  return (
    <div className='mb-2'>
      <div className='flex items-center'>
        <Button className='mr-3 scale-75' color='tertiary' icon={() => <BackIconRounded />} onClick={onBackClick} />

        <ParagraphWithTooltip weight={fontWeights.medium} colorClassName='text-gray-charcoal'>
          {title}
        </ParagraphWithTooltip>
      </div>

      <div className='h-1.5px bg-gradient-to-r from-gray-gallery-darker via-gray-concrete to-white -mx-1'></div>
    </div>
  );
};
