import moment, { Moment } from 'moment-timezone';
import { getBrowserLanguage } from '../browser';

export function getFilterDateWithTimezone(date: Moment, timezone: string) {
  const changedDate = date.clone();

  //add timezone for date
  changedDate.tz(timezone);

  changedDate.add(date.utcOffset() - changedDate.utcOffset(), 'minutes');

  return changedDate;
}

const DEFAULT_DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

export function getLocalizedDate(timestamp: number, locale = 'en', options = DEFAULT_DATE_FORMAT_OPTIONS) {
  const date = moment(timestamp * 1000).toDate();
  const formatOptions = { ...DEFAULT_DATE_FORMAT_OPTIONS, ...options };
  if (locale === 'en') {
    const browserLocale = getBrowserLanguage();
    if (browserLocale.indexOf('en') === 0) {
      return date.toLocaleDateString(browserLocale, formatOptions);
    }

    return date.toLocaleDateString('en-US', formatOptions);
  }

  return date.toLocaleDateString(locale, formatOptions);
}

export const getFormattedDateAndTime = (unixTime: number, locale: string) => {
  return unixTime ? moment(unixTime * 1000).format('L - LT') : '-';
};

export const getTimestampFromIso = (iso: string) => {
  return moment(iso).unix();
};
