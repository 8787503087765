import { getInstanceId } from '@va/dashboard/selectors/app';
import { CompanyType, useLatestVisitorsColumnsV8 } from '@va/dashboard/shared/visitors';
import { useCurrentPeriodFilter, useMutateOnRefresh } from '@va/dashboard/util-hooks';
import { post } from '@va/http-client';
import { useFiltersContext } from '@va/shared/feature-filters';
import { DeviceTypesEnum } from '@va/types/device';
import { VisitorTypes } from '@va/types/visitors';
import { DataTableV8, useControlledTableState } from '@va/ui/components/data-table';
import { LocationType, useRefreshContext } from '@va/util/components';
import { toQueryString } from '@va/util/helpers';
import { useLazyDataFetch } from '@va/util/hooks';
import { RequestCanceler, useCancelOnUnmount } from '@va/util/misc';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

type PageVisit = {
  key: string;
  url: string;
  title: string;
  unixTs: number;
};

type SessionExtras = {
  id: string;
  seen: boolean;
  star: boolean;
  tags: Array<string>;
};

export type LatestVisitorsItem = {
  id: string;
  visitorKey: string;
  pageVisitsInSession: number;
  device: DeviceTypesEnum;
  platform: string;
  browser: string;
  countryCode: string;
  location: LocationType;
  status: VisitorTypes;
  ip: string;
  companyOrgName: string | null;
  companyOrgType: CompanyType | null;
  hasRecording: boolean;
  duration: number | null;
  sessionExtras: SessionExtras;
  visitorStatus: number;
  adCampaignSource: string;
  adCampaignMedium: string;
  adCampaignTerm: string;
  adCampaignKey: string;
  adCampaignName: string;
  pageVisit: PageVisit;
  lastVisitTs: number;
};

export const LatestVisitorsTable = memo(
  ({
    disablePagination,
    initialPageSize,
    paginationContainerId,
    displayTableHeader,
  }: {
    disablePagination?: boolean;
    initialPageSize?: number;
    paginationContainerId?: string;
    displayTableHeader?: boolean;
  }) => {
    const { pagination, setPagination } = useControlledTableState({
      pagination: { pageSize: initialPageSize },
    });

    const { data, isLoading, mutate } = useGetLatestVisitorsList(pagination.pageNumber, pagination.pageSize);

    const [rowCount, setRowCount] = useState(data?.meta?.total ?? 0);

    useEffect(() => {
      setRowCount((prev) => {
        if (isLoading) return prev;
        if (!data) return prev;
        return data?.meta?.total;
      });
    }, [isLoading, data]);

    const columnsV8 = useLatestVisitorsColumnsV8();
    const { isManualRefreshing } = useRefreshContext();
    const isInProgress = isManualRefreshing || isLoading;

    useMutateOnRefresh(mutate);

    return (
      <DataTableV8<LatestVisitorsItem>
        className='lg:min-w-[1200px]'
        columns={columnsV8}
        isLoading={isInProgress}
        data={data?.payload?.data ?? []}
        rowCount={rowCount}
        state={{ pagination }}
        disablePagination={disablePagination}
        paginationContainerId={paginationContainerId}
        onPaginationChange={setPagination}
        displayTableHeader={displayTableHeader}
      />
    );
  },
);

export const usePrepareLatestVisitorsListRequests = () => {
  const { appliedFilterValues } = useFiltersContext();
  const { from, until } = useCurrentPeriodFilter();

  return { payload: appliedFilterValues, from, until };
};

export const useGetLatestVisitorsList = (page: number, pageSize: number) => {
  const websiteId = useSelector(getInstanceId);

  const { payload, from, until } = usePrepareLatestVisitorsListRequests();

  const query = useMemo(
    () => ({
      from,
      until,
      page,
      pageSize,
    }),
    [from, until, page, pageSize],
  );

  const requestId = useMemo(() => RequestCanceler.generateId(), []);
  useCancelOnUnmount(requestId);

  const fetcher = useCallback(
    async (url: string, queryData: any, payload: any) => {
      const signal = RequestCanceler.onRequestStart(requestId);
      const res = await post(url, queryData, payload, { signal });
      RequestCanceler.onRequestEnd(requestId);
      return res;
    },
    [requestId],
  );

  return useLazyDataFetch<{
    payload: { data: LatestVisitorsItem[] | null };
    meta: { pageSize: number; pageTotal: number; total: number; page: number };
  }>(
    [`/v2/websites/${websiteId}/sessions/table?${toQueryString(query)}`, undefined, payload],
    {
      onError: () => {
        RequestCanceler.onRequestEnd(requestId);
      },
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
    ({ payload, meta }) => ({ payload, meta: { ...meta, page: meta.page } }),
    fetcher,
  );
};
