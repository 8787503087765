import { DataViewOption } from '@va/constants';
import { usePageHeaderProps } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { ReportBlock } from '@va/ui/design-system';
import { FC, useMemo } from 'react';
import LeafletMapPanel from '../leaflet-map/LeafletMap';

const LatestVisitorsMap: FC<{
  map: {
    data: any;
  };
  websiteUrl: string;
  isWixFree: boolean;
  refreshFunc: Function;
}> = ({ map, websiteUrl, isWixFree, refreshFunc }) => {
  const translate = useTranslate();

  const pageHeaderProps = useMemo(() => ({ refreshFn: refreshFunc }), [refreshFunc]);

  usePageHeaderProps(pageHeaderProps);

  return (
    <ReportBlock
      title={translate('all.latestVisitors.map.reportBlockTitle')}
      titleTooltip={translate('all.latestVisitors.map.reportBlockTitleTooltip')}
      visualization={{ selectedView: DataViewOption.map }}
    >
      <LeafletMapPanel
        isWixFree={isWixFree}
        translate={translate}
        cardName='latestVisitsGoogleMap'
        websiteUrl={websiteUrl}
        circleRadius={3000}
        {...map.data}
      />
    </ReportBlock>
  );
};

export default LatestVisitorsMap;
