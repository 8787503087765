import { TableListIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button } from '@va/ui/design-system';
import classNames from 'classnames';

export const SaveTemplateBtnComponent = ({
  onClick,
  isAddFilterBtnVisible,
  disabled,
  tooltip,
}: {
  onClick: () => void;
  isAddFilterBtnVisible: boolean;
  disabled?: boolean;
  tooltip?: string;
}) => {
  const translate = useTranslate();

  return (
    <Button
      color='quinary'
      text={translate('button.saveFiltersAsTemplate')}
      icon={(_, color) => <TableListIcon color={color} className='shrink-0' />}
      className={classNames('h-[42px] grow md:min-w-min', {
        'rounded-none': isAddFilterBtnVisible,
        'rounded-b-none md:rounded-r-none md:rounded-bl-12': !isAddFilterBtnVisible,
      })}
      textClasses='text-12'
      onClick={onClick}
      disabled={disabled}
      tooltip={tooltip}
    />
  );
};
