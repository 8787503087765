import { domainRegex, validationTranslationKeys } from '@va/constants';
import { useTranslate } from '@va/localization';
import {
  Button,
  Heading5,
  InputMessage,
  Paragraph,
  TextInput,
  fontWeights,
  paragraphSizes,
} from '@va/ui/design-system';
import { useFormik } from 'formik';
import * as yup from 'yup';

export const AddWebsiteForm: React.FC<{
  onSubmit: (values: { url: string }) => void;
  isLoading: boolean;
  error?: Error | null;
  title: string;
  subtitle: string;
}> = ({ onSubmit, isLoading, error: apiError, title, subtitle }) => {
  const translate = useTranslate();

  const { handleChange, values, errors, handleSubmit, setFieldValue } = useFormik({
    onSubmit: (values) => {
      onSubmit({ ...values });
    },
    initialValues: { url: '' },
    validationSchema: getValidationSchema(translate),
  });

  return (
    <form className='mt-12 max-w-[540px] mx-auto' onSubmit={handleSubmit}>
      <Heading5 className='text-center' colorClassName='text-gray-code' weight={fontWeights.semibold}>
        {title}
      </Heading5>
      <Paragraph className='mb-24px text-center' weight={fontWeights.medium}>
        {subtitle}
      </Paragraph>
      <TextInput
        name='url'
        label={translate('onboarding.labels.websiteUrl')}
        value={values.url}
        clearField={() => {
          setFieldValue('url', '');
        }}
        error={errors.url}
        onChange={handleChange}
      />
      <Button
        disabled={isLoading}
        type={isLoading ? 'button' : 'submit'}
        className='w-full mt-18px'
        text={isLoading ? translate('button.loading') : translate('button.continue')}
      />
      <Paragraph className='!font-525 text-gray-devil mt-1 ml-2' size={paragraphSizes.tiny}>
        {translate('standalone.addWebsiteStep.information')}
      </Paragraph>
      {apiError && <InputMessage error={translate('notifications.generalError')} />}
    </form>
  );
};

function getValidationSchema(translate: Function) {
  return yup.object().shape({
    url: yup
      .string()
      .required(translate(validationTranslationKeys.required))
      .matches(domainRegex, translate(validationTranslationKeys.websiteUrl)),
  });
}
