export type WebsitesQueryFilter = {
  start: number;
  length: number;
  url: string;
  withSharedWebsites: boolean;
  withSubscription?: boolean;
};

export enum PageTypeEnum {
  dynamic = 'dynamic',
  static = 'static',
}

export enum UserRole {
  OWNER = 'owner',
  EDITOR = 'editor',
  WATCHER = 'watcher',
  DASHBOARD = 'dashboard',
}

export type StaticPageType = {
  key: string;
  url: string;
  title: string;
  type?: PageTypeEnum.static;
};

export type DynamicPageType = {
  key: string;
  name: string;
  pattern: string;
  type?: PageTypeEnum.dynamic;
};

export type SsrSettingsType = {
  key: string;
  paused: boolean;
  status: string;
  websiteKey: string;
  anyPage: boolean;
  clickAndScroll: boolean;
  minDuration: number;
  dynamicPages: DynamicPageType[];
  pages: StaticPageType[];
  pageKeys: string[];
  dynamicPageKeys: string[];
  urlPatterns: UrlPatternType[];
  error?: { message: string };
};

export enum UrlPatternTypeEnum {
  simple = 'SIMPLE',
  exact = 'EXACT',
  starts = 'STARTS',
  ends = 'ENDS',
  contains = 'CONTAINS',
}

export type UrlPatternType = {
  url: string;
  type: UrlPatternTypeEnum;
  id: string;
};

export enum URL_VIEW {
  title = 'title',
  url = 'url',
}

export type WebsiteResponse = {
  anonymizeIps: boolean;
  businessSector: number;
  companyName: string;
  consentlessTracking: boolean;
  country: string | null;
  email: string;
  eventAutoTracking: boolean;
  eventTracking: boolean;
  externalId: string | null;
  firstName: string;
  firstPremiumTs: number | null;
  firstWeekDay: number | null;
  frontendFlags: Record<string, unknown>;
  hadFirstVisit: boolean;
  hasCustomer: boolean;
  id: string;
  ignoreVisitsKey: string;
  importTs: number | null;
  installDate: number;
  ipCountryISO: string;
  isPayable: boolean;
  lastName: string;
  lastResetDate: number | null;
  lastVisitTs: number;
  maxPrivacyMode: boolean;
  metaSiteId: string | null;
  numberOfMonthlyVisits: number;
  platform: 'CUSTOM' | 'WIX';
  regionalFormat: null | unknown;
  resetDataRequested: null | boolean;
  status: 'ACTIVE' | 'REJECTED' | 'PENDING';
  sumoActive: boolean;
  timezone: string;
  url: string;
  whiteLabel: boolean;
  migrationStatus: unknown;
  types: Array<string>;
};

export type WebsiteType = {
  url: string;
  id: string;
  types: UserRole[];
  subscription: WebsiteSubscription | null;
  [key: string]: unknown;
};

export type WebsitesDataType = {
  hasContributor: boolean;
  length: number;
  start: number;
  total: number;
  totalWhiteLabeled: number;
  websites: WebsiteType[];
};

export type WebsiteSubscription = {
  activeUntil: null | string;
  isActiveUntil?: null | string;
  id: string;
  planId: string;
  status: SubscriptionStatus;
  updateUrl: string;
  planName: string;
};

export enum SubscriptionStatus {
  // === Paddle ====
  TRIALING = 'Trialing',
  PAUSED = 'Paused',
  DELETED = 'Deleted',
  // === Common ====
  ACTIVE = 'Active',
  PAST_DUE = 'Past Due',
  // === Braintree ====
  PENDING = 'Pending',
  CANCELED = 'Canceled',
  EXPIRED = 'Expired',
}
