import { setGlobalFilter } from '@va/dashboard/actions/ui';
import { getWebsite } from '@va/dashboard/selectors/core';
import { useCurrentPeriodFilter } from '@va/dashboard/util-hooks';
import { SessionStorage, updateSelectedPeriod } from '@va/util/helpers';
import { StorageKeysManager } from '@va/util/misc';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useRefreshDateFilter = () => {
  const dispatch = useDispatch();
  const { from, until, websiteId } = useCurrentPeriodFilter();
  const website = useSelector(getWebsite);

  useEffect(() => {
    const remainingTime = getRemainingTimeUntilMidnight();

    const timeoutId = setTimeout(() => {
      const todaysSessionDate = SessionStorage.getItem(StorageKeysManager.getTodayDateKey());
      if (!todaysSessionDate) return;

      let currentSessionMomentDate: moment.Moment | undefined = undefined;

      try {
        currentSessionMomentDate = moment(JSON.parse(todaysSessionDate)).tz(window.timezone);
      } catch (error) {
        console.error(error);
      }

      if (!currentSessionMomentDate) {
        return;
      }

      const { from: updatedFrom, until: updatedUntil } = updateSelectedPeriod(
        moment(from).tz(window.timezone),
        moment(until).tz(window.timezone),
        currentSessionMomentDate,
        websiteId,
        window.timezone,
        website.installDate,
      );
      dispatch(setGlobalFilter({ from: updatedFrom, until: updatedUntil }));
    }, remainingTime);

    return () => clearTimeout(timeoutId);
  }, [dispatch, from, until, website.installDate, websiteId]);
};

function getRemainingTimeUntilMidnight() {
  const now = moment().tz(window.timezone);
  const midnightMoment = moment().tz(window.timezone).endOf('day');
  return midnightMoment.diff(now);
}
