import { fontWeightClassName, fontWeights } from '@va/ui/design-system';
import classNames from 'classnames';
import { PropsWithChildren, forwardRef } from 'react';
import { HeadingProps } from './types';

export const Heading3 = forwardRef<HTMLHeadingElement, PropsWithChildren<HeadingProps>>(
  ({ weight = fontWeights.normal, colorClassName = 'text-gray-code', className, children, ...rest }, ref) => {
    const classes = classNames(
      `sm-initial:text-3xl sm-initial:leading-36 sm-initial:-tracking-0024 text-21 leading-30 -tracking-0021 font-primary`,
      fontWeightClassName(weight),
      colorClassName,
      className,
    );

    return (
      <h3 ref={ref} className={classes} {...rest}>
        {children}
      </h3>
    );
  },
);
