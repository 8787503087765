import Config from 'Config';
import { detect } from 'detect-browser';
import { getParameterByName } from '../url';
const MOBILE_MAX_WIDTH = 640;

export const mobileOS = {
  android: 'android',
  ios: 'ios',
  windows: 'windows',
  unknown: 'unknown',
};

export function isSafari(userAgent: string) {
  // eslint-disable-next-line no-useless-escape
  return /Version\/[\d\.]+.*Safari/.test(userAgent);
}

export const isFirefox = () => {
  const detectedBrowser = detect();
  if (!detectedBrowser) return false;
  return detectedBrowser.name === 'firefox';
};

export function isMobileDevice() {
  const mediaQuery = window.matchMedia(`(max-width: ${MOBILE_MAX_WIDTH}px)`);
  return mediaQuery.matches;
}

export function getBrowserLanguage() {
  return navigator.language;
}

export function getMobileOperatingSystem() {
  // TODO Recheck window.opera
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return mobileOS.windows;
  }

  if (/android/i.test(userAgent)) {
    return mobileOS.android;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return mobileOS.ios;
  }

  return mobileOS.unknown;
}

// Detects if device is in ProgressiveWebApp mode
export function isInWebApp() {
  const isInWebAppiOS =
    // @ts-ignore window.navigator.standalone available only on iOS Safari
    'standalone' in window.navigator && window.navigator.standalone;
  const isInWebAppChrome = window.matchMedia('(display-mode: standalone)').matches;

  return isInWebAppiOS || isInWebAppChrome;
}

export function extractLanguage() {
  let locale = (getParameterByName('locale') || window.navigator.language).substring(0, 2);
  if (!Config.supportedLanguages.includes(locale)) {
    locale = 'en';
  }
  return locale;
}
