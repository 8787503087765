import { useMutateOnRefresh } from '@va/dashboard/util-hooks';
import { GlobeIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { ProgressBarListItem } from '@va/ui/design-system';
import { Flag } from '@va/util/components';
import { sumBy } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { LocationRowDetails } from './LocationRowDetails';
import LocationsCard from './LocationsCard';
import { VisitorByCountry, noOfItemsToShow, useVisitorsByCountry } from './apiClient';

const VisitorsByCountryCard = () => {
  const translate = useTranslate();
  const { data, isLoading, mutate } = useVisitorsByCountry();

  const totalCount = useMemo(() => data?.totalCount ?? 0, [data?.totalCount]);
  const maxCount = useMemo(() => data?.maxCount ?? 0, [data]);

  const [showExtendedData, setShowExtendedData] = useState(false);
  const { isManualRefreshing } = useMutateOnRefresh(mutate);
  const visitorsByCountryData = useMemo(() => data?.sortedData || [], [data?.sortedData]);

  const dataToShow: VisitorByCountry[] = useMemo(
    () => getDataToShow(showExtendedData, visitorsByCountryData),
    [showExtendedData, visitorsByCountryData],
  );

  const othersCount = useMemo(() => {
    if (totalCount) return totalCount - sumBy(dataToShow, (v) => v.count);
    return 0;
  }, [totalCount, dataToShow]);

  const showChangeLoadedDataButton = useMemo(
    () => visitorsByCountryData.length > noOfItemsToShow.DEFAULT,
    [visitorsByCountryData.length],
  );

  const isCountryUnknown = useCallback((country: string) => {
    return isVisitorUnknown(country);
  }, []);

  return (
    <LocationsCard
      title={translate('all.latestVisitors.locations.byCountry.title')}
      isLoading={isLoading || isManualRefreshing}
      onChangeLoadedData={() => setShowExtendedData((prev) => !prev)}
      showExtendedData={showExtendedData}
      showChangeLoadedDataButton={showChangeLoadedDataButton}
      hasNoData={!dataToShow.length}
    >
      <>
        {dataToShow.map((visit, index) => (
          <ProgressBarListItem
            key={index}
            datasets={[{ color: 'var(--color-primary)', count: visit.count }]}
            totalCount={totalCount}
            maxCount={maxCount}
            countLabel={translate('label.visitors')}
            tooltip={
              isCountryUnknown(visit.countryCode) ? translate('panels.referringSites.tooltipUnknown') : undefined
            }
            rowDetails={
              <LocationRowDetails
                label={translate(`country.name.${visit.countryCode}`)}
                icon={<Flag countryCode={visit.countryCode} size='1x1' className='w-18 h-18 rounded-full' />}
              />
            }
          />
        ))}
        {showExtendedData && othersCount > 0 && (
          <ProgressBarListItem
            datasets={[{ color: 'var(--color-primary)', count: othersCount }]}
            totalCount={totalCount}
            maxCount={maxCount}
            countLabel={translate('label.visitors')}
            rowDetails={<LocationRowDetails label={translate('country.name.others')} icon={<GlobeIcon />} />}
          />
        )}
      </>
    </LocationsCard>
  );
};

export default memo(VisitorsByCountryCard);

export function isVisitorUnknown(item: string) {
  return item?.toLocaleLowerCase() === 'unknown';
}

export function getDataToShow(showExtendedData: boolean, data: any) {
  return showExtendedData ? data.slice(0, noOfItemsToShow.EXTENDED) : data.slice(0, noOfItemsToShow.DEFAULT);
}
