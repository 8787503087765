import Map from '@va/deprecated/components/maps/LeafletMap';
import { LoadingLogo, NoDataMessage } from '@va/ui/design-system';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './leaflet-map.scss';

export default class LeafletMapPanel extends PureComponent {
  hasInformation() {
    return !(
      this.props.items.length === 0 ||
      (this.props.items.length === 1 && isNaN(this.props.items[0].position[0]))
    );
  }

  renderMap() {
    const points = this.props.items.map((item) => ({
      sessionKey: item.sessionKey,
      isPrivate: item.isPrivate,
      lat: item.position[0],
      lng: item.position[1],
    }));

    return (
      <Map points={points} center={this.props.center} zoom={this.props.zoom} circleRadius={this.props.circleRadius} />
    );
  }

  renderPanel() {
    return (
      <div className='traffic-body leaflet-map-container'>
        <div className={`latest-visitors-map-panel`}>{this.renderMap()}</div>
      </div>
    );
  }

  renderContent() {
    if (!this.props.inProgress) {
      return this.hasInformation() ? this.renderPanel() : <NoDataMessage type='panel' />;
    }
  }

  render() {
    return this.props.inProgress ? (
      <div className='relative min-h-200'>
        <LoadingLogo />
      </div>
    ) : (
      <div className='traffic-box-container'>{this.renderContent()}</div>
    );
  }
}

LeafletMapPanel.propTypes = {
  translate: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  circleRadius: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      sessionKey: PropTypes.string.isRequired,
      position: PropTypes.arrayOf((propValue, key, componentName, location, propFullName) => {
        if (!Array.isArray(propValue) || propValue.length !== 2) {
          return new Error(`Invalid prop ${propFullName} supplied to ${componentName}. Expected array of length 2.`);
        }

        if (Number.isNaN(parseFloat(propValue[0])) || Number.isNaN(parseFloat(propValue[1]))) {
          return new Error(
            'Invalid prop `' +
              propFullName +
              '` supplied to' +
              ' `' +
              componentName +
              '`. Expected elements to be of type float.',
          );
        }
      }),
    }),
  ),
  cardName: PropTypes.string.isRequired,
  size: PropTypes.any.isRequired,
  center: PropTypes.any,
  zoom: PropTypes.any,
};

LeafletMapPanel.defaultProps = {
  size: { height: '0', width: '100' },
  dataset: '',
  markers: [],
  circleRadius: 2500,
};
