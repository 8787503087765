import { NavAddCircle, StopIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { useConfirmationDialogContext } from '@va/shared/util-confirmation';
import { Button, HorizontalDivider, Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { useCustomizationContext, usePermissionsProvider } from '@va/util/misc';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { RemoveAdditionalFiltersModal } from './RemoveAdditionalFiltersModal';
import { AddFilterTooltip } from './add-filter-tooltip/AddFilterTooltip';
import './applied-filters.scss';
import { useGlobalFiltersConfig } from './ctx';
import { useFiltersContext } from './filters-context';

export const AppliedFilters = ({
  className,
  keepHorizontalDivider = true,
}: {
  className?: string;
  keepHorizontalDivider?: boolean;
}) => {
  const { appliedFilters, isExpanded, deleteAllAppliedFilters, hasAvailableFiltersLeft, templatesEnabled } =
    useFiltersContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const translate = useTranslate();
  const { confirm } = useConfirmationDialogContext();
  const { SaveTemplateButton } = useGlobalFiltersConfig();
  const { canEditFilters } = usePermissionsProvider();
  const { getCustomValue } = useCustomizationContext();

  const horizontalSeparatorLine = useMemo(() => <HorizontalDivider className='-translate-x-5' />, []);

  const shouldDisplayAddFilterBtn = hasAvailableFiltersLeft;

  const filterItems = useMemo(() => {
    const items: React.ReactNode[] = [];

    appliedFilters.forEach((filter, index) => {
      const Input = filter.appliedInput;
      items.push(<Input filter={filter} {...filter.inputProps} />);

      if (index !== appliedFilters.length - 1) {
        items.push(<CombinationalOperator />);
      }
    });

    return items;
  }, [appliedFilters]);

  if (appliedFilters.length === 0 || !isExpanded) {
    return null;
  }

  return (
    <div className='shrink-0 py-[1px] applied-filter-container'>
      {keepHorizontalDivider && horizontalSeparatorLine}
      <div className={classNames('flex flex-wrap min-h-12 gap-2 w-full', className)}>
        {filterItems.map((item, index) => (
          <div key={index} className='contents'>
            {item}
          </div>
        ))}
        <div className='filter-buttons min-h-12 flex flex-row bg-gray-concrete p-3px w-auto rounded-15 gap-[1.5px]'>
          {shouldDisplayAddFilterBtn && (
            <AddFilterTooltip isOpen={isDropdownOpen} openChange={setIsDropdownOpen}>
              <div className='grow'>
                <Button
                  tooltip={
                    !canEditFilters &&
                    getCustomValue('disabledFiltersMessage', translate('all.defaultWarnings.cantEditFilters'))
                  }
                  disabled={!canEditFilters}
                  color='quinary'
                  icon={(_, color) => <NavAddCircle className='shrink-0' color={color} />}
                  text={translate('button.addNewFilter')}
                  className='w-full h-[42px] rounded-r-none add-filter-btn'
                  textClasses='!text-12'
                  onClick={() => setIsDropdownOpen((prev) => !prev)}
                />
              </div>
            </AddFilterTooltip>
          )}
          {canEditFilters && templatesEnabled && SaveTemplateButton && (
            <SaveTemplateButton isAddFilterBtnVisible={shouldDisplayAddFilterBtn} />
          )}
          {canEditFilters && (
            <Button
              color='quinary'
              text={translate('button.removeAllFilters')}
              icon={() => <StopIcon className='shrink-0' color={'#EA2A0C'} />}
              className={classNames('h-[42px] grow md:min-w-min rounded-l-none remove-filters-btn')}
              textClasses='text-12 text-red-pure'
              onClick={() =>
                confirm(() => {
                  deleteAllAppliedFilters();
                }, RemoveAdditionalFiltersModal)
              }
            />
          )}
        </div>
      </div>
      {keepHorizontalDivider && horizontalSeparatorLine}
    </div>
  );
};

export default AppliedFilters;

const CombinationalOperator = () => {
  const translate = useTranslate();
  return (
    <Paragraph
      weight={fontWeights.medium}
      size={paragraphSizes.tiny}
      className='h-12 rounded-xl flex items-center justify-center px-3 bg-gray-concrete'
    >
      {/** Hardcoded it to AND for the time being, it's not clear yet how the OR operator should behave or if it's even needed, it will introduce more complexity */}
      {translate('all.filters.combinationalOperators.and')}
    </Paragraph>
  );
};
