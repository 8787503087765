export function rewriteUrl() {
  let url = window.location.href;
  const websiteIndex = url.indexOf('/website');
  if (websiteIndex > 0) {
    // Extract websiteKey with first '/' included
    const websiteKey = url.substring(websiteIndex + 8, websiteIndex + 45);
    if (websiteKey.split('/').length === 2) {
      // remove websiteKey from URL
      url = url.replace(websiteKey, '');
    }
  }

  return url;
}
