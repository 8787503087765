import { EVENTS, TEST_IDS } from '@va/constants';
import { useTranslate } from '@va/localization';
import { Button, GrayGradientDivider, ModalHeader, Paragraph } from '@va/ui/design-system';
import { ModalWrapper } from '@va/util/components';
import { EventBus } from '@va/util/misc';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

export const RouterWarningModal = ({ when, title, message }: { when: boolean; title?: string; message?: string }) => {
  const translate = useTranslate();
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState('');

  const [showWarning, setShowWarning] = useState(false);

  const handleCloseModal = useCallback(() => {
    setShowWarning(false);
  }, []);

  const setBlockHistoryEmpty = useCallback(() => history.block(() => {}), [history]);

  const messageText = useMemo(() => message ?? translate('all.warningModal.unsavedChangesText'), [translate, message]);

  useEffect(() => {
    const l = EventBus.addListener(EVENTS.skipUnsavedChangesWarning, (callback: () => void) => {
      setBlockHistoryEmpty();
      if (callback && typeof callback === 'function') {
        callback();
      }
    });

    return () => {
      l.removeListener();
    };
  }, [setBlockHistoryEmpty]);

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowWarning(true);
        return messageText;
      });
    } else {
      setBlockHistoryEmpty();
    }

    return () => {
      setBlockHistoryEmpty();
    };
  }, [history, when, setBlockHistoryEmpty, messageText]);

  const handleOK = useCallback(async () => {
    setBlockHistoryEmpty();
    history.push(currentPath);
    setShowWarning(false);
  }, [currentPath, history, setBlockHistoryEmpty, setShowWarning]);

  return (
    <ModalWrapper closeModal={handleCloseModal} isModalOpen={showWarning}>
      <div className={'max-w-600'}>
        <ModalHeader title={title ?? translate('all.warningModal.title')} onClose={handleCloseModal} />
        <Paragraph className='px-5 py-3'>{messageText}</Paragraph>
        <GrayGradientDivider />

        <div className='flex flex-row justify-end p-3 gap-3'>
          <Button
            className='rounded-12 flex-grow'
            color='tertiary'
            text={translate('button.cancel')}
            onClick={handleCloseModal}
            data-testid={TEST_IDS.generic.buttons.cancel}
          />
          <Button
            className='rounded-12 flex-grow text-red-pure'
            color='tertiary'
            text={translate('button.continue')}
            onClick={handleOK}
            data-testid={TEST_IDS.generic.buttons.continue}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};
