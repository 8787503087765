import { TooltipOptions } from '@va/types/tooltip';
import { fontWeights, Paragraph, ParagraphWithTooltip } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

type TabbedButtonProps = {
  buttonOptions: {
    label?: string;
    icon?: ReactElement;
    value: string | number;
    tooltip?: string | ReactNode;
    className?: string;
    disabled?: boolean;
    tooltipProps?: TooltipOptions;
  }[];
  selectedValue: string | number;
  onChange: Function;
  className?: string;
  textClassName?: string;
  activeButtonClassName?: string;
  inactiveButtonClassName?: string;
};

export const TabbedButton: React.FC<TabbedButtonProps> = ({
  buttonOptions,
  selectedValue,
  onChange,
  className,
  textClassName,
  activeButtonClassName,
  inactiveButtonClassName,
}) => {
  return (
    <div className={classNames('flex h-54 p-[3px] w-full bg-gray-concrete rounded-13.5', className)}>
      {buttonOptions.map(({ label, value, tooltip, className, disabled, tooltipProps, icon }, index) => {
        const isSelected = selectedValue === value;
        return (
          <div
            key={index}
            className={classNames(
              'flex grow justify-center items-center px-4 truncate rounded-13.5',
              className,
              {
                'bg-white rounded-12 cursor-default': isSelected,
                'hover:bg-gray-mercury active:bg-gray-alto cursor-pointer': !isSelected && !disabled,
                'cursor-not-allowed text-gray-silver': disabled,
              },
              isSelected && !disabled && activeButtonClassName,
              !isSelected && inactiveButtonClassName,
            )}
            onClick={() => {
              if (disabled) return;
              onChange(value);
            }}
          >
            {tooltip ? (
              <TooltipWrapper disabled={!isSelected} trigger='click' content={tooltip} {...tooltipProps}>
                <div className='flex gap-2'>
                  {label && (
                    <Paragraph
                      weight={fontWeights.medium}
                      colorClassName={isSelected ? 'text-primary' : undefined}
                      className={classNames(
                        'truncate border-b border-dashed',
                        { 'border-gray-silver': isSelected, 'border-transparent': !isSelected },
                        textClassName,
                      )}
                    >
                      {label}
                    </Paragraph>
                  )}
                  {icon}
                </div>
              </TooltipWrapper>
            ) : (
              <div className='flex gap-2'>
                {label && (
                  <ParagraphWithTooltip
                    weight={fontWeights.medium}
                    colorClassName={isSelected ? 'text-primary' : undefined}
                    className={textClassName}
                  >
                    {label}
                  </ParagraphWithTooltip>
                )}
                {icon}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
