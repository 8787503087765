import { Clock, FilterIcon, LayersIcon, LinkIcon, LoaderIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { fontWeights, Paragraph, paragraphSizes, ParagraphWithTooltip } from '@va/ui/design-system';
import { CloseButton, TooltipWrapper, useTooltipContext } from '@va/ui/tooltips';
import { appHasDarkTheme, getUrlWithoutProtocolAndTrailingSlash, getWellFormedUrl } from '@va/util/helpers';
import { useCheckOverflow, useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import moment from 'moment';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

export type PageItemType = {
  title?: string;
  url: string;
  topRightInfo: string;
  bottomRightInfo: string;
  privacyLevel: number;
};

type VisitedPagesTooltipProps = {
  sessionId?: string;
  isLoading?: boolean;
  title: string;
  items: PageItemType[];
  sessionStart?: number;
  showSubTitle?: boolean;
  showVisitPagesAndDate?: boolean;
  isPageHistoryTrackingEnabled: boolean;
};

export const VisitedPagesTooltip: React.FC<VisitedPagesTooltipProps> = ({
  sessionId,
  title,
  items,
  isLoading,
  sessionStart,
  showSubTitle = true,
  showVisitPagesAndDate = false,
  isPageHistoryTrackingEnabled,
}) => {
  const { height: windowHeight } = useWindowDimensions();
  const isDarkThemeApplied = appHasDarkTheme();
  const translate = useTranslate();
  const { locale } = useLocale();
  const { context } = useTooltipContext();

  if (isLoading)
    return (
      <div className='flex justify-center items-center py-4 lg:py-0'>
        <LoaderIcon color={!isDarkThemeApplied ? 'white' : undefined} />
      </div>
    );
  return (
    <div
      className='rounded-3xl flex flex-col gap-2 overflow-hidden'
      style={{ maxHeight: windowHeight < 750 ? 250 : 400 }}
    >
      <div className='px-30 pt-30'>
        <div className='flex justify-between items-center truncate'>
          {sessionId ? (
            <Link to={`/visitors/history/${sessionId}`}>
              <ParagraphWithTooltip
                className='underline pb-1 text-left truncate'
                size={paragraphSizes.large}
                weight={fontWeights.medium}
              >
                {title}
              </ParagraphWithTooltip>
            </Link>
          ) : (
            <Paragraph
              className={classNames('pb-1 text-left truncate', {
                underline: sessionId,
              })}
              size={paragraphSizes.large}
              weight={fontWeights.medium}
            >
              {title}
            </Paragraph>
          )}
          <CloseButton onClose={() => context.onOpenChange(false)} />
        </div>
        {showSubTitle && (
          <div className='flex items-center gap-3'>
            <FilterIcon color={isDarkThemeApplied ? 'black' : 'white'} />
            <Paragraph weight={fontWeights.medium} className='ml-2'>
              {translate('card.latestVisitors.visitorsHistory.sortedNewestFirst')}
            </Paragraph>
          </div>
        )}
        {showVisitPagesAndDate && (
          <div className='flex items-center gap-3'>
            <div className='flex flex-nowrap items-center gap-1'>
              <LayersIcon color={isDarkThemeApplied ? 'black' : 'white'} />
              <Paragraph weight={fontWeights.medium} className='ml-2'>
                {translate('visitorsHistory.pages', {
                  pageCount: items?.length ?? 0,
                })}
              </Paragraph>
            </div>
            {sessionStart && (
              <div className='flex flex-nowrap items-center gap-1'>
                <Clock color={isDarkThemeApplied ? 'black' : 'white'} />
                <Paragraph weight={fontWeights.medium} className='ml-2'>
                  {moment(sessionStart * 1000)
                    .locale(locale)
                    .format('L')}
                </Paragraph>
              </div>
            )}
          </div>
        )}
      </div>
      <div className='grow scrollbar scrollbar-thumb px-30 mb-30 overflow-y-auto'>
        <div className='flex mb-3 w-full overflow-hidden'>
          <div className='min-w-[120px] md:min-w-[295px]'>
            <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.medium}>
              {translate('card.latestVisitors.visitorsHistory.pages')}
            </ParagraphWithTooltip>
          </div>
          <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.medium} className=''>
            {translate('card.latestVisitors.visitorsHistory.time')}
          </ParagraphWithTooltip>
        </div>
        <ul className='space-y-2'>
          {items.map((item, index) => {
            return <PageListItem isPageHistoryTrackingEnabled={isPageHistoryTrackingEnabled} key={index} item={item} />;
          })}
        </ul>
      </div>
    </div>
  );
};

type PageListItemProps = {
  item: PageItemType;
  isPageHistoryTrackingEnabled: boolean;
};

const PageListItem: React.FC<PageListItemProps> = ({
  item: { url, title, bottomRightInfo, topRightInfo, privacyLevel },
  isPageHistoryTrackingEnabled,
}) => {
  const urlWithoutProtocols = getUrlWithoutProtocolAndTrailingSlash(url);
  const urlRef = useRef(null);
  const isDarkThemeApplied = appHasDarkTheme();
  const isUrlOverflowing = useCheckOverflow(urlRef);
  const translate = useTranslate();

  return (
    <li className='flex items-center gap-10 justify-between'>
      <div className='flex items-center gap-3 truncate'>
        <a
          href={isPageHistoryTrackingEnabled ? url : undefined}
          target='_blank'
          rel='noreferrer'
          className={classNames('h-12 w-12 shrink-0 rounded-full flex items-center justify-center', {
            'bg-white/20': !isDarkThemeApplied,
            'bg-black/20': isDarkThemeApplied,
            'pointer-events-none cursor-not-allowed': !url,
          })}
        >
          <LinkIcon color={isDarkThemeApplied ? 'black' : 'white'} />
        </a>
        <div className='flex flex-col text-left truncate'>
          <ParagraphWithTooltip weight={fontWeights.medium} className='truncate w-full block'>
            {title}
          </ParagraphWithTooltip>
          {isPageHistoryTrackingEnabled ? (
            <TooltipWrapper disabled={!isUrlOverflowing} content={url} ref={urlRef}>
              {privacyLevel === undefined || privacyLevel < 2 ? (
                <a target='_blank' rel='noopener noreferrer' className='truncate max-w-sm' href={getWellFormedUrl(url)}>
                  {urlWithoutProtocols}
                </a>
              ) : (
                <ParagraphWithTooltip className='max-w-sm overflow-hidden truncate'>
                  {translate('panels.recordings.consentless.hiddenPages')}
                </ParagraphWithTooltip>
              )}
            </TooltipWrapper>
          ) : (
            <ParagraphWithTooltip className='max-w-sm overflow-hidden truncate'>
              {translate('panels.recordings.consentless.hiddenPages')}
            </ParagraphWithTooltip>
          )}
        </div>
      </div>
      <div className='shrink-0 text-left'>
        <Paragraph
          colorClassName={isDarkThemeApplied ? 'text-gray-charcoal/83' : 'text-white-83'}
          weight={fontWeights.medium}
        >
          {topRightInfo}
        </Paragraph>
        <Paragraph className='min-w-50' colorClassName={isDarkThemeApplied ? 'text-gray-charcoal/66' : 'text-white-66'}>
          {bottomRightInfo}
        </Paragraph>
      </div>
    </li>
  );
};
