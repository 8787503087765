export function CheckMarkWithFilledBackground(props) {
  const { fillColor, pathColor, ...restProps } = props;
  const fillColorValue = fillColor ? fillColor : '#0B9952';
  const pathColorValue = pathColor ? pathColor : '#FFFFFF';

  return (
    <svg {...restProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12' r='12' fill={fillColorValue} />
      <path d='M7.5 12L10.5 15L16.5 9' stroke={pathColorValue} strokeWidth='2' />
    </svg>
  );
}

export function UnCheckMarkIcon(props) {
  const { color, ...restProps } = props;
  const colorValue = color ? color : '#DDDDDD';

  return (
    <svg {...restProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12' r='11' stroke={colorValue} strokeWidth='2' />
    </svg>
  );
}
