import { ACCOUNT_SETTINGS_NOTIFICATIONS_ID } from '@va/constants';
import { NavChatInbox } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Paragraph, ToggleActionCard, fontWeights } from '@va/ui/design-system';
import { useHashScroll } from '@va/util/hooks';
import { useRef } from 'react';
import DetailsSection from '../common/DetailsSection';
import { useNewsletterPreferencesSa } from './useNewsletterPreferencesSa';

export const NotificationSettings = () => {
  const translate = useTranslate();
  const { onNewsletterChange, isSubscribedByType } = useNewsletterPreferencesSa();
  const elementRef = useRef<HTMLDivElement>(null);

  useHashScroll(elementRef);

  return (
    <DetailsSection
      title={translate('account.notifications.settings')}
      ref={elementRef}
      id={ACCOUNT_SETTINGS_NOTIFICATIONS_ID}
      leftSideNode={
        <Paragraph colorClassName='text-gray-charcoal' weight={fontWeights.medium}>
          {translate('account.notificationSettings.label')}
        </Paragraph>
      }
    >
      <div className='grid mobile:grid-cols-1 grid-cols-2 gap-3 mt-4 md:mt-0'>
        <ToggleActionCard
          heightClass='h-12'
          name='standaloneNewsletter'
          title={translate('notifications.options.newsletter')}
          icon={() => <NavChatInbox color='#696969' />}
          handleChange={() => {
            onNewsletterChange('standaloneNewsletter');
          }}
          value={!!isSubscribedByType('standaloneNewsletter')}
        />
        <ToggleActionCard
          heightClass='h-12'
          name='standalonePromotions'
          title={translate('notifications.options.promotions')}
          icon={() => <NavChatInbox color='#696969' />}
          handleChange={() => {
            onNewsletterChange('standalonePromotions');
          }}
          value={!!isSubscribedByType('standalonePromotions')}
        />
      </div>
    </DetailsSection>
  );
};
