import { isoCountries } from '@va/assets';
import { UNIDENTIFIED_COUNTRY_CODE } from '@va/constants';
import { getPrivacySettingsSucceeded } from '@va/dashboard/actions/api';
import { currentActivePrivacyLevel, getPrivacySettingsData } from '@va/dashboard/selectors/api';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { PrivacyLevelType, PrivacyLevelsEnum } from '@va/standalone/shared/types';
import { OptionStatusEnum, SelectDropdownWithGroupsOptionType } from '@va/ui/design-system';
import { Flag } from '@va/util/components';
import { areArraysEqual } from '@va/util/helpers';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdatePrivacySettings } from './privacy-level-apiClient';

const useCountries = () => {
  const translate = useTranslate();

  return { ...isoCountries, UNIDENTIFIED_COUNTRY_CODE: translate(`countryNames.${UNIDENTIFIED_COUNTRY_CODE}`) };
};

type PrivacyLevelContextType = {
  activePrivacyLevel: PrivacyLevelsEnum;
  handlePrivacyLevelChange: (level: PrivacyLevelsEnum) => void;
  handleValueChange: (level: PrivacyLevelsEnum, value: string) => void;
  privacyLevelCountries: {
    [PrivacyLevelsEnum.defaultPrivacy]: SelectDropdownWithGroupsOptionType[];
    [PrivacyLevelsEnum.basicPrivacy]: SelectDropdownWithGroupsOptionType[];
    [PrivacyLevelsEnum.GDPRMode]: SelectDropdownWithGroupsOptionType[];
    [PrivacyLevelsEnum.completePrivacy]: SelectDropdownWithGroupsOptionType[];
  };
  cancelChanges: () => void;
  submitChanges: () => void;
  updatePrivacyInProgress: boolean;
  hasMadeChanges: boolean;
  handleReset: () => void;
};

const PrivacyLevelContext = React.createContext({} as PrivacyLevelContextType);

export const PrivacyLevelContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const dispatch = useDispatch();
  const { showSuccessNotification, showErrorNotification } = useAddNotification();
  const data = useSelector(getPrivacySettingsData) as PrivacyLevelType[];
  const {
    isLoading: updatePrivacyInProgress,
    execute: updatePrivacyLevel,
    isSucceeded,
    error,
    data: updatedPrivacyResponse,
  } = useUpdatePrivacySettings();
  const countries = useCountries();

  const selectedPrivacyLevel = useSelector(currentActivePrivacyLevel);

  const [activePrivacyLevel, setActivePrivacyLevel] = useState(
    selectedPrivacyLevel ?? PrivacyLevelsEnum.completePrivacy,
  );

  const getLockedCountriesForLevel = useCallback(
    (level: PrivacyLevelsEnum) => {
      return data?.find(({ levelKey }) => level === levelKey)?.exceptions ?? [];
    },
    [data],
  );

  const initialDataOfLockedCountries = useMemo(() => {
    return {
      [PrivacyLevelsEnum.defaultPrivacy]: getLockedCountriesForLevel(PrivacyLevelsEnum.defaultPrivacy),
      [PrivacyLevelsEnum.basicPrivacy]: getLockedCountriesForLevel(PrivacyLevelsEnum.basicPrivacy),
      [PrivacyLevelsEnum.GDPRMode]: getLockedCountriesForLevel(PrivacyLevelsEnum.GDPRMode),
      [PrivacyLevelsEnum.completePrivacy]: getLockedCountriesForLevel(PrivacyLevelsEnum.completePrivacy),
    };
  }, [getLockedCountriesForLevel]);

  const [lockedCountries, setLockedCountries] = useState<{
    [PrivacyLevelsEnum.defaultPrivacy]: string[];
    [PrivacyLevelsEnum.basicPrivacy]: string[];
    [PrivacyLevelsEnum.GDPRMode]: string[];
    [PrivacyLevelsEnum.completePrivacy]: string[];
  }>(initialDataOfLockedCountries);

  const allLockedCountries = useMemo(() => {
    const countries: string[] = [];

    Object.values(lockedCountries).forEach((item) => {
      countries.push(...item);
    });

    return countries;
  }, [lockedCountries]);

  const getCountryStatus = useCallback(
    (privacyLevel: PrivacyLevelsEnum, countryCode: string) => {
      if (lockedCountries?.[privacyLevel]?.includes(countryCode)) {
        return OptionStatusEnum.locked;
      }
      if (allLockedCountries.includes(countryCode) || privacyLevel !== activePrivacyLevel) {
        return OptionStatusEnum.inactive;
      }
      return OptionStatusEnum.active;
    },
    [activePrivacyLevel, lockedCountries, allLockedCountries],
  );

  const getCountries = useCallback(
    (privacyLevel: PrivacyLevelsEnum) => {
      return Object.entries(countries).map(([alpha2code, countryName], index) => {
        return {
          value: alpha2code,
          label: countryName,
          icon: <Flag countryCode={alpha2code} size='1x1' className='w-18 h-18 rounded-full shrink-0' />,
          status: getCountryStatus(privacyLevel, alpha2code),
          className: alpha2code === UNIDENTIFIED_COUNTRY_CODE ? 'sticky -bottom-[2px] z-50 bg-white' : undefined,
        };
      });
    },
    [getCountryStatus, countries],
  );

  const privacyLevelCountries = useMemo(() => {
    return {
      [PrivacyLevelsEnum.defaultPrivacy]: getCountries(PrivacyLevelsEnum.defaultPrivacy),
      [PrivacyLevelsEnum.basicPrivacy]: getCountries(PrivacyLevelsEnum.basicPrivacy),
      [PrivacyLevelsEnum.GDPRMode]: getCountries(PrivacyLevelsEnum.GDPRMode),
      [PrivacyLevelsEnum.completePrivacy]: getCountries(PrivacyLevelsEnum.completePrivacy),
    };
  }, [getCountries]);

  useEffect(() => {
    if (isSucceeded) {
      dispatch(getPrivacySettingsSucceeded({ data: updatedPrivacyResponse?.payload }));
      showSuccessNotification();
    }
  }, [isSucceeded, showSuccessNotification, updatedPrivacyResponse, dispatch]);

  useEffect(() => {
    if (error) {
      showErrorNotification();
    }
  }, [error, showErrorNotification]);

  const handlePrivacyLevelChange = useCallback((newPrivacyLevel: PrivacyLevelsEnum) => {
    setActivePrivacyLevel(newPrivacyLevel);
  }, []);

  const handleValueChange = useCallback(
    (privacyLevel: PrivacyLevelsEnum, value: string) => {
      //check if country is locked on another level (in order to remove that from there)
      const lockedOnPrivacyLevel = Object.keys(lockedCountries).find((item) => {
        return lockedCountries[Number(item) as PrivacyLevelsEnum]?.includes(value);
      });

      //check if country already locked on the clicked level..if it's remove it from the list
      if (Number(lockedOnPrivacyLevel) === privacyLevel) {
        setLockedCountries((prev) => {
          const prevData = prev[privacyLevel];
          return {
            ...prev,
            [privacyLevel]: prevData.filter((item: string) => item !== value),
          };
        });
        return;
      }

      //set country status
      setLockedCountries((prev) => {
        const prevData = prev[privacyLevel];
        const countriesOnThisLevel = prev[Number(lockedOnPrivacyLevel) as PrivacyLevelsEnum];

        return {
          ...prev,
          ...(countriesOnThisLevel && {
            [Number(lockedOnPrivacyLevel)]: countriesOnThisLevel.filter((item: string) => item !== value),
          }),
          [privacyLevel]: [...prevData, value],
        };
      });
    },
    [lockedCountries],
  );

  const cancelChanges = useCallback(() => {
    setActivePrivacyLevel(selectedPrivacyLevel as PrivacyLevelsEnum);
    setLockedCountries(initialDataOfLockedCountries);
  }, [initialDataOfLockedCountries, selectedPrivacyLevel]);

  const submitChanges = useCallback(() => {
    const payloadData = data.map((item) => {
      const privacyLevel = item.levelKey;
      return { ...item, selected: privacyLevel === activePrivacyLevel, exceptions: lockedCountries[privacyLevel] };
    });
    updatePrivacyLevel(payloadData);
  }, [data, activePrivacyLevel, lockedCountries, updatePrivacyLevel]);

  const hasMadeChanges = useMemo(() => {
    if (selectedPrivacyLevel !== activePrivacyLevel) {
      return true;
    }
    const level = data.find((item) => {
      return !areArraysEqual(item.exceptions, lockedCountries[item.levelKey]!);
    });
    return !!level;
  }, [activePrivacyLevel, data, lockedCountries, selectedPrivacyLevel]);

  const handleReset = useCallback(() => {
    setActivePrivacyLevel(PrivacyLevelsEnum.completePrivacy);
    setLockedCountries({
      [PrivacyLevelsEnum.defaultPrivacy]: [],
      [PrivacyLevelsEnum.basicPrivacy]: [],
      [PrivacyLevelsEnum.GDPRMode]: [],
      [PrivacyLevelsEnum.completePrivacy]: [],
    });
  }, []);

  return (
    <PrivacyLevelContext.Provider
      value={{
        activePrivacyLevel,
        handlePrivacyLevelChange,
        handleValueChange,
        privacyLevelCountries: privacyLevelCountries,
        cancelChanges,
        submitChanges,
        updatePrivacyInProgress,
        hasMadeChanges,
        handleReset,
      }}
    >
      {children}
    </PrivacyLevelContext.Provider>
  );
};

const usePrivacyLevelOptionsContext = () => {
  return useContext(PrivacyLevelContext);
};

export default usePrivacyLevelOptionsContext;
