import { setGlobalFilter } from '@va/dashboard/actions/ui';
import { getWebsite } from '@va/dashboard/selectors/core';
import { getGlobalFilter, shouldRefreshDatePicker } from '@va/dashboard/selectors/ui';
import { useRefreshDateFilter } from '@va/dashboard/util-hooks';
import { useLocale, useTranslate } from '@va/localization';
import { DatePicker } from '@va/shared/feature-litepie-datepicker';
import dayjs from 'dayjs';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

function DatePickerContainer({
  setGlobalFilter,
  filter,
  featureReleaseDate,
  installDate,
  lastResetDate,
  shouldRefreshDatePicker,
  placement,
}) {
  const { locale } = useLocale();
  const translate = useTranslate();
  const onPeriodChange = useCallback(
    (date) => {
      const [start, end] = date;

      setGlobalFilter({
        from: moment(new Date(start).getTime()).tz(window.timezone),
        until: moment(new Date(end).getTime()).tz(window.timezone),
      });
    },
    [setGlobalFilter],
  );

  const minimumDate = useMemo(() => {
    if (featureReleaseDate && featureReleaseDate > installDate)
      return dayjs(featureReleaseDate * 1000).tz(window.timezone);

    return dayjs(installDate * 1000).tz(window.timezone);
  }, [featureReleaseDate, installDate]);

  const maximumDate = new dayjs().tz(window.timezone).endOf('day');
  const getDatePickerTooltipTranslationKey = () => {
    const availableDataFrom = lastResetDate ? Math.max(lastResetDate, installDate) : installDate;
    return featureReleaseDate && featureReleaseDate > availableDataFrom
      ? translate('filter.featureReleaseDate', {
          releaseDate: moment(featureReleaseDate * 1000).format('L'),
        })
      : translate('filter.installedToday');
  };

  useRefreshDateFilter();

  return (
    <DatePicker
      tooltipContent={getDatePickerTooltipTranslationKey()}
      onPeriodChange={onPeriodChange}
      startDate={filter.from}
      endDate={filter.until}
      locale={locale}
      minDate={minimumDate}
      maxDate={maximumDate}
      shouldRefreshDatePicker={shouldRefreshDatePicker}
      placement={placement}
    />
  );
}

const mapStateToProps = (state, ownProps) => ({
  filter: getGlobalFilter(state),
  installDate: ownProps.installDate || getWebsite(state).installDate,
  lastResetDate: getWebsite(state).lastResetDate,
  shouldRefreshDatePicker: shouldRefreshDatePicker(state),
});

const mapDispatchToProps = {
  setGlobalFilter: setGlobalFilter,
};

DatePickerContainer.propTypes = {
  featureReleaseDate: PropTypes.number,
  featureReleaseTransKey: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  //connect
  setGlobalFilter: PropTypes.func.isRequired,
  activePeriod: PropTypes.string,
  filter: PropTypes.shape({
    from: PropTypes.object.isRequired,
    until: PropTypes.object.isRequired,
  }),
  installDate: PropTypes.number.isRequired,
  lastResetDate: PropTypes.number,
  locale: PropTypes.string,
  isMobileSm: PropTypes.bool,
  shouldRefreshDatePicker: PropTypes.bool,
  placement: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerContainer);

/* TODO will be shifted from here */
// getDatePickerTooltipTranslationKey() {
//   const { featureReleaseDate, featureReleaseTransKey, lastResetDate, installDate } = this.props;
//   if (featureReleaseDate) {
//     const availableDataFrom = lastResetDate ? Math.max(lastResetDate, installDate) : installDate;
//     if (featureReleaseDate > availableDataFrom) {
//       return featureReleaseTransKey || 'filter.featureReleaseDate';
//     } else {
//       return 'filter.installedToday';
//     }
//   }
//   return 'filter.installedToday';
// }
