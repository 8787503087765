import { getInstanceId } from '@va/dashboard/selectors/app';
import { BrowserIcon, DeviceIcon, OperatingSystemIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { DeviceTypesEnum } from '@va/types/device';
import { Paragraph, ParagraphWithTooltip } from '@va/ui/design-system';
import { Flag } from '@va/util/components';
import { getFormattedDateAndTime } from '@va/util/helpers';
import { useFetchData } from '@va/util/hooks';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const SessionInfoTooltip = ({ sessionKey }: { sessionKey: string }) => {
  const { locale } = useLocale();
  const { data, isLoading, error } = useFetchSessionInfo(sessionKey);
  const translate = useTranslate();

  if (isLoading) {
    return <Skeleton className='min-w-[200px] h-[151px]' />;
  }

  if (error || !data) {
    return translate('notifications.generalError');
  }

  return (
    <div className='p-2 flex flex-col gap-2'>
      <ParagraphWithTooltip tooltipProps={{ interactive: true }} className='!my-0 !p-0'>
        IP: {data.ip}
      </ParagraphWithTooltip>
      <div className='flex gap-4'>
        <Flag className='!w-7 !h-7 shrink-0' countryCode={data.countryCode} />
        <DeviceIcon className='w-7 h-7 shrink-0' deviceType={data.deviceType} />
        <OperatingSystemIcon className='w-7 h-7 shrink-0' osName={data.platform} />
        <BrowserIcon className='w-7 h-7 shrink-0' browserName={data.browserName} />
      </div>
      <Link className='text-15 py-2 block' to={`/visitors/history/${sessionKey}`}>{`${
        data.pagesVisited || 0
      } ${translate('panels.map.tooltip.pageVisited')}`}</Link>
      <Paragraph className='!my-0 !p-0'>{getFormattedDateAndTime(data.timestamp, locale)}</Paragraph>
    </div>
  );
};

type SessionData = {
  browserName: string;
  countryCode: string;
  deviceType: DeviceTypesEnum;
  pagesVisited: number;
  platform: string;
  timestamp: number;
  ip: string;
};

const useFetchSessionInfo = (sessionId: string) => {
  const websiteId = useSelector(getInstanceId);
  return useFetchData<SessionData>(`/websites/${websiteId}/sessions/${sessionId}/info`);
};
