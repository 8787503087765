import { EXPORT_FORMAT } from '@va/constants';
import {
  EXPORT_VISITS_CANCEL,
  EXPORT_VISITS_FAILED,
  EXPORT_VISITS_REQUEST,
  EXPORT_VISITS_SUCCEEDED,
  exportVisitsCancel,
  exportVisitsFailed,
  exportVisitsPageFailed,
  exportVisitsPageSucceeded,
  exportVisitsSucceeded,
} from '@va/dashboard/actions/api';
import Api from '@va/dashboard/api-client/index';
import { isTrackingEnabled } from '@va/dashboard/selectors/api';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getUrlViewFlag, getWebsiteTimezone, getWebsiteUrl } from '@va/dashboard/selectors/core';
import { getLocale } from '@va/dashboard/selectors/ui';
import { getHeaderForExportVisits, processExportVisitsData, processExportVisitsDataXLS } from '@va/http-client';
import { getMaxExportLengthPerPage, getUrlWithoutProtocolAndTrailingSlash } from '@va/util/helpers';
import moment from 'moment';
import { all, call, cancel, cancelled, fork, put, select, take } from 'redux-saga/effects';
import { downloadExportResponse } from './core/Util';

export function* watchers() {
  yield all([watchExportVisits()]);
}

export function* takeExportVisitsActions() {
  while (true) {
    let action = yield take(EXPORT_VISITS_REQUEST);
    const exportTask = yield fork(tryToExportVisits, action);

    action = yield take([EXPORT_VISITS_CANCEL, EXPORT_VISITS_FAILED, EXPORT_VISITS_SUCCEEDED]);
    if (action.type === EXPORT_VISITS_CANCEL) {
      yield cancel(exportTask);
    }
  }
}

export function* watchExportVisits() {
  yield fork(takeExportVisitsActions);
}

export function* tryToExportVisits(action) {
  try {
    yield call(exportVisits, action);
  } finally {
    if (yield cancelled()) {
      yield put(exportVisitsCancel());
    }
  }
}

export function* exportVisits(action) {
  const locale = yield select(getLocale);
  const translate = action.translate;
  const timezone = yield select(getWebsiteTimezone);
  const websiteId = yield select(getInstanceId);
  const websiteUrl = yield select(getWebsiteUrl);
  const urlviewFlag = yield select(getUrlViewFlag);
  const isTrackingFeature = yield select(isTrackingEnabled);
  let data;
  let spreadsheetData = getHeaderForExportVisits(translate, action.format);

  const newFilter = Object.assign({}, action.filter, { start: 0, length: 0 });
  data = yield call(Api.getLatestVisitsList, websiteId, newFilter);
  const length = getMaxExportLengthPerPage(data.visitsTotal);

  const requiredRequests = data.visitsTotal / length;
  for (let i = 0; i < requiredRequests; i++) {
    data = yield call(getExportVisitsPage, action.filter, length, i);
    if (data === null) {
      return;
    }

    if (action.format === EXPORT_FORMAT.CSV) {
      spreadsheetData += processExportVisitsData(
        data.data,
        translate,
        timezone,
        urlviewFlag,
        locale,
        isTrackingFeature,
      );
    } else {
      const processedData = processExportVisitsDataXLS(
        data.data,
        translate,
        timezone,
        urlviewFlag,
        locale,
        isTrackingFeature,
      );
      for (let i = 0; i < processedData.length; i++) {
        spreadsheetData.push(processedData[i]);
      }
    }
  }

  let { from, until } = action.filter;

  from = moment(from).tz(window.timezone).format('YYYY-MM-DD');
  until = moment(until).tz(window.timezone).format('YYYY-MM-DD');

  const fileName = `${getUrlWithoutProtocolAndTrailingSlash(websiteUrl)}_${from}_${until}_visits`;
  const response = yield call(downloadExportResponse, spreadsheetData, fileName, action.format);
  yield put(exportVisitsSucceeded(response));
}

export function* getExportVisitsPage(filter, length, page) {
  try {
    const newFilter = Object.assign({}, filter, {
      start: page * length,
      length: length,
    });
    const websiteId = yield select(getInstanceId);
    const data = yield call(Api.getLatestVisitsList, websiteId, newFilter);

    yield put(
      exportVisitsPageSucceeded({
        visits: data.data,
        visitsTotal: data.visitsTotal,
      }),
    );

    return data;
  } catch (error) {
    yield put(exportVisitsPageFailed(error));
    yield put(exportVisitsFailed(error));
    return null;
  }
}
