import { getInstanceId } from '@va/dashboard/selectors/app';
import { put } from '@va/http-client';
import { PrivacyLevelType } from '@va/standalone/shared/types';
import { useAsyncFunction } from '@va/util/hooks';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

type PrivacyLevelResponseType = {
  payload: PrivacyLevelType[];
};

export const useUpdatePrivacySettings = () => {
  const websiteId = useSelector(getInstanceId);

  const asyncFunc = useCallback(
    async (data: PrivacyLevelType[]) => {
      return await put(`/websites/${websiteId}/pc-api/v1/websites/${websiteId}/settings`, {}, data);
    },
    [websiteId],
  );

  return useAsyncFunction<typeof asyncFunc, PrivacyLevelResponseType>(asyncFunc);
};
