import { RadioInput, RadioInputSelected } from '@va/icons';
import classNames from 'classnames';

type RadioInputProps = {
  selected: boolean;
  className?: string;
};

export const RadioInputButton = ({ selected, className }: RadioInputProps) => {
  return (
    <>
      {selected ? (
        <RadioInputSelected className={classNames('h-3 w-3', className)} />
      ) : (
        <RadioInput className={classNames(className)} />
      )}
    </>
  );
};
