import { FEATURE_TOUR_IDS, pathName, supportLinks } from '@va/constants';
import { getAccountInformation } from '@va/dashboard/selectors/api';
import { getWebsite } from '@va/dashboard/selectors/core';
import { CogIcon, DropdownIcon, GlobeIcon, UserIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { logout } from '@va/standalone/shared/actions';
import { HorizontalDivider, Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import { getFullNameFromUserInfo, getInitialsFromUserInfo, isStandaloneApp } from '@va/util/helpers';
import { Link } from '@va/util/router';
import classNames from 'classnames';
import React, { MouseEventHandler, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import LanguageSelector from './LanguageSelector';

const UserMenuContent: React.FC<{
  userInformation: { firstName?: string; lastName?: string };
  onCloseCallback: () => void;
}> = ({ userInformation, onCloseCallback }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const [isLanguageSelectorVisible, setIsLanguageSelectorVisible] = useState(false);
  const userInitials = useMemo(() => getInitialsFromUserInfo(userInformation), [userInformation]);
  const history = useHistory();

  const isStandalone = isStandaloneApp();
  const fullName = useMemo(() => getFullNameFromUserInfo(userInformation), [userInformation]);

  return (
    <div className='min-w-[400px] rounded-24 bg-white border-2 border-gray-mercury text-black px-3 shadow-md py-3'>
      {!isLanguageSelectorVisible && (
        <>
          {(isStandalone || fullName) && (
            <>
              <div
                className={classNames(
                  'group flex flex-row rounded-18 items-center px-3 pb-3 my-3 bg-white flex-nowrap',
                  {
                    'hover:bg-white-snow cursor-pointer': isStandalone,
                  },
                )}
              >
                <div className='rounded-full bg-primary w-[36px] h-[36px] flex justify-center items-center text-white shrink-0 font-primary'>
                  {userInitials.toUpperCase()}
                </div>
                <div className='pl-18px overflow-hidden'>
                  <Paragraph
                    size={paragraphSizes.large}
                    colorClassName='text-gray-charcoal'
                    weight={fontWeights.medium}
                    className='truncate'
                  >
                    {getFullNameFromUserInfo(userInformation)}
                  </Paragraph>
                  {isStandalone && (
                    <Link to='/my-account/info' onClick={() => onCloseCallback()}>
                      <Paragraph colorClassName='text-gray-devil group-hover:text-primary' weight={fontWeights.medium}>
                        {translate('standalone.accountSettings.myAccount.title')}
                      </Paragraph>
                    </Link>
                  )}
                </div>
              </div>
              <HorizontalDivider />
            </>
          )}
          <UserMenuButton
            leftIcon={<GlobeIcon color='var(--color-black)' />}
            text={translate('locale.language')}
            rightIcon={<DropdownIcon />}
            onClick={() => {
              setIsLanguageSelectorVisible(true);
            }}
          />
          <HorizontalDivider />

          {isStandalone && (
            <UserMenuButton
              leftIcon={<CogIcon />}
              text={translate('menu.myAccount')}
              onClick={() => {
                history.push(pathName.accountSettings);
                onCloseCallback();
              }}
            />
          )}
          <a href={supportLinks.home} target='_blank' rel='noreferrer' onClick={() => onCloseCallback()}>
            <UserMenuButton leftIcon={<HelpIcon />} text={translate('button.helpCenter')} />
          </a>
          {isStandalone && (
            <UserMenuButton
              leftIcon={<LogoutIcon />}
              text={translate('button.logout')}
              onClick={() => {
                dispatch(logout());
              }}
            />
          )}
        </>
      )}

      {isLanguageSelectorVisible && (
        <LanguageSelector
          onBackClick={() => {
            setIsLanguageSelectorVisible(false);
          }}
          onLanguageSelected={() => {
            setIsLanguageSelectorVisible(false);
            onCloseCallback();
          }}
        />
      )}
    </div>
  );
};

export const UserMenu: React.FC<{ closeSidebar?: () => void }> = ({ closeSidebar }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const websiteInfo = useSelector(getWebsite);
  const accountInfo = useSelector(getAccountInformation);

  const userInfo = useMemo(() => (isStandaloneApp() ? accountInfo : websiteInfo), [accountInfo, websiteInfo]);

  const userInitials = useMemo(() => getInitialsFromUserInfo(userInfo), [userInfo]);

  return (
    <TooltipWrapper
      open={isExpanded}
      onOpenChange={(open) => setIsExpanded(open)}
      trigger='click'
      zIndex={999}
      content={
        <UserMenuContent
          userInformation={userInfo}
          onCloseCallback={() => {
            setIsExpanded(false);
            closeSidebar && closeSidebar();
          }}
        />
      }
      arrow={false}
      useDefaultStyle={false}
    >
      <div
        data-tour={FEATURE_TOUR_IDS.userMenu}
        className={classNames(
          'mr-2 sm-initial:mr-0 sm-initial:h-[60px] rounded-[15px] bg-primary-lighter-06 hover:bg-primary-lighter-13 sm-initial:p-3 flex justify-between items-center cursor-pointer',
          { isOpen: isExpanded },
        )}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <div className='rounded-full bg-primary w-[36px] h-[36px] flex justify-center items-center text-white '>
          {userInitials ? userInitials.toUpperCase() : <UserIcon color='white' />}
        </div>
        <DropdownArrow open={isExpanded} className='hidden sm-initial:block sm-initial:ml-2' />
      </div>
    </TooltipWrapper>
  );
};

// TODO Razvan replace with button from filter templates?
export const UserMenuButton: React.FC<{
  leftIcon: React.ReactNode;
  text: React.ReactNode;
  onClick?: MouseEventHandler;
  rightIcon?: React.ReactNode;
}> = ({ leftIcon, text, onClick, rightIcon }) => {
  return (
    <button
      className='w-full flex flex-row items-center justify-between h-12 px-3 bg-white hover:bg-white-snow rounded-12'
      onClick={onClick}
    >
      <div className='flex flex-row items-center'>
        <div className='h-6 w-6 text-center flex items-center'>{leftIcon}</div>
        {typeof text === 'string' ? (
          <Paragraph className='pl-3' weight={fontWeights.medium}>
            {text}
          </Paragraph>
        ) : (
          text
        )}
      </div>
      {rightIcon}
    </button>
  );
};

const HelpIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 9C16.5 10.801 15.8652 12.4536 14.8072 13.7465L12.6681 11.6074C13.1919 10.8718 13.5 9.97187 13.5 9C13.5 8.02813 13.1919 7.12821 12.6681 6.3926L14.8072 4.25345C15.8652 5.54635 16.5 7.19905 16.5 9ZM9 16.5C10.801 16.5 12.4536 15.8652 13.7465 14.8072L11.6074 12.6681C10.8718 13.1919 9.97187 13.5 9 13.5C8.02813 13.5 7.12821 13.1919 6.3926 12.6681L4.25345 14.8072C5.54635 15.8652 7.19905 16.5 9 16.5ZM3.19279 13.7465C2.13477 12.4536 1.5 10.801 1.5 9C1.5 7.19905 2.13477 5.54635 3.19279 4.25345L5.33194 6.3926C4.80809 7.12821 4.5 8.02813 4.5 9C4.5 9.97187 4.80809 10.8718 5.33194 11.6074L3.19279 13.7465ZM6.3926 5.33194C7.12821 4.80809 8.02813 4.5 9 4.5C9.97187 4.5 10.8718 4.80809 11.6074 5.33194L13.7465 3.19279C12.4536 2.13477 10.801 1.5 9 1.5C7.19905 1.5 5.54635 2.13477 4.25345 3.19279L6.3926 5.33194ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9Z'
        fill='black'
      />
    </svg>
  );
};

const LogoutIcon = () => {
  return (
    <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.9828 2.41021L2.99563 3.73926C2.38312 3.94344 1.96997 4.51664 1.96997 5.16229V12.8377C1.96997 13.4834 2.38312 14.0566 2.99563 14.2608L6.9828 15.5898C7.46845 15.7517 7.96997 15.3902 7.96997 14.8783V3.12172C7.96997 2.6098 7.46845 2.24833 6.9828 2.41021ZM2.52129 2.31624C1.29626 2.72458 0.469971 3.871 0.469971 5.16229V12.8377C0.469971 14.129 1.29626 15.2754 2.52129 15.6838L6.50846 17.0128C7.92661 17.4856 9.38987 16.4707 9.4668 15H10.22C11.4626 15 12.47 13.9927 12.47 12.75V12H10.97V12.75C10.97 13.1642 10.6342 13.5 10.22 13.5H9.46997V4.50001H10.22C10.6342 4.50001 10.97 4.8358 10.97 5.25001V6.00001H12.47V5.25001C12.47 4.00737 11.4626 3.00001 10.22 3.00001H9.4668C9.38987 1.52932 7.92661 0.514467 6.50846 0.987183L2.52129 2.31624ZM19.0003 8.46968L16.0003 5.46968L14.9396 6.53034L16.6593 8.25001H10.97V9.75001H16.6593L14.9396 11.4697L16.0003 12.5303L19.0003 9.53034L19.5306 9.00001L19.0003 8.46968ZM5.71997 9.75001C6.13418 9.75001 6.46997 9.41423 6.46997 9.00001C6.46997 8.5858 6.13418 8.25001 5.71997 8.25001C5.30576 8.25001 4.96997 8.5858 4.96997 9.00001C4.96997 9.41423 5.30576 9.75001 5.71997 9.75001Z'
        fill='black'
      />
    </svg>
  );
};
