import { CommonProps } from '@va/types/component';
import { TooltipOptions } from '@va/types/tooltip';
import { TooltipWrapper } from '@va/ui/tooltips';
import { useCheckOverflow } from '@va/util/hooks';
import classNames from 'classnames';
import { isNil, isString } from 'lodash';
import { MouseEventHandler, ReactNode, forwardRef, useMemo, useRef } from 'react';
import './button.scss';

const BASE_CLASSES = `visa-base-button gap-0.5 group flex items-center divide-transparent divide-x-9 py-12px text-15 leading-24 font-medium whitespace-nowrap overflow-hidden`;
const BASE_ACTIVE_CLASSES = 'active:focus:ring-inset active:focus:ring-2';
const PRIMARY_CLASSES = `bg-primary text-white active:focus:ring-white-40`;
export const SECONDARY_CLASSES = `bg-primary-lighter-06 text-primary hover:bg-primary-lighter-13 active:bg-primary-lighter-20 active:focus:ring-primary group-hover:bg-white`;
const TERTIARY_CLASSES = `bg-white-snow text-gray-charcoal hover:bg-black-lighter-06 focus:bg-black-lighter-13 focus:text-primary active:bg-white-snow active:focus:ring-primary`;
const QUATERNARY_CLASSES = `bg-white text-primary hover:bg-black-lighter-06 focus:bg-black-lighter-13 focus:text-primary active:bg-white-snow active:focus:ring-primary`;
const QUINARY_CLASSES = `bg-white text-gray-dark-charchoal hover:bg-black-lighter-06 focus:bg-black-lighter-13 active:bg-white-snow active:focus:ring-primary`;
const DESTRUCTIVE_CLASSES = `bg-primary-lighter-06 text-red-pure hover:bg-primary-lighter-13 active:bg-primary-lighter-20 active:focus:ring-primary`;
const ICON_AND_TEXT_CLASSES = `pl-12px pr-15px justify-start`;
const TEXT_ONLY_CLASSES = `px-18px justify-center`;
const ICON_ONLY_CLASSES = `w-12 h-12 px-12px justify-center shrink-0`;
export const DISABLED_CLASSES = 'bg-primary-lighter-06 text-primary-lighter-60 opacity-80 cursor-not-allowed';
const SVG_CLASSES_PRIMARY = `fill-white`;
const SVG_CLASSES_SECONDARY = `fill-primary`;
const SVG_CLASSES_TERTIARY = `fill-gray-charcoal group-focus:fill-primary`;
const SVG_CLASSES_QUINARY = `fill-gray-charcoal`;
const SVG_CLASSES_DESTRUCTIVE = `fill-red-pure`;
const SVG_DISABLED_CLASSES = `fill-primary-lighter-20`;

const colorClasses = {
  primary: `${PRIMARY_CLASSES}`,
  secondary: `${SECONDARY_CLASSES}`,
  tertiary: `${TERTIARY_CLASSES}`,
  quaternary: `${QUATERNARY_CLASSES}`,
  destructive: `${DESTRUCTIVE_CLASSES}`,
  quinary: `${QUINARY_CLASSES}`,
};

const svgClasses = {
  primary: `${SVG_CLASSES_PRIMARY}`,
  secondary: `${SVG_CLASSES_SECONDARY}`,
  tertiary: `${SVG_CLASSES_TERTIARY}`,
  quaternary: `${SVG_CLASSES_TERTIARY}`,
  quinary: `${SVG_CLASSES_QUINARY}`,
  destructive: `${SVG_CLASSES_DESTRUCTIVE}`,
};

export type ButtonColors = 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'quinary' | 'destructive';
export enum ButtonShapes {
  'rectangle' = 'rectangle',
  'circle' = 'circle',
}

export type ButtonProps = CommonProps & {
  icon?: (iconClasses: string, color?: string) => ReactNode;
  rightSideIcon?: ReactNode;
  rightSideIconWrapperCss?: string;
  text?: string | number;
  disabled?: boolean;
  color?: ButtonColors;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  tooltip?: string | ReactNode;
  type?: 'button' | 'submit';
  shape?: ButtonShapes;
  textClasses?: string;
  tooltipProps?: TooltipOptions;
  dataTourId?: string;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      icon,
      rightSideIcon,
      rightSideIconWrapperCss,
      text,
      disabled = false,
      color = 'primary',
      onClick,
      tooltip,
      type = 'button',
      className,
      shape = ButtonShapes.rectangle,
      tooltipProps,
      textClasses,
      dataTourId,
      ...props
    },
    ref,
  ) => {
    const getButtonColorClasses = (color: ButtonColors) => colorClasses[color];
    const titleRef = useRef(null);
    const isOverflow = useCheckOverflow(titleRef);
    const { disabled: isTooltipDisabled } = tooltipProps ?? {};

    const getIconClasses = (color: ButtonColors, disabled: boolean) => {
      if (disabled) return `${SVG_DISABLED_CLASSES}`;
      return svgClasses[color];
    };

    const iconColor = useMemo(() => {
      if (color === 'destructive') return '#ED290E';
      if (disabled) return 'var(--color-primary-lighter-20)';
      switch (color) {
        case 'primary':
          return 'white';
        case 'secondary':
          return 'var(--color-primary)';
        case 'tertiary':
          return 'var(--color-gray-charcoal)';
        default:
          return undefined;
      }
    }, [color, disabled]);

    const computedClasses = useMemo(() => {
      const iconClasses = classNames(getIconClasses(color, disabled), 'shrink-0');
      const buttonClasses = classNames(
        BASE_CLASSES,
        {
          [ICON_AND_TEXT_CLASSES]: icon && !isNil(text),
          [TEXT_ONLY_CLASSES]: !icon && !isNil(text),
          [ICON_ONLY_CLASSES]: icon && isNil(text),
          [getButtonColorClasses(color)]: !disabled,
          [BASE_ACTIVE_CLASSES]: !disabled,
          [DISABLED_CLASSES]: disabled,
          'rounded-12': shape === ButtonShapes.rectangle,
          'rounded-full': shape === ButtonShapes.circle,
        },
        className,
      );

      return { buttonClasses, iconClasses };
    }, [color, disabled, icon, text, shape, className]);

    return (
      <TooltipWrapper
        interactive={!isString(tooltip)}
        content={tooltip ?? text}
        disabled={isTooltipDisabled || (!tooltip && !isOverflow)}
        {...tooltipProps}
      >
        <button
          data-tour={dataTourId}
          ref={ref}
          className={computedClasses.buttonClasses}
          onClick={(e) => {
            if (disabled) return;
            if (!onClick) return;
            onClick(e);
          }}
          type={type}
          {...props}
          // A disabled button does not trigger onMouseEnter/onMouseLeave events
          disabled={false}
        >
          {icon && icon(computedClasses.iconClasses, iconColor)}
          {text && (
            <p ref={titleRef} className={classNames('truncate', textClasses)}>
              {text}
            </p>
          )}
          {rightSideIcon && (
            <div className={classNames('ml-auto shrink-0', rightSideIconWrapperCss)}>{rightSideIcon}</div>
          )}
        </button>
      </TooltipWrapper>
    );
  },
);
