import React from 'react';
import PropTypes from 'prop-types';

export function CloseIconWithBorder(props) {
  const { color, radius = 17, ...restProps} = props;
  const colorValue = color ? color : '#CCCCCC';
  return (
    <svg {...restProps} width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='18' cy='18' r={radius} stroke={colorValue} strokeWidth='2' />
      <path d='M14 14L22 22' stroke={colorValue} strokeWidth='2' />
      <path d='M22 14L14 22' stroke={colorValue} strokeWidth='2' />
    </svg>
  );
}

CloseIconWithBorder.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  radius: PropTypes.number,
};

export function BackIconWithBorder(props) {
  const {color, ...restProps} = props;
  const colorValue = color ? color : '#CCCCCC';
  return <svg {...restProps} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="17" stroke={colorValue} strokeWidth="2"/>
    <path d="M20 14L16 18L20 22" stroke={colorValue} strokeWidth="2"/>
  </svg>;
}

BackIconWithBorder.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
}

export function StatusIcon(props) {
  const {color, isActive, outline, ...restProps} = props;
  const colorValue = color || (isActive ? '#06A25A' : '#EA2A0C');
  return <svg {...restProps} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="3" fill="#F0FBF6"/>
    <circle cx="8" cy="8" r="6.75" fill="white" stroke="white" stroke-width="1.5"/>
    <circle cx="8" cy="8" r="5.25" stroke={outline || colorValue} strokeWidth="1.5"/>
    <circle cx="8" cy="8" r="3" fill={colorValue}/>
  </svg>;
}

StatusIcon.propTypes = {
  color: PropTypes.string,
  isActive: PropTypes.bool,
  className: PropTypes.string,
}
