import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { nonTranslatableText } from '@va/constants';
import { onContactSupport } from '@va/dashboard/util-helpers/misc';
import PopUpCard from '@va/deprecated/components/PopUpCard';
import { FeedbackFail, FeedbackSuccess } from '@va/svg-visa-icons';
import MessageAnimated from 'standalone/assets/icons/MessageAnimated/index';

export default class MessageCard extends PureComponent {
  renderRequestEmailSucceeded() {
    const { translate, title } = this.props;

    return (
      <PopUpCard
        type='card-grey'
        title={title || translate('modal.emailRequest.title')}
        footerButtons={<div onClick={this.props.onClose}>{translate('button.close')}</div>}
      >
        <React.Fragment>
          <div className='email-icon-container'>
            <MessageAnimated className='email-icon' />
          </div>
          <p>
            {translate(`modal.${this.props.modalName}.emailRequest.succeeded`, {
              visitorAnalytics: nonTranslatableText.appName,
            })}
          </p>
        </React.Fragment>
      </PopUpCard>
    );
  }

  renderRequestEmailError() {
    const { translate, title } = this.props;

    return (
      <PopUpCard
        type='card-grey'
        colorTheme='theme-red'
        title={title || translate('modal.emailRequest.title')}
        footerButtons={<div onClick={this.props.onClose}>{translate('button.close')}</div>}
      >
        <p className='mt-4 mb-8'>{translate('form.generalError')}</p>
      </PopUpCard>
    );
  }

  renderConfirmationSucceeded() {
    const { translate, title } = this.props;

    return (
      <PopUpCard
        type='card-grey'
        title={title || translate('modal.confirmation.succeeded.title')}
        footerButtons={<div onClick={this.props.onClose}>{translate('button.close')}</div>}
      >
        <div className='text-center'>
          <div className='feedback-icon-container'>
            <FeedbackSuccess />
          </div>
          <p className='mt-4 mb-8'>{translate(`modal.${this.props.modalName}.confirmation.succeeded`)}</p>
          {this.props.text2 && <p>{this.props.text2}</p>}
        </div>
      </PopUpCard>
    );
  }

  renderConfirmationFailed() {
    const { translate, title } = this.props;

    return (
      <PopUpCard
        type='card-grey'
        colorTheme='theme-red'
        title={title || translate('modal.confirmation.failed.title')}
        footerButtons={<div onClick={this.props.onClose}>{translate('button.close')}</div>}
      >
        <div className='text-center'>
          <div className='feedback-icon-container'>
            <FeedbackFail />
          </div>
          <p onClick={onContactSupport}>{translate(`modal.${this.props.modalName}.confirmation.failed`)}</p>
          {this.props.text2 && <p>{this.props.text2}</p>}
        </div>
      </PopUpCard>
    );
  }

  showRequestEmailView() {
    return this.props.isRequestEmailError ? this.renderRequestEmailError() : this.renderRequestEmailSucceeded();
  }

  showConfirmationView() {
    return this.props.isConfirmEmailError ? this.renderConfirmationFailed() : this.renderConfirmationSucceeded();
  }

  render() {
    return this.props.confirmationView ? this.showConfirmationView() : this.showRequestEmailView();
  }
}

MessageCard.propTypes = {
  modalName: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  text2: PropTypes.any,
  confirmationView: PropTypes.bool,
  isRequestEmailError: PropTypes.bool,
  isConfirmEmailError: PropTypes.bool,
  title: PropTypes.string,
  providerName: PropTypes.string.isRequired,
};
