import { TEST_IDS } from '@va/constants';
import { FilterIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button } from '@va/ui/design-system';
import { DropdownArrow } from '@va/util/components';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { AddFilterTooltip } from './add-filter-tooltip/AddFilterTooltip';
import { FILTER_SELECTION_MODAL_CLASS_IDENTIFIER } from './constants';
import { useFiltersContext } from './filters-context';

export const AddFilterButton = ({ className }: { className?: string }) => {
  const translate = useTranslate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { appliedFilters, isExpanded, toggleAppliedFiltersVisibility } = useFiltersContext();

  const filtersExist = appliedFilters.length > 0;
  const filterColor = filtersExist ? '#FFFFFF' : '#3C3C3C';

  const handleButtonClick = useCallback(() => {
    if (filtersExist) {
      toggleAppliedFiltersVisibility();
      return;
    }
    setIsDropdownOpen((prev) => !prev);
  }, [filtersExist, toggleAppliedFiltersVisibility]);

  return (
    <AddFilterTooltip isOpen={isDropdownOpen} openChange={setIsDropdownOpen}>
      <div className={classNames(className, 'inline-block')}>
        <Button
          color={filtersExist ? 'primary' : 'tertiary'}
          icon={() => <FilterIcon color={filterColor} />}
          rightSideIcon={<DropdownArrow color={filterColor} open={isDropdownOpen || (filtersExist && isExpanded)} />}
          text={''}
          data-testid={TEST_IDS.generic.filters.toggleBtn}
          onClick={handleButtonClick}
          className={classNames('mr-2 h-12', {
            '!bg-green-pure': filtersExist && !isExpanded,
          })}
          tooltip={translate('tooltip.label.applyFilters')}
        />
      </div>
    </AddFilterTooltip>
  );
};

export function isClickInsideFilterModal(e: Event) {
  const filterSelectionModal = document.querySelector(`.${FILTER_SELECTION_MODAL_CLASS_IDENTIFIER}`);
  if (filterSelectionModal && filterSelectionModal.contains(e.target as any)) return true;
  return false;
}
