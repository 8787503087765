import { TEST_IDS } from '@va/constants';
import { CloseIconWithCircle } from '@va/icons';
import { Button, fontWeights, GrayGradientDivider, Heading5 } from '@va/ui/design-system';
import classNames from 'classnames';
import { ReactNode } from 'react';

const ModalHeader = ({
  title,
  onClose,
  className,
}: {
  title: string | ReactNode;
  onClose: () => void;
  className?: string;
}) => {
  return (
    <>
      <div className={classNames('flex justify-between items-center py-15px px-18px', className)}>
        <Heading5 weight={fontWeights.semibold} colorClassName={'text-gray-charcoal'}>
          {title}
        </Heading5>
        <Button
          data-testid={TEST_IDS.generic.buttons.closeModal}
          color='quinary'
          icon={() => <CloseIconWithCircle />}
          onClick={onClose}
        />
      </div>
      <GrayGradientDivider />
    </>
  );
};

export { ModalHeader };
