import classNames from 'classnames';
import { IconProps } from '.';

export const StarIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 0.25L7.90442 3.62878L11.7063 4.3959L9.08142 7.25121L9.52671 11.1041L6 9.49L2.47329 11.1041L2.91858 7.25121L0.293661 4.3959L4.09558 3.62878L6 0.25Z'
        fill={color}
      />
    </svg>
  );
};
