import { CloseIconWithCircle, FilledCheckIcon } from '@va/icons';
import { Heading3, Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { setResizeObserver } from '@va/util/helpers';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Redirect } from 'react-router';
import './multi-step-wizard.scss';

const lineClasses = 'line w-full h-1 relative top-1/2 border-b-2 border-dashed';

export type MultiStepWizardProps = {
  steps: Array<{
    label: React.ReactNode;
    component: ReactNode;
    hasError?: boolean;
    isFinalized?: boolean;
    subInfo?: string;
    smallLabel?: boolean;
  }>;
  activeStep: number;
  onStepChange?: (step: number) => void;
};

export const MultiStepWizard: FC<MultiStepWizardProps> = ({ steps, activeStep, onStepChange }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isMobile } = useWindowDimensions();
  const containerRef = useRef<HTMLDivElement>(null);
  const [itemWidth, setItemWidth] = useState(0);

  const multiplicationFactor = useMemo(() => {
    if (isMobile) return 0.75;
    return 0.5;
  }, [isMobile]);

  const onResizeCallback = useCallback(() => {
    if (!ref.current) return;
    if (isMobile) {
      setItemWidth(ref.current.clientWidth * multiplicationFactor);
      return;
    }
    setItemWidth(ref.current.clientWidth * multiplicationFactor);
  }, [isMobile, multiplicationFactor]);

  const translateBy = useMemo(
    () => itemWidth * (1 - multiplicationFactor) - itemWidth * activeStep,
    [activeStep, itemWidth, multiplicationFactor],
  );

  useEffect(() => {
    setResizeObserver(containerRef, onResizeCallback);
  }, [onResizeCallback]);

  return (
    <div ref={containerRef}>
      <div
        ref={ref}
        id='multi-step'
        className='flex flex-row flex-nowrap gap-1 transition-transform duration-500'
        style={{
          transform: `translateX(${translateBy}px)`,
        }}
      >
        {steps.map((step, index) => {
          const { hasError, label, isFinalized, subInfo, smallLabel } = step;
          const isActive = activeStep === index;
          const isCompleted = activeStep > index || isFinalized;
          const isNextActive = activeStep >= index + 1;

          const shouldShowStepLabel = () => {
            if (isMobile && !isActive) return false;
            return true;
          };

          const labelStyles = 'mx-2 pt-3 text-center break-words';
          const labelColorStyles = classNames({
            'text-gray-silver': !isActive && !isCompleted,
            'text-gray-charcoal': isActive,
            'text-primary': isCompleted,
            '!text-negative': hasError,
          });

          return (
            <div
              key={index}
              className='step flex flex-col items-center shrink-0'
              style={{ width: `${itemWidth}px` }}
              onClick={() => {
                if (onStepChange) {
                  onStepChange(index);
                }
              }}
            >
              <div className='w-full flex justify-center'>
                <div
                  className={classNames(lineClasses, {
                    'border-gray-dusty': !isActive && !isNextActive,
                    'border-primary': isActive || isNextActive,
                  })}
                />
                <StepStatusIcon index={index} hasError={!!hasError} isActive={isActive} isCompleted={!!isCompleted} />
                <div
                  className={classNames(lineClasses, {
                    'border-gray-dusty': !isNextActive,
                    'border-primary': isNextActive,
                  })}
                />
              </div>
              {shouldShowStepLabel() && (
                <div className='flex flex-col'>
                  {smallLabel ? (
                    <Paragraph size={paragraphSizes.large}>{label}</Paragraph>
                  ) : (
                    <Heading3 className={labelStyles} weight={fontWeights.semibold} colorClassName={labelColorStyles}>
                      {label}
                    </Heading3>
                  )}
                  {subInfo && isActive && (
                    <Paragraph weight={fontWeights.medium} className='mt-1'>
                      {subInfo}
                    </Paragraph>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div>{steps[activeStep] ? steps[activeStep].component : <Redirect to={'/error'} />}</div>
    </div>
  );
};

const StepStatusIcon = ({
  hasError,
  isActive,
  isCompleted,
  index,
}: {
  index: number;
  hasError: boolean;
  isActive: boolean;
  isCompleted: boolean;
}) => {
  if (hasError) return <CloseIconWithCircle className='w-12 h-12 shrink-0' color='var(--color-negative)' />;
  if (isCompleted) return <FilledCheckIcon className='w-12 h-12 shrink-0' color='var(--color-primary)' />;
  return (
    <div
      className={classNames(
        'text-xl font-bold flex items-center justify-center w-12 h-12 shrink-0 rounded-full text-white',
        {
          'bg-gray-silver': !isActive,
          'bg-gray-charcoal': isActive,
        },
      )}
    >
      {index + 1}
    </div>
  );
};
