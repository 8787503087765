import { Clock } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { CloseButton, useTooltipContext } from '@va/ui/tooltips';
import { appHasDarkTheme, getLocalizedDate, getPercentageChange, getTimestampFromIso } from '@va/util/helpers';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import { useMemo } from 'react';
import { TooltipTransformerFunction } from './ExternalTooltip';
import { TooltipGridRow } from './tooltip/TooltipGridRow';
import { TooltipSubtitle } from './tooltip/TooltipSubtitle';
import { TooltipTitle } from './tooltip/TooltipTitle';

/** @deprecated */
function getPercentageChanges(
  currentValues: { [key: string]: { value: number } },
  previousValues: { [key: string]: { value: number } },
) {
  const percentageChangeValues: { [key: string]: number | '-' } = {};
  for (const k of Object.keys(currentValues)) {
    if (!previousValues) {
      percentageChangeValues[k] = '-';
      continue;
    }

    if (!previousValues[k] || previousValues[k].value === 0) {
      percentageChangeValues[k] = '-';
      continue;
    }

    percentageChangeValues[k] = getPercentageChange(previousValues[k].value, currentValues[k].value);
  }
  return percentageChangeValues;
}

/** @deprecated */
export type RawTooltipRow = {
  value: number;
  color: string;
};

/** @deprecated */
export type LineChartTooltipProps = {
  currentValues: {
    [key: string]: RawTooltipRow;
  };
  previousValues: {
    [key: string]: RawTooltipRow;
  };
  title?: string;
  subtitle?: string;
  currentPeriod: string;
  previousPeriod: string;
  showPrevious: boolean;
  datasetsTransformer?: TooltipTransformerFunction;
  reversePercentageColors?: boolean;
  closeTooltip?: () => void;
};

/** @deprecated */
export const LineChartTooltip = ({
  currentValues,
  previousValues,
  subtitle,
  title,
  currentPeriod,
  previousPeriod,
  showPrevious,
  datasetsTransformer,
  reversePercentageColors,
  closeTooltip,
}: LineChartTooltipProps) => {
  const { isMobile } = useWindowDimensions();
  const translate = useTranslate();
  const { locale } = useLocale();
  const percentageChanges = getPercentageChanges(currentValues, previousValues);
  const lineKeys = Object.keys(currentValues);
  const isDarkThemeApplied = appHasDarkTheme();
  const { context } = useTooltipContext();

  const tooltipValues = useMemo(() => {
    if (!datasetsTransformer) {
      return { current: currentValues, previous: previousValues };
    }

    return {
      current: datasetsTransformer(currentValues),
      previous: datasetsTransformer(previousValues),
    };
  }, [currentValues, datasetsTransformer, previousValues]);

  const gridStyle = useMemo(() => {
    if (!showPrevious) return 'auto repeat(2, minmax(0, 100px))';
    if (isMobile) return '125px minmax(60px, 1fr) repeat(2, minmax(0px, 60px))';
    return '135px minmax(120px, 1fr) repeat(2, minmax(0, 100px))';
  }, [isMobile, showPrevious]);

  return (
    <div
      className={classNames('text-12 sm-initial:text-15 pb-6px', {
        'text-white': !isDarkThemeApplied,
      })}
    >
      <div className='flex justify-between gap-2'>
        <TooltipTitle title={title} />
        <CloseButton
          onClose={() => [context.onOpenChange(false), closeTooltip?.()]}
          className={classNames({
            'top-2': !title,
          })}
        />
      </div>
      <div
        className={classNames('pt-2 grid gap-x-15px gap-y-3 sm-initial:items-center', {
          'grid-cols-3': !showPrevious,
          'grid-cols-4': showPrevious,
        })}
        style={{
          gridTemplateColumns: gridStyle,
        }}
      >
        {/* Header */}
        <TooltipSubtitle subtitle={subtitle} elementsCount={lineKeys.length} />
        <PeriodHeadline
          className={classNames({
            'col-span-2': !showPrevious,
          })}
          period={getLocalizedDate(getTimestampFromIso(currentPeriod), locale, { weekday: 'short' })}
        />
        {showPrevious && (
          <PeriodHeadline
            className='col-span-2'
            period={translate('tooltips.comparedToPreviousPeriod', {
              period: getLocalizedDate(getTimestampFromIso(previousPeriod), locale, { weekday: 'short' }) || 'N/A',
            })}
          />
        )}
        {lineKeys.map((key) => (
          <TooltipGridRow
            key={key}
            rowKey={key}
            showPrevious={showPrevious}
            currentValue={tooltipValues.current[key]}
            previousValue={tooltipValues.previous[key]}
            percentageChange={percentageChanges[key]}
            reversePercentageColors={reversePercentageColors}
          />
        ))}
      </div>
    </div>
  );
};

/** @deprecated */
function PeriodHeadline({ period, className }: { period: string; className?: string }) {
  const isDarkThemeApplied = appHasDarkTheme();
  return (
    <div
      className={classNames(
        'flex flex-col sm-initial:flex-row sm-initial:items-center gap-9px sm-initial:flex-nowrap',
        className,
        {
          'text-white-83': !isDarkThemeApplied,
        },
      )}
    >
      <Clock strokeColor={isDarkThemeApplied ? '#000000' : '#fff'} strokeOpacity={0.83} className='shrink-0' />
      <span className='text-12'>{period}</span>
    </div>
  );
}
