import { getInstanceId, isModuleEnabled } from '@va/dashboard/selectors/app';
import { getWebsitePlatform, getWebsiteStatus } from '@va/dashboard/selectors/core';
import { isSidebarOpen, isTabletDevice } from '@va/dashboard/selectors/ui';
import { AppVersion, Sidebar, UpgradeAccountNav } from '@va/dashboard/shared/feature-sidebar';
import { navigationActions } from '@va/dashboard/slices';
import { getNavigationTabs, isAccountSettingsPageSelected } from '@va/standalone/shared/helpers';
import { getUserRole } from '@va/standalone/shared/selectors';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const SidebarWrapper = () => {
  const websiteId = useSelector(getInstanceId);
  const websiteStatus = useSelector(getWebsiteStatus);
  const websitePlatform = useSelector(getWebsitePlatform);
  const userRole = useSelector(getUserRole);
  const isTablet = useSelector(isTabletDevice);
  const isEnabled = useSelector(isModuleEnabled);

  const navigationTabs = useMemo(
    () => getNavigationTabs(websiteId, websiteStatus, websitePlatform, userRole, isEnabled),
    [websiteId, websiteStatus, websitePlatform, userRole, isEnabled],
  );

  const dispatch = useDispatch();
  const isOpen = useSelector(isSidebarOpen);

  if (isAccountSettingsPageSelected() && !isTablet) return null;

  return (
    <Sidebar
      // TODO Properly sync types
      // @ts-ignore
      sidebarItems={navigationTabs}
      onToggleSidebar={() => {
        dispatch(navigationActions.toggleSidebar());
      }}
      isSidebarExpand={isOpen}
      footer={() => (
        <div className='flex flex-col items-center gap-3 justify-end h-full pb-3'>
          <UpgradeAccountNav />
          <AppVersion />
        </div>
      )}
      setIsSidebarExpanded={(value) => {
        dispatch(navigationActions.setIsSidebarOpen(value));
      }}
    />
  );
};
