import { CommonProps } from '@va/types/component';
import { fontWeights, Paragraph } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { ReactElement, ReactNode, useMemo } from 'react';

type VisitDetailsProps = CommonProps & {
  icon: ReactNode;
  tooltipText?: ReactElement | string;
  tooltipSubtext?: ReactElement | string;
};

export function VisitDetails({ icon, tooltipText, tooltipSubtext, ...rest }: VisitDetailsProps) {
  const isTooltipDisabled = useMemo(() => {
    if (tooltipText || tooltipSubtext) return false;
    return true;
  }, [tooltipSubtext, tooltipText]);

  const tooltipContent = () => {
    return (
      <div className='flex flex-col '>
        {tooltipText && <div className='text-10.5 flex justify-center font-medium leading-15'>{tooltipText}</div>}
        {tooltipSubtext && (
          <Paragraph weight={fontWeights.medium} className='flex justify-center'>
            {tooltipSubtext}
          </Paragraph>
        )}
      </div>
    );
  };

  return (
    // TODO maybe hide tooltips on mobile?
    <TooltipWrapper
      disabled={isTooltipDisabled}
      tooltipClassNames='!rounded-10.5 px-18.5 py-10.5'
      content={tooltipContent()}
    >
      <div
        data-testid={rest['data-testid']}
        className='h-12 w-12 shrink-0 rounded-full bg-white-snow group-hover:bg-white hover:bg-white flex justify-center items-center'
      >
        <div className='shrink-0'>{icon}</div>
      </div>
    </TooltipWrapper>
  );
}
