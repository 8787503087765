import { CopyIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { CheckMarkWithCircle } from '@va/svg-visa-icons';
import { CodeEditor } from '../code-editor/CodeEditor';
import './CodeEditorWithCopyBtn.scss';
import CopyToClipboardBtn from './CopyToClipboardBtn';

type CodeEditorWithCopyBtnType = {
  code: string;
};
export const CodeEditorWithCopyBtn = (props: CodeEditorWithCopyBtnType) => {
  const { code } = props;
  const translate = useTranslate();
  return (
    <div className='relative  '>
      <CodeEditor value={code} />
      <CopyToClipboardBtn
        btnClass={'w-full'}
        content={code}
        onCopyText={
          <span className='flex items-center'>
            <CheckMarkWithCircle color={'#FFF'} />
            <span className={'ml-2 font-medium text-15 text-white'}>{translate('button.copiedToClipboardText')}</span>
          </span>
        }
        btnText={
          <span className='flex items-center'>
            <CopyIcon color={'#FFF'} />
            <span className={'ml-2 font-medium text-15 text-white'}>
              {translate('button.copySnippetToClipboardText')}
            </span>
          </span>
        }
        containerClassName={
          'copy-code-btn-wrapper h-12 bg-primary bottom-3 right-3 z-10 absolute flex rounded-xl w-1/2 xs:w-11/12 m-auto'
        }
      />
    </div>
  );
};
