import { modalName, SSR_BETA_RELEASE_DATE_UNIX_TS, tabNames, TEST_IDS, userActions } from '@va/constants';
import { updateSessionExtras } from '@va/dashboard/actions/api';
import { setSsrSessionForRecordings } from '@va/dashboard/actions/app';
import { openModal, setTabProperty } from '@va/dashboard/actions/ui';
import { VideoCameraIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { hasPermission } from '@va/standalone/shared/selectors';
import { Button, ButtonColors } from '@va/ui/design-system';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export const VideoRecordingButton: React.FC<{
  sourceTab: string;
  text?: string;
  color?: ButtonColors;
  iconColor?: string;
  className?: string;
  showTooltip?: boolean;
  sessionInfo: { unixTs: number; id: string; hasRecording: boolean };
}> = (props) => {
  const history = useHistory();
  const { sourceTab, sessionInfo, text, color = 'secondary', iconColor, className, showTooltip = true } = props;
  const { unixTs, hasRecording, id: sessionId } = sessionInfo;
  const translate = useTranslate();
  const dispatch = useDispatch();
  const sessionAfterSsrReleaseDate = unixTs > SSR_BETA_RELEASE_DATE_UNIX_TS;
  const hasEditRecordingPermission = useSelector((state) => hasPermission(state, userActions.Edit));

  const handleButtonClick = () => {
    dispatch(setTabProperty(tabNames.visitorsHistory, 'selectedVisitorId', sessionId));
    if (!hasRecording && sessionAfterSsrReleaseDate) {
      dispatch(openModal(modalName.ssrTechnicalLimitation));
      return;
    }
    dispatch(setSsrSessionForRecordings(sessionId));
    if (hasEditRecordingPermission) {
      dispatch(updateSessionExtras(sessionId, { seen: true }));
    }
    history.push(`/behaviour/recordings/${sessionId}`);
    dispatch(setTabProperty(tabNames.recordings, 'sourceTab', sourceTab));
  };

  const tooltip = hasRecording
    ? translate('features.visitorRecording')
    : translate('features.visitorRecordingTechnicalIssue');

  return (
    <div className={classNames('relative shrink-0 flex items-center justify-center w-12', className)}>
      {/* TODO add another icon for no recordings */}
      <Button
        data-testid={TEST_IDS.generic.links.sessionRecordings}
        color={color}
        onClick={handleButtonClick}
        text={text}
        className={'w-full justify-center'}
        icon={(className) => (
          <div className='relative flex items-center justify-center'>
            <VideoCameraIcon className={className} color={iconColor} />
            {!hasRecording && (
              <div className='absolute text-18 rotate-[15px] text-primary z-10' style={{ color: iconColor }}>
                /
              </div>
            )}
          </div>
        )}
        tooltip={showTooltip ? tooltip : null}
      />
    </div>
  );
};
