export const EVENTS = {
  collapseSidebarItems: 'collapseSidebarItems',
  collapseFilters: 'collapseFilters',
  collapseSegments: 'collapseSegments',
  selectedSegmentsChanged: 'selectedSegmentsChanged',
  appliedFiltersChanged: 'appliedFiltersChanged',
  saveCustomDashboard: 'saveCustomDashboard',
  openDashboardEditMode: 'openDashboardEditMode',
  skipUnsavedChangesWarning: 'skipUnsavedChangesWarning',
  editRBName: 'editRBName',
};
