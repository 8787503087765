import { get, patch, post, remove } from '@va/http-client';

export const getVideoTrackingCode = (websiteId) => {
  const url = `/websites/${websiteId}/ssr-tracking-code`;
  return get(url, {});
};

export const checkSsrCode = (websiteId) => {
  const url = `/websites/${websiteId}/ssr-tracking-code/check`;
  return get(url, {});
};

export const deleteRecording = (websiteId, data) => {
  const url = `/websites/${websiteId}/recordings/delete`;
  return remove(url, {}, data);
};

export const getRecordingsCount = (websiteId) => {
  const url = `/websites/${websiteId}/recordings/count`;
  return get(url, {});
};

export const createSsrSettings = (websiteId, data) => {
  const url = `/websites/${websiteId}/ssr-settings`;
  return post(url, {}, data);
};

export const getSsrSettings = (websiteId) => {
  const url = `/websites/${websiteId}/ssr-settings`;
  return get(url, {});
};

export const updateSsrSettings = (websiteId, ssrSettingsId, data) => {
  const url = `/websites/${websiteId}/ssr-settings/${ssrSettingsId}`;
  return patch(url, {}, data);
};

export const updateSessionExtras = (websiteId, sessionId, sessionData) => {
  const url = `/websites/${websiteId}/sessions/${sessionId}/session-extras`;
  return patch(url, {}, sessionData);
};

export const updateMultipleSessionExtras = (websiteId, payload) => {
  const url = `/websites/${websiteId}/session-extras`;
  return patch(url, {}, payload);
};

export const getSsrOss = (websiteId) => {
  const url = `/websites/${websiteId}/recordings/oss`;
  return get(url, {});
};

export const getSsrBrowsers = (websiteId) => {
  const url = `/websites/${websiteId}/recordings/browsers`;
  return get(url, {});
};

export const getSsrMaxPageCount = (websiteId) => {
  const url = `/websites/${websiteId}/recordings/max-page-count`;
  return get(url, {});
};

export const getSsrMaxDuration = (websiteId) => {
  const url = `/websites/${websiteId}/recordings/max-duration`;
  return get(url, {});
};

export const getSsrComment = (websiteId, sessionId) => {
  const url = `/websites/${websiteId}/sessions/${sessionId}/comments`;
  return get(url, {});
};

export const createSsrComment = (websiteId, sessionId, data) => {
  const url = `/websites/${websiteId}/sessions/${sessionId}/comments`;
  return post(url, {}, data);
};

export const updateSsrComment = (websiteId, sessionId, commentId, data) => {
  const url = `/websites/${websiteId}/sessions/${sessionId}/comments/${commentId}`;
  return patch(url, {}, data);
};

export const deleteSsrComment = (websiteId, sessionId, commentId) => {
  const url = `/websites/${websiteId}/sessions/${sessionId}/comments/${commentId}`;
  return remove(url, {});
};
