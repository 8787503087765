import { useTranslate } from '@va/localization';
import { InputMessage, TextInput } from '@va/ui/design-system';
import { isNil } from 'lodash';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { filterValidationKeys } from '../../constants';
import { FilterInputComponentRefType } from '../../filter-types';
import { useFiltersContext } from '../../filters-context';
import { useFilterValidation } from '../../useFilterValidation';
import { PickOperatorBtn } from '../PickOperatorBtn';
import { useOperator } from '../useOperator';
import { ComparisonFilter } from './ComparisonFilter';

export const ComparisonFilterInput = forwardRef<FilterInputComponentRefType, { filter: ComparisonFilter }>(
  ({ filter }, ref) => {
    const { operatorOptions } = filter;

    const [value, setValue] = useState<number | undefined>(undefined);
    const { operator, setOperator } = useOperator(filter.operator);

    const { applySingleNewFilter } = useFiltersContext();

    const translate = useTranslate();

    const onSubmit = useCallback(() => {
      const clone = Object.assign({}, filter);
      clone.values = [value ?? 0];
      clone.operator = operator;

      applySingleNewFilter(clone);
    }, [applySingleNewFilter, filter, operator, value]);

    const { error, handleSubmit, validate } = useFilterValidation({
      onSubmit,
      value,
      validationFunc: (val) => {
        if (isNil(val)) {
          return filterValidationKeys.required;
        }
      },
    });

    useImperativeHandle(ref, () => ({ submit: handleSubmit }));

    return (
      <>
        <div className='flex items-center w-full gap-3'>
          <PickOperatorBtn
            onOperatorChange={setOperator}
            selectedOperator={operator}
            operatorOptions={operatorOptions}
          />
          <TextInput
            wrapperClassName='flex-1 min-w-[110px]'
            type='number'
            heightClass='h-12'
            clearField={() => {
              setValue(undefined);
            }}
            showIcons={false}
            value={value}
            debouncedInput={false}
            onChange={(e) => {
              const eventValue = e.target.value;

              let val;

              if (eventValue === '') {
                val = undefined;
              } else {
                val = Number(eventValue);
              }

              setValue(val);
              validate(val);
            }}
          />
        </div>

        {error && <InputMessage error={translate(error)} />}
      </>
    );
  },
);
