import { EventBus } from '@va/util/misc';
import classNames from 'classnames';
import React, { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { SideArrowScroll } from './SideArrowScroll';
import { SCROLL_DIRECTION } from './enums';

export const HorizontalScroll = forwardRef<
  HTMLDivElement,
  {
    children: any;
    scrollPixels?: number;
    className?: string;
    hideScrollbar?: boolean;
    maxHeight?: string;
    nameForSyncScroll?: string;
    disabled?: boolean;
  }
>(
  (
    { children, scrollPixels = 200, nameForSyncScroll, className, hideScrollbar = false, maxHeight, disabled = false },
    ref,
  ) => {
    const [canScrollLeft, setCanScrollLeft] = React.useState(false);
    const [canScrollRight, setCanScrollRight] = React.useState(false);
    const contentRef = React.useRef<HTMLDivElement>(null);

    const componentId = useMemo(() => Math.random(), []);

    const emitSyncScrollEvent = useCallback(
      (scrollLeft: number) => {
        if (!nameForSyncScroll) return;

        EventBus.dispatch('syncScroll', {
          scrollLeft: scrollLeft,
          emitter: componentId,
          name: nameForSyncScroll,
        });
      },
      [componentId, nameForSyncScroll],
    );

    const updateScrollValues = React.useCallback((element: HTMLElement | null) => {
      if (!element) return;

      setCanScrollRight(element.scrollWidth > element.clientWidth + element.scrollLeft);
      setCanScrollLeft(element.scrollLeft > 0);
    }, []);

    const handleOnScroll = useCallback(
      (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const element = e.target as HTMLElement;
        updateScrollValues(element);

        const scrollLeft = element.scrollLeft;

        emitSyncScrollEvent(scrollLeft);
      },
      [emitSyncScrollEvent, updateScrollValues],
    );

    const scrollElement = useCallback(
      (element: HTMLElement, scrollDirection: SCROLL_DIRECTION) => {
        if (scrollDirection === SCROLL_DIRECTION.RIGHT) {
          element.scrollLeft += scrollPixels;
        } else {
          element.scrollLeft -= scrollPixels;
        }
        emitSyncScrollEvent(element.scrollLeft);
        updateScrollValues(element);
      },
      [emitSyncScrollEvent, scrollPixels, updateScrollValues],
    );

    const scrollFn = React.useCallback(
      (element: HTMLElement, scrollDirection: SCROLL_DIRECTION, canScroll = false) => {
        if (!element || !canScroll) return;
        scrollElement(element, scrollDirection);
      },
      [scrollElement],
    );

    useEffect(() => {
      if (disabled) return;
      if (!nameForSyncScroll) return;

      const listener = EventBus.addListener(
        'syncScroll',
        ({ name, emitter, scrollLeft }: { name: string; emitter: number; scrollLeft: number }) => {
          if (emitter === componentId) return;
          if (name !== nameForSyncScroll) return;
          if (!contentRef.current) return;
          contentRef.current.scrollLeft = scrollLeft;
          updateScrollValues(contentRef.current);
        },
      );
      return () => {
        listener.removeListener();
      };
    }, [componentId, disabled, nameForSyncScroll, scrollElement, scrollFn, scrollPixels, updateScrollValues]);

    return disabled ? (
      children
    ) : (
      <div className={classNames('relative', className)} ref={ref}>
        <div
          className={classNames('overflow-x-auto scrollbar scrollbar-thumb', {
            'scrollbar-hidden': hideScrollbar,
          })}
          ref={contentRef}
          onMouseEnter={() => updateScrollValues(contentRef.current)}
          onMouseLeave={() => {
            setCanScrollRight(false);
            setCanScrollLeft(false);
          }}
          onScroll={(e) => {
            handleOnScroll(e);
          }}
          style={{ maxHeight: maxHeight }}
        >
          {children}
        </div>

        <SideArrowScroll
          scrollDirection={SCROLL_DIRECTION.LEFT}
          canScroll={canScrollLeft}
          scrollFn={() => {
            if (!contentRef?.current) return;
            scrollFn(contentRef.current, SCROLL_DIRECTION.LEFT, canScrollLeft);
          }}
          updateScrollValues={() => updateScrollValues(contentRef.current)}
        />

        <SideArrowScroll
          scrollDirection={SCROLL_DIRECTION.RIGHT}
          canScroll={canScrollRight}
          scrollFn={() => {
            if (!contentRef?.current) return;
            scrollFn(contentRef.current, SCROLL_DIRECTION.RIGHT, canScrollRight);
          }}
          updateScrollValues={() => updateScrollValues(contentRef.current)}
        />
      </div>
    );
  },
);
