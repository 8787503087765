import { TEST_IDS } from '@va/constants';
import { setModalMounted } from '@va/dashboard/actions/ui';
import { isModalMounted } from '@va/dashboard/selectors/ui';
import { withHover } from '@va/deprecated/components/withHover';
import { CloseIcon } from '@va/icons';
import { BackIconWithBorder } from '@va/svg-visa-icons';
import { Button } from '@va/ui/design-system';
import classNames from 'classnames';
import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//@ts-ignore
import Rodal from 'rodal';

type ModalWrapperTypes = {
  isOpen: boolean;
  roundedCorners?: boolean;
  bigger?: boolean;
  contentWidth?: number;
  blackCloseButton?: boolean;
  onBackButtonClick?: React.MouseEventHandler<HTMLDivElement>;
  showBackIcon?: boolean;
  children: JSX.Element;
  showCloseIconWithBorder?: boolean;
  closeModal?: Function;
  closeIcon?: JSX.Element;
  closeMaskOnClick?: Function;
  animation?: string;
  className?: string;
  showCloseButton?: boolean;
  height?: number;
  width?: number;
};

/** @deprecated */
const ModalWrapper: React.FC<ModalWrapperTypes> = (props) => {
  const {
    isOpen,
    roundedCorners = true,
    bigger,
    contentWidth,
    blackCloseButton,
    onBackButtonClick,
    showBackIcon,
    children,
    showCloseIconWithBorder,
    closeModal,
    closeIcon,
    closeMaskOnClick,
    animation = 'zoom',
    className,
    showCloseButton,
    height,
    width,
  } = props;

  const dispatch = useDispatch();
  const hasModalMounted = useSelector(isModalMounted);

  const rodalProps = useMemo(() => {
    return {
      closeMaskOnClick,
      animation,
      className: className,
      visible: isOpen,
      showCloseButton: showCloseButton,
      duration: 500,
      customStyles: {
        height: height || 'auto',
        width: width || 'auto',
      },
      onClose: closeModal,
    };
  }, [closeMaskOnClick, animation, className, isOpen, showCloseButton, height, width, closeModal]);

  useEffect(() => {
    if (!hasModalMounted) {
      dispatch(setModalMounted());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isOpen) return null;

  return (
    <div
      className={classNames('custom-rodal', {
        'rounded-corners': roundedCorners,
        bigger: bigger,
        'content-width': contentWidth,
        'black-close-btn': blackCloseButton,
      })}
    >
      <Rodal {...rodalProps}>
        {showBackIcon && (
          <div className='z-[var(--zIndex-modal)] absolute top-20 left-20 cursor-pointer' onClick={onBackButtonClick}>
            <BackIconWithBorderWithHover className='w-10 h-10' hoverColor={window.PRIMARY_COLOR} />
          </div>
        )}
        {children}
        {showCloseIconWithBorder && (
          <Button
            onClick={() => closeModal?.()}
            color='tertiary'
            className={'absolute top-20 right-20 w-10 h-10 z-999 cursor-pointer'}
            icon={() => <CloseIcon />}
            data-testid={TEST_IDS.generic.buttons.closeModal}
          />
        )}
        {closeIcon && (
          <div className='close-icon-wrapper' onClick={() => closeModal?.()}>
            {closeIcon}
          </div>
        )}
      </Rodal>
    </div>
  );
};

/** @deprecated */
export default memo(ModalWrapper);

export const BackIconWithBorderWithHover = withHover()(
  ({ hovered, hoverColor, className }: { hovered: boolean; hoverColor: string; className: string }) => (
    <BackIconWithBorder color={hovered ? hoverColor : undefined} className={className} />
  ),
);
