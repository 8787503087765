import { arrow, computePosition, offset, shift } from '@floating-ui/react';
import * as d3 from 'd3';
import { isNil } from 'lodash';
import worldgeojson from './world.json';

type DataItem<T> = {
  fillColor: string;
} & T;

type TopoData = {
  type: string;
  properties: {
    name: string;
  };
  geometry: {
    type: string;
    coordinates: any;
  };
  id: string;
};

export type MapData<T> = Record<string, DataItem<T>>;

const defaultCfg: Partial<ChoroplethMapCfg<any>> = {
  markersEnabled: true,
  tooltipOptions: {
    arrowColor: '#000000',
  },
};

type TooltipOptions = {
  arrowColor?: string;
};

export type ChoroplethMapCfg<T> = {
  containerEl: HTMLElement;
  tooltipTemplate: (data: DataItem<T> | undefined, topoData: TopoData, eventType: 'click' | 'mouseOver') => string;
  markerTemplate?: (data: DataItem<T> | undefined, topoData: TopoData) => string;
  markersEnabled?: boolean;
  data: MapData<T>;
  defaultFill: string;
  test?: boolean;
  tooltipOptions?: TooltipOptions;
};

export class ChoroplethMap<T> {
  svg: d3.Selection<any, any, null, undefined>;
  data: MapData<T>;
  tooltip: HTMLElement | null | undefined;
  clicked: boolean = false;
  zoomFactor = 1;

  constructor(private cfg: ChoroplethMapCfg<T>) {
    if (isNil(cfg.markersEnabled)) {
      this.cfg.markersEnabled = defaultCfg.markersEnabled;
    }

    const { containerEl, data } = this.cfg;

    this.svg = d3
      .select(containerEl)
      .append('svg')
      .attr('width', containerEl.clientWidth)
      .attr('height', containerEl.clientHeight)
      .attr('class', 'chropleth-map')
      .style('overflow', 'hidden');

    this.data = data;

    this.draw();
    this.addZoom();

    d3.select(window).on('resize', () => {
      this.svg.selectAll('*').remove();

      const { height, width } = this.getParentDimensions();

      this.svg.attr('width', width);
      this.svg.attr('height', height);

      this.draw();
    });
  }

  addMarkers() {
    this.svg.selectAll('.subunit').each((d: any) => {
      const data = this.getSubunitData(d.id);
      if (data) {
        this.createMarker(d);
      }
    });
  }

  createMarker(d: TopoData) {
    const path = this.getGeoPath();
    const data = this.getSubunitData(d.id);

    this.svg
      .select('g')
      .append('g')
      .attr('class', `marker ${d.id}`)
      .html(() => {
        return this.cfg.markerTemplate?.(data, d) ?? '';
      })
      .attr('transform', () => {
        const c = path.centroid(d.geometry as any);
        return `translate(${c[0]}, ${c[1]}) scale(${this.computeMarkerSize()})`;
      });
  }

  updateMarkers() {
    this.svg.selectAll('.subunit').each((d: any) => {
      const marker = this.svg.select(`.marker.${d.id}`);
      const data = this.getSubunitData(d.id);

      const isEmptyMarker = marker.empty();

      if (isEmptyMarker && !data) {
        return;
      }

      if (!this.cfg.markersEnabled) {
        marker.remove();
        return;
      }

      if (isEmptyMarker && data) {
        this.createMarker(d);
        return;
      }

      if (!isEmptyMarker && !data) {
        marker.remove();
        return;
      }
    });
  }

  update(newCfg: Partial<ChoroplethMapCfg<T>>) {
    const { data: newData, ...rest } = newCfg;

    if (newData) {
      this.svg.selectAll('.subunit').each((d: any) => {
        this.svg.select(`.${d.id}`).attr('fill', newData[d.id]?.fillColor ?? this.cfg.defaultFill);
      });

      this.data = newData;
    }

    Object.assign(this.cfg, rest);

    this.updateMarkers();
  }

  showTooltip(reference: HTMLElement, topoData: TopoData, eventType: 'click' | 'mouseOver') {
    if (!reference) return;

    if (!this.tooltip) {
      this.tooltip = document.createElement('div');
      this.tooltip.style.position = 'absolute';

      this.cfg.containerEl.append(this.tooltip);
    }

    this.tooltip.innerHTML = this.cfg.tooltipTemplate(this.getSubunitData(topoData.id), topoData, eventType);

    const arrowEl = document.createElement('div');
    arrowEl.style.backgroundColor = this.cfg.tooltipOptions?.arrowColor ?? defaultCfg.tooltipOptions?.arrowColor ?? '';
    arrowEl.setAttribute('id', 'arrow');

    this.tooltip.append(arrowEl);

    computePosition(reference, this.tooltip, {
      placement: 'bottom',
      middleware: [offset(20), shift(), arrow({ element: arrowEl })],
    }).then(({ x, y, middlewareData }) => {
      if (!this.tooltip) return;

      Object.assign(this.tooltip.style, {
        top: `${y}px`,
        left: `${x}px`,
        position: 'absolute',
        zIndex: 9999,
      });
      if (middlewareData.arrow) {
        const { x, centerOffset } = middlewareData.arrow;
        if (centerOffset !== 0) {
          arrowEl.style.opacity = '0';
          return;
        }
        Object.assign(arrowEl?.style, {
          left: `${x}px`,
          top: `${-arrowEl?.offsetHeight / 2}px`,
        });
      }
    });
  }

  addZoom() {
    const width = +this.svg.attr('width');
    const height = +this.svg.attr('height');

    const zoom = d3
      .zoom()
      .translateExtent([
        [0, 0],
        [width, height],
      ])

      .scaleExtent([1, 5])
      .on('zoom', (e) => {
        this.onZoom(e);
      });

    this.svg.call(zoom as any);
  }

  updateMarkersSize() {
    const path = this.getGeoPath();

    this.svg.selectAll('.subunit').each((d: any) => {
      const marker = this.svg.select(`.marker.${d.id}`);

      marker.attr('transform', () => {
        const c = path.centroid(d.geometry as any);
        return `translate(${c[0]}, ${c[1]}) scale(${this.computeMarkerSize()})`;
      });
    });
  }

  computeMarkerSize() {
    const scale = d3.scaleLinear().domain([1, 2, 3, 4, 5]).range([1.5, 1.25, 1, 0.75, 0.5, 0.25]);
    return scale(this.zoomFactor);
  }

  onZoom(e: d3.D3ZoomEvent<any, any>) {
    this.svg.select('g').attr('transform', e.transform.toString()).style('transform-origin', '0 0');
    this.zoomFactor = e.transform.k;
    this.updateMarkersSize();
  }

  destroyTooltip() {
    if (!this.tooltip) return;
    this.tooltip.innerHTML = '';
  }

  private getSubunitData(id: string): DataItem<T> | undefined {
    return this.data[id];
  }

  private getParentDimensions() {
    const width = d3.select(this.cfg.containerEl).node()?.getBoundingClientRect()?.width ?? 0;
    const height = d3.select(this.cfg.containerEl).node()?.getBoundingClientRect()?.height ?? 0;
    return { width, height };
  }

  private getGeoPath() {
    const { height, width } = this.getParentDimensions();

    const projection = d3
      .geoEquirectangular()
      .scale(170)
      .center([0, 0])
      .translate([width / 2, height / 2]);

    return d3.geoPath().projection(projection);
  }

  private draw() {
    const path = this.getGeoPath();

    this.svg
      .append('g')
      .selectAll('path')
      .data(worldgeojson.features)
      .enter()
      .append('path')
      .attr('d', path as any)
      .style('stroke-width', 0.6)
      .style('stroke-opacity', 1)
      .style('stroke', '#b8bbbc')
      .attr('fill', (d) => {
        const data = this.getSubunitData(d.id);
        if (!data) return this.cfg.defaultFill;
        return data.fillColor;
      })
      .attr('class', (d) => {
        return `subunit ${d.id}`;
      })
      .on('mouseover', (e, d) => {
        this.svg.select(`.${d.id}`).attr('fill', window.PRIMARY_COLOR);

        if (!this.clicked) {
          this.showTooltip(e.target, d, 'mouseOver');
        }
      })
      .on('mouseleave', (e, d) => {
        const data = this.getSubunitData(d.id);
        this.svg.select(`.${d.id}`).attr('fill', data?.fillColor ?? this.cfg.defaultFill);

        if (!this.clicked) {
          this.destroyTooltip();
        }
      })
      .on('click', (e, d) => {
        this.clicked = true;
        this.showTooltip(e.target, d, 'click');
      });

    d3.select(window).on('click', (e) => {
      if (this.clicked) {
        const className = (e.target as HTMLElement).classList;

        if (className.contains('subunit')) return;

        this.clicked = false;
        this.destroyTooltip();
      }
    });

    if (this.cfg.markersEnabled) {
      this.addMarkers();
    }
  }
}

export function getFillColor(count: number) {
  if (count > 50) return 'rgba(92, 4, 180)';
  if (count > 30) return 'rgba(92, 4, 180,0.8)';
  if (count > 20) return 'rgba(92, 4, 180,0.6)';
  if (count > 10) return 'rgba(92, 4, 180,0.45)';
  if (count > 0) return 'rgba(92, 4, 180,0.3)';
  return 'rgba(92, 4, 180,0.05)';
}
