import { getQueryStringProcessed } from '@va/util/router';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';

const RedirectComponent = (props) => {
  let to = props.to;

  // add query string to keep parameters when navigating
  if (props.keepQueryParams && isString(to)) {
    to = props.to.concat(props.location.search);
    if (props.extraQueryParams) {
      to = props.to.concat(getQueryStringProcessed(props.location.search, props.extraQueryParams));
    }
  }

  return <Redirect {...props} to={to} />;
};

RedirectComponent.propTypes = {
  push: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  keepQueryParams: PropTypes.bool,
  extraQueryParams: PropTypes.object,
};

export default withRouter(RedirectComponent);
