import { apiStatus, flagKeys } from '@va/constants';
import { getAccountInformation } from '@va/dashboard/selectors/api';
import Immutable from 'immutable';
import { isNil } from 'lodash';
import { createSelector } from 'reselect';

export const getApiRequestStatus = (state: any, apiRequestName: string) =>
  state.getIn(['api', apiRequestName, 'status'], apiStatus.NONE);

export const getApiRequest = (state: any, apiRequestName: string) =>
  state.getIn(['api', apiRequestName], Immutable.Map());

export const getTrackingCode = (state: any) =>
  state.getIn(['api', 'getTrackingCode', 'payload', 'trackingCode'], Immutable.Map());
export const getMpmTrackingCode = (state: any) =>
  state.getIn(['api', 'getTrackingCode', 'payload', 'mpmTrackingCode'], Immutable.Map());
export const getAgencyId = (state: any) => state.getIn(['api', 'getAgencyUi', 'data', 'id']);
export const getAccountOwnerEmail = (state: any) => state.getIn(['core', 'api', 'accountInformation', 'email'], '');
export const getUserFlags = createSelector(getAccountInformation, (data) => data['frontendFlags'] || {});
export const getWebsites = (state: any) => state.getIn(['api', 'websites'], Immutable.Map());
export const getOwnedWebsites = (state: any) => state.getIn(['api', 'websites', 'websites'], Immutable.Map());
export const getWebsiteOverview = (state: any, id: string) =>
  state.getIn(['api', 'getWebsiteOverview', id], Immutable.Map());
export const getCustomer = (state: any) => state.getIn(['api', 'getCustomer']).toJS();

export const userHasFinishedOnboarding = createSelector(getUserFlags, (flags) => {
  const flag = flags[flagKeys.ONBOARDING_FINISHED];
  if (flag === false || flag === undefined) return false;
  return true;
});

export const getLastOnboardingStep = createSelector(getUserFlags, (flags) => {
  const step = flags[flagKeys.LAST_COMPLETED_ONBOARDING_STEP];
  if (isNil(step)) return undefined;
  // For 0 and 1 lastCompletedOnboardingStep is stored as boolean by BE
  return Number(step);
});

export const userHasWebsites = createSelector(getWebsites, (websites) => websites?.get('total') > 0);

export const getWebsitesMerged = (state: any) => getOwnedWebsites(state);

export const getOldestInstallDate = createSelector(
  getWebsitesMerged,
  (websites) =>
    websites &&
    websites.toJS() &&
    Math.min.apply(
      null,
      websites.toJS().map((item: any) => item.installDate),
    ),
);

export const getMigrationsUser = (state: any) => state.getIn(['api', 'migrationsUser'], Immutable.Map());

export const getUser = (state: any) => state.getIn(['api', 'user'], Immutable.Map());

export const isAgencyAccount = (state: any) => {
  const accountInfo = getAccountInformation(state);
  return accountInfo.isAgency;
};

export const isAgencyOwner = (state: any) => {
  const accountInfo = getAccountInformation(state);
  return accountInfo.isAgencyOwner;
};

export const getAgencyUi = (state: any) => getApiRequest(state, 'getAgencyUi')?.get('data')?.toJS();

export const getPrimaryColor = createSelector([getAgencyUi], (agencyUi) => {
  if (!agencyUi) return window.PRIMARY_COLOR;

  return agencyUi?.theme?.colors?.primary ?? window.PRIMARY_COLOR;
});
