export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
export const colorCodeRegex = /^#[0-9A-F]{6}$/i;
export const validateUrlWithProtocolRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,5})?(\/.*)?$/;
export const ip4Regex =
  /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9*])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9*])$/;
// eslint-disable-next-line no-useless-escape
export const domainRegex = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,50}[\.]{0,1}/;
export const websiteUrlRegex =
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=-]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validationTranslationKeys = {
  required: 'form.requiredField',
  requiredIfOtherFieldExists: 'form.validation.requiredIfOtherFieldExists',
  invalidEmail: 'register.form.email.invalidMessage',
  passwordDoNotMatch: 'form.passwordDoNotMatch',
  passwordStrength: 'form.passwordStrength',
  invalidCustomDomainCharacters: 'form.validation.invalidCustomDomainCharacters',
  invalidStartCharCustomDomain: 'form.validation.invalidStartCharCustomDomain',
  unavailableCustomDomain: 'form.validation.unavailableCustomDomain',
  minChars: 'form.validation.minimumCharacters',
  maxChars: 'form.validation.maximumCharacters',
  maximumCharactersCount: 'form.validation.maximumCharactersCount',
  number: 'form.validation.mustBeNumber',
  websiteUrl: 'validation.websiteUrl',
};

export const passwordStrengthTransKeys = {
  veryWeak: 'password.strength.veryWeak',
  weak: 'password.strength.weak',
  medium: 'password.strength.medium',
  strong: 'password.strength.strong',
};
