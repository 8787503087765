import { BLACK_COLOR } from '@va/constants';
import { TestAttributes } from '@va/types/component';
import { Link } from '@va/util/router';
import classNames from 'classnames';
import { ElementType } from 'react';
import { ContextMenuProps } from '../context-menu';
import { ContextMenuWrapper } from './ContextMenuWrapper';

type NestedNavigationLinkProps = TestAttributes & {
  url: string;
  onRouteChange?: () => void;
  isActive: boolean;
  className?: string;
  icon?: ElementType;
  label: string;
  hasTooltip?: boolean;
  contextMenu?: ContextMenuProps;
  contextMenuElement?: React.ReactNode;
};

export const NestedNavigationLink = ({
  url,
  onRouteChange,
  isActive,
  className,
  icon: Icon,
  label,
  hasTooltip,
  contextMenu,
  contextMenuElement,
  ...rest
}: NestedNavigationLinkProps) => {
  return (
    <div
      data-testid={rest['data-testid']}
      id='nested-nav-link-wrapper'
      className={classNames('flex items-center relative', { active: isActive })}
    >
      <Link
        id={'nested-nav-link'}
        to={url}
        key={url}
        onClick={onRouteChange}
        className={classNames(
          `whitespace-nowrap active:bg-gray-concrete py-3 px-4 text-15 transition font-medium text-center flex items-center`,
          { 'active text-gray-code': isActive },
          {
            'text-gray-charcoal hover:text-mine-shaft hover:bg-alabaster rounded-lg sm:rounded-none': !isActive,
          },
          className,
        )}
      >
        {Icon && <Icon isActive={isActive} color={BLACK_COLOR} className='hidden sm-initial:block mr-2' />}
        <span
          className={classNames({
            'underline-dashed': hasTooltip && isActive,
          })}
        >
          {label}
        </span>
      </Link>
      {isActive && contextMenuElement && contextMenuElement}
      {isActive && contextMenu && <ContextMenuWrapper contextMenu={contextMenu} />}
    </div>
  );
};
