import {
  ADS_RELEASE_DATE_UNIX_TS,
  LOCATIONS_RELEASE_DATE_UNIX_TS,
  modalName,
  nonTranslatableText,
  pathName,
  tabNames,
  UNTRANSLATED_LOCALE,
} from '@va/constants';
//This import is required in order to work all locale languages.
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import {
  getConversionTypes,
  requestLatestVisitorsMap,
  WEBSITE_REQUEST,
  WEBSITE_SUCCEEDED,
} from '@va/dashboard/actions/api';
import {
  addNotification,
  requestTranslationsFailed,
  requestTranslationsSucceeded,
  SET_ACTIVE_TAB,
  SET_GLOBAL_FILTER,
  setCustomizationOptions,
  setGlobalFilter,
  setLocale,
} from '@va/dashboard/actions/ui';
import { getFeatureReleaseDate, getInstanceId } from '@va/dashboard/selectors/app';
import { getWebsite, hasWebsite } from '@va/dashboard/selectors/core';
import { getGlobalFilter, getLocale, isModalOpen } from '@va/dashboard/selectors/ui';
import { getGoogleImporterProgress, LOGIN_SUCCEEDED } from '@va/standalone/shared/actions';
import { modalName as standaloneModals } from '@va/standalone/shared/constants';
import { isAccountSettingsPageSelected, isUserLoggedInWithCustomUrl } from '@va/standalone/shared/helpers';
import { appHistory } from '@va/standalone/shared/router';
import { getWebsites } from '@va/standalone/shared/selectors';
import { isStandaloneApp, setDefaultTheme, storeTimePeriodToSessionStorage } from '@va/util/helpers';
import Config from 'Config';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/pt';
import 'moment/locale/ro';
import { all, call, put, select, spawn, take, takeLatest } from 'redux-saga/effects';
import 'whatwg-fetch';

export function* watchers() {
  yield all([
    // takeLatest(REQUEST_TRANSLATIONS, getTranslations),
    takeLatest(SET_ACTIVE_TAB, handleSetActiveTab),
    takeLatest(SET_GLOBAL_FILTER, persistGlobalFilterToSessionStorage),
    // VISA-10395 - After logout the customization state is reset, so we need to set it back after login
    takeLatest(LOGIN_SUCCEEDED, handleSetCustomizationOptions),
  ]);
}

/** @deprecated */
export function requestTranslations(locale) {
  const url = `${Config.translationsUrl}/${locale}.json`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.ok) {
      // return data or json deserialization error
      return response.json();
    }

    // return error message from api or default http text
    throw new Error(response.statusText, response.status);
  });
}

/** @deprecated */
export function* getTranslations(action) {
  try {
    const localeFromStore = yield select(getLocale);
    const locale = action.locale || localeFromStore;
    if (locale === UNTRANSLATED_LOCALE) {
      yield put(setLocale(UNTRANSLATED_LOCALE));
      return;
    }
    const data = yield call(requestTranslations, locale);
    moment.locale(locale);
    yield put(requestTranslationsSucceeded(data, locale));
    yield put(setLocale(locale));
  } catch (error) {
    yield put(requestTranslationsFailed(error));
  }
}

function* websiteRequestSucceeded() {
  const websiteRequest = yield take([WEBSITE_REQUEST, WEBSITE_SUCCEEDED]);

  return websiteRequest.type === WEBSITE_SUCCEEDED;
}

function* resetFilterDownToFeatureReleaseDate(releaseDate) {
  const website = yield select(getWebsite);
  const installDate = website.installDate;
  let filter = yield select(getGlobalFilter);
  const fromDate = filter.from.unix();
  if (releaseDate && releaseDate > installDate && fromDate < releaseDate) {
    filter = {
      from: moment(releaseDate * 1000).startOf('day'),
      until: moment().endOf('day'),
    };
    yield put(setGlobalFilter(filter));
  }
}

export function* persistGlobalFilterToSessionStorage(action) {
  const websiteId = yield select(getInstanceId);
  storeTimePeriodToSessionStorage({
    from: action.filter?.from,
    until: action.filter?.until,
    websiteId: websiteId,
  });
}

export function* handleSetActiveTab(action) {
  if (isAccountSettingsPageSelected() && !isUserLoggedInWithCustomUrl()) {
    setDefaultTheme();
  }

  const websiteExists = yield select(hasWebsite);

  const allWebsites = yield select(getWebsites);
  const isAddWebsiteModalOpen = yield select(isModalOpen, modalName.addWebsite);
  const isContributorConfirmationModalOpen = yield select(isModalOpen, standaloneModals.CONFIRM_CONTRIBUTOR_REQUEST);

  //Check if there is no websites in the account, do now allow user to visit any other tab until he/she creates one.
  if (
    allWebsites?.get('total') === 0 &&
    isStandaloneApp() &&
    !isContributorConfirmationModalOpen &&
    ![tabNames.accountSettings, tabNames.addWebsite, tabNames.consent].includes(action.tab)
  ) {
    appHistory.push(pathName.manageWebsites);
    if (!isAddWebsiteModalOpen && action.tab !== 'manageWebsites') {
      yield put(
        addNotification({
          title: 'notifications.notifyToAddWebsite',
          level: 'error',
          autoDismiss: 10,
        }),
      );
    }
    return;
  }

  if (websiteExists || spawn(websiteRequestSucceeded)) {
    const filter = yield select(getGlobalFilter);

    switch (action.tab) {
      case tabNames.latestVisitorsMap:
        //VISA-3415 Temporary disabled this premium feature for free wix users
        yield put(requestLatestVisitorsMap(filter));
        break;
      case tabNames.latestVisitorsLocations: {
        const featureReleaseDate = yield select(getFeatureReleaseDate, LOCATIONS_RELEASE_DATE_UNIX_TS);
        yield spawn(resetFilterDownToFeatureReleaseDate, featureReleaseDate);
        break;
      }
      case tabNames.adsOverview: {
        const featureReleaseDate = yield select(getFeatureReleaseDate, ADS_RELEASE_DATE_UNIX_TS);
        yield spawn(resetFilterDownToFeatureReleaseDate, featureReleaseDate);
        break;
      }
      case tabNames.myCampaigns: {
        const featureReleaseDate = yield select(getFeatureReleaseDate, ADS_RELEASE_DATE_UNIX_TS);
        yield spawn(resetFilterDownToFeatureReleaseDate, featureReleaseDate);
        break;
      }

      case tabNames.pageSettings:
        yield put(getConversionTypes());

        break;
      case tabNames.import:
        yield put(getGoogleImporterProgress());
        break;
      case tabNames.trafficStructure:
      case tabNames.trafficShare:
      case tabNames.trafficSession:
      case tabNames.trafficPagesPerVisit:
      case tabNames.trafficBounceRate:
        yield spawn(resetFilterDownToFeatureReleaseDate, Config.trafficStructureReleaseDate);
        break;
      default:
        return null;
    }
  }
}

function* handleSetCustomizationOptions() {
  yield put(
    setCustomizationOptions({
      providerName: nonTranslatableText.appName,
      supportCenterDomain: 'https://www.twipla.com',
      chatwootToken: Config.chatwootToken,
    }),
  );
}
