import { TEST_IDS } from '@va/constants';
import { useMergeRefs } from '@va/util/hooks';
import { useStartIntersectionObserverCtx } from '@va/util/misc';
import classNames from 'classnames';
import { forwardRef, memo, PropsWithChildren, useRef } from 'react';
import { useReportBlockCtx } from './report-block-ctx';

export type ReportBlockBodyProps = {
  className?: string;
  border?: boolean;
};

export const ReportBlockBody = memo(
  forwardRef<HTMLDivElement, PropsWithChildren<ReportBlockBodyProps>>(({ className, border = true, children }, ref) => {
    const { id } = useReportBlockCtx();
    const innerRef = useRef<HTMLDivElement | null>(null);

    useStartIntersectionObserverCtx(innerRef);
    const mergeRefs = useMergeRefs([innerRef, ref]);
    return (
      <div
        data-testid={TEST_IDS.helpers.createReportBlockId(id)}
        ref={mergeRefs}
        className={classNames(
          'report-block bg-white rounded-24 p-6 relative overflow-hidden space-y-2',
          { 'border border-gray-mercury': border },
          className,
        )}
      >
        {children}
      </div>
    );
  }),
);
