import { useLocale } from '@va/localization';
import { TooltipWrapper } from '@va/ui/tooltips';
import { addNumberSeparator, calculatePercentFromTotal } from '@va/util/helpers';
import classNames from 'classnames';
import { useMemo } from 'react';
import { ProgressBar } from '../progress-bar';
import { Paragraph, ParagraphWithTooltip, paragraphSizes } from '../typography';

export const ProgressBarListItem = ({
  datasets,
  totalCount,
  onMouseEnter,
  onMouseLeave,
  className,
  isSelected = false,
  countLabel,
  maxCount,
  tooltip,
  rowDetails,
}: {
  datasets: { color: string; count: number }[];
  totalCount: number;
  maxCount?: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
  isSelected?: boolean;
  countLabel: string;
  tooltip?: string;
  rowDetails: React.ReactNode;
}) => {
  const { locale } = useLocale();

  const progress = useMemo(
    () =>
      datasets.map((item) => {
        return {
          color: item.color,
          percentage: calculatePercentFromTotal(item.count, maxCount ?? totalCount),
        };
      }),
    [datasets, maxCount, totalCount],
  );

  const datasetTotalCount = useMemo(
    () =>
      datasets.reduce((acc, item) => {
        return acc + item.count;
      }, 0),
    [datasets],
  );

  const percentFromTotal = useMemo(
    () => calculatePercentFromTotal(datasetTotalCount, totalCount),
    [datasetTotalCount, totalCount],
  );

  return (
    <TooltipWrapper disabled={!tooltip} content={tooltip}>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={classNames(
          'rounded-15 p-6px md:gap-7 group hover:bg-white-snow grid grid-cols-1 md:grid-cols-4',
          { '!bg-white-snow': isSelected },
          className,
        )}
      >
        {rowDetails}
        <div className='flex items-center w-full gap-7 col-span-3'>
          <div className='grow'>
            <ProgressBar progress={progress} />
          </div>
          <div className='text-right w-[90px]'>
            <Paragraph className='!font-525'>
              {addNumberSeparator(percentFromTotal, locale, { style: 'percent' })}
            </Paragraph>
            <ParagraphWithTooltip size={paragraphSizes.tiny} className='!font-525'>
              {addNumberSeparator(datasetTotalCount, locale)} {countLabel}
            </ParagraphWithTooltip>
          </div>
        </div>
      </div>
    </TooltipWrapper>
  );
};
