import { IconProps } from '..';

export const BlackBerryLogo = ({ className }: IconProps) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 24 24'
      xmlSpace='preserve'
      className={className}
    >
      <path
        d='M8.6,5.1C8.3,4.7,7.8,4.5,7.2,4.5H3.4C3.2,4.5,3,4.6,3,4.8L2.2,8.2c0,0.1,0,0.3,0.1,0.4s0.2,0.2,0.3,0.2h3.8
  c1.1,0,2.2-0.9,2.5-2V6.7C9,6,8.9,5.5,8.6,5.1z M8,6.4v0.1C7.8,7.2,7,7.8,6.3,7.8H3.1l0.6-2.5h3.5c0.3,0,0.6,0.1,0.7,0.3
  C8.1,5.8,8.1,6.1,8,6.4z'
      />
      <path
        d='M7.3,10.5C7,10.1,6.5,9.9,5.9,9.9H2.1c-0.2,0-0.4,0.1-0.4,0.3l-0.8,3.4c0,0.1,0,0.3,0.1,0.4
  c0.1,0.1,0.2,0.2,0.3,0.2h3.8c1.1,0,2.2-0.9,2.5-2v-0.1C7.7,11.5,7.6,10.9,7.3,10.5z M6.8,11.8L6.8,11.8c-0.2,0.8-1,1.4-1.7,1.4
  H1.9l0.6-2.5H6c0.3,0,0.6,0.1,0.7,0.3C6.8,11.3,6.9,11.5,6.8,11.8z'
      />
      <path
        d='M16.2,5.1c-0.3-0.4-0.8-0.6-1.4-0.6H11c-0.2,0-0.4,0.1-0.4,0.3L9.8,8.2c0,0.1,0,0.3,0.1,0.4s0.2,0.2,0.3,0.2
  H14c1.1,0,2.2-0.9,2.5-2V6.7C16.6,6,16.5,5.5,16.2,5.1z M15.6,6.4v0.1c-0.2,0.7-1,1.3-1.7,1.3h-3.2l0.6-2.5h3.5
  c0.3,0,0.6,0.1,0.7,0.3C15.7,5.8,15.7,6.1,15.6,6.4z'
      />
      <path
        d='M14.9,10.5c-0.3-0.4-0.8-0.6-1.4-0.6H9.7c-0.2,0-0.4,0.1-0.4,0.3l-0.8,3.4c0,0.1,0,0.3,0.1,0.4
  c0.1,0.1,0.2,0.2,0.3,0.2h3.8c1.1,0,2.2-0.9,2.5-2v-0.1C15.3,11.5,15.2,10.9,14.9,10.5z M14.4,11.8L14.4,11.8
  c-0.2,0.8-1,1.4-1.7,1.4H9.5l0.6-2.5h3.5c0.3,0,0.6,0.1,0.7,0.3C14.4,11.3,14.4,11.5,14.4,11.8z'
      />
      <path
        d='M22.9,8c-0.3-0.4-0.8-0.6-1.4-0.6h-3.8c-0.2,0-0.4,0.1-0.4,0.3L16.4,11c0,0.1,0,0.3,0.1,0.4
  c0.1,0.1,0.2,0.2,0.3,0.2h3.8c1.1,0,2.2-0.9,2.5-2V9.5C23.3,9,23.2,8.4,22.9,8z M22.4,9.3L22.4,9.3c-0.2,0.8-1,1.4-1.7,1.4h-3.2
  l0.6-2.5h3.5c0.3,0,0.6,0.1,0.7,0.3C22.4,8.7,22.4,9,22.4,9.3z'
      />
      <path
        d='M22,13.5c-0.3-0.4-0.8-0.6-1.4-0.6h-3.8c-0.2,0-0.4,0.1-0.4,0.3l-0.8,3.4c0,0.1,0,0.3,0.1,0.4
  c0.1,0.1,0.2,0.2,0.3,0.2h3.8c1.1,0,2.2-0.9,2.5-2v-0.1C22.5,14.4,22.4,13.9,22,13.5z M21.5,14.8L21.5,14.8
  c-0.2,0.8-1,1.4-1.7,1.4h-3.2l0.6-2.5h3.5c0.3,0,0.6,0.1,0.7,0.3C21.5,14.2,21.6,14.5,21.5,14.8z'
      />
      <path
        d='M13.2,16c-0.3-0.4-0.8-0.6-1.4-0.6H8c-0.2,0-0.4,0.1-0.4,0.3l-0.8,3.4c0,0.1,0,0.3,0.1,0.4
  c0.1,0.1,0.2,0.2,0.3,0.2H11c1.1,0,2.2-0.9,2.5-2v-0.1C13.6,17,13.5,16.4,13.2,16z M12.7,17.3L12.7,17.3c-0.2,0.8-1,1.4-1.7,1.4
  H7.8l0.6-2.5h3.5c0.3,0,0.6,0.1,0.7,0.3C12.7,16.7,12.7,17,12.7,17.3z'
      />
    </svg>
  );
};
