import { setInstanceId } from '@va/dashboard/actions/app';
import { useTranslate } from '@va/localization';
import { accountType } from '@va/standalone/shared/constants';
import { getAccountType, getLastWebsiteId } from '@va/standalone/shared/helpers';
import { PrivacyLevelsEnum } from '@va/standalone/shared/types';
import { Button, fontWeights, Heading5, HorizontalScroll, InputMessage, Paragraph } from '@va/ui/design-system';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DataPrivacyCard } from './DataPrivacyCard';
import { useUpdatePrivacySettings } from '../privacy-level-apiClient';
import { emptyArrayOfN } from '@va/util/helpers';
import { getPrivacySettingsSucceeded } from '@va/dashboard/actions/api';

export const DataPrivacyStep: FC<{
  onSuccess: () => void;
}> = ({ onSuccess }) => {
  const [privacyLevelValue, setPrivacyLevelValue] = useState<PrivacyLevelsEnum>(PrivacyLevelsEnum.completePrivacy);
  const translate = useTranslate();
  const dispatch = useDispatch();

  const onChange = useCallback((value: PrivacyLevelsEnum) => {
    setPrivacyLevelValue(value);
  }, []);

  const { isLoading, execute: updatePrivacyLevel, isSucceeded, error, data } = useUpdatePrivacySettings();

  const buildPayload = useCallback(() => {
    return emptyArrayOfN(4).map((_, index) => ({
      levelKey: index,
      selected: index === privacyLevelValue,
      exceptions: [],
    }));
  }, [privacyLevelValue]);

  const onSave = useCallback(() => {
    const payload = buildPayload();
    updatePrivacyLevel(payload);
  }, [buildPayload, updatePrivacyLevel]);

  useEffect(() => {
    if (getAccountType() === accountType.WEEBLY) {
      dispatch(setInstanceId(getLastWebsiteId()));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isSucceeded) return;

    dispatch(getPrivacySettingsSucceeded({ data: data?.payload }));
    window.skipTrackingCodeRedirect = true;
    onSuccess();
  }, [isSucceeded, onSuccess, data, dispatch]);

  return (
    <div className='max-w-full mx-auto mt-12 flex items-center flex-col'>
      <Heading5 weight={fontWeights.semibold} className='text-gray-code text-center mb-6px'>
        {translate('onboarding.dataPrivacy.title')}
      </Heading5>
      <Paragraph weight={fontWeights.medium} className='text-gray-charcoal text-center max-w-[90%] lg:max-w-[70%]'>
        {translate('onboarding.dataPrivacy.info')}
      </Paragraph>
      <HorizontalScroll className='w-full mt-36px' scrollPixels={300} hideScrollbar>
        <div className='min-w-full inline-flex flex-row flex-nowrap justify-start 2xl:justify-center gap-x-6 px-18px md:px-60px'>
          <DataPrivacyCard
            onClick={onChange}
            value={PrivacyLevelsEnum.completePrivacy}
            selected={privacyLevelValue === PrivacyLevelsEnum.completePrivacy}
            baseKey={'onboarding.privacyLevels.completePrivacy'}
          />
          <DataPrivacyCard
            onClick={onChange}
            value={PrivacyLevelsEnum.GDPRMode}
            selected={privacyLevelValue === PrivacyLevelsEnum.GDPRMode}
            baseKey={'onboarding.privacyLevels.gdprMode'}
          />
          <DataPrivacyCard
            onClick={onChange}
            value={PrivacyLevelsEnum.basicPrivacy}
            selected={privacyLevelValue === PrivacyLevelsEnum.basicPrivacy}
            baseKey={'onboarding.privacyLevels.basicPrivacy'}
          />
          <DataPrivacyCard
            onClick={onChange}
            value={PrivacyLevelsEnum.defaultPrivacy}
            selected={privacyLevelValue === PrivacyLevelsEnum.defaultPrivacy}
            baseKey={'onboarding.privacyLevels.defaultPrivacy'}
          />
        </div>
      </HorizontalScroll>
      <Button
        onClick={onSave}
        disabled={isLoading}
        className='w-11/12 md:w-600px my-8'
        text={isLoading ? translate('button.loading') : translate('button.continue')}
      />
      {error && <InputMessage error={translate('notifications.generalError')} />}
    </div>
  );
};
