import { getWebsite, getWebsiteTimezone } from '@va/dashboard/selectors/core';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import { LocalizationContext } from '@va/localization';
import L from 'leaflet';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Circle, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { connect } from 'react-redux';
import CustomMarkerClusterGroup from './CustomMarkerClusterGroup';
import { SessionInfoTooltip } from './SessionInfoTooltip';
import './index.scss';

const MAX_ZOOM = 14;
const MIN_ZOOM = 1;
const MAX_BOUNDS = [
  [85, -180],
  [-85, 180],
];

/**
 * Leaflet map with clustering and async tooltip
 */
class LeafletMap extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      center: [props.center.latitude, props.center.longitude],
      zoom: props.zoom,
      tooltipSticky: false,
      pointId: null,
      tooltip: null,
    };

    this.bindMethods(['onViewportChanged']);
  }

  /**
   * @param  {string[]} methods
   */
  bindMethods(methods) {
    methods.forEach((name) => {
      this[name] = this[name].bind(this);
    });
  }

  onViewportChanged(viewPort) {
    // map change is called when the map is initialized at which point
    // the tooltip reference might not be set
    this.state.tooltip?.hide();

    this.setState({
      zoom: viewPort.zoom,
      center: viewPort.center,
      pointId: null,
      tooltipSticky: false,
    });
  }

  getMarkers() {
    const icon = L.icon({
      iconUrl: '/marker-icon.png',
      iconSize: [19, 35],
    });

    return this.props.points.map((item, index) => {
      if (item.isPrivate) {
        return (
          <Circle
            className='max-privacy-map-circle'
            key={`${item.sessionKey}_${index}`}
            center={[item.lat, item.lng]}
            radius={this.props.circleRadius}
          />
        );
      } else {
        return (
          <Marker key={`${item.sessionKey}_${index}`} position={[item.lat, item.lng]} icon={icon}>
            <Popup className='min-w-[200px]'>
              <SessionInfoTooltip sessionKey={item.sessionKey} />
            </Popup>
          </Marker>
        );
      }
    });
  }

  render() {
    const { locale } = this.context;

    return (
      <div className='leaflet-map-panel'>
        <MapContainer
          ref={(node) => (this.map = node)}
          center={this.state.center}
          zoom={this.state.zoom}
          onViewportChanged={this.onViewportChanged}
          maxZoom={MAX_ZOOM}
          minZoom={MIN_ZOOM}
          maxBounds={MAX_BOUNDS}
        >
          <TileLayer
            url={`https://{s}.map.twipla.com/{z}/{x}/{y}.png?lang=${locale}`}
            attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
          />
          <CustomMarkerClusterGroup points={this.props.points} markers={this.getMarkers()} />
        </MapContainer>
      </div>
    );
  }
}

LeafletMap.defaultProps = {
  points: [],
  center: {
    latitude: 45,
    longitude: -45,
  },
  zoom: 3,
  circleRadius: 2500,
};

LeafletMap.propTypes = {
  translate: PropTypes.func.isRequired,
  circleRadius: PropTypes.number,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      sessionKey: PropTypes.any.isRequired,
      lng: PropTypes.number.isRequired,
      lat: PropTypes.number.isRequired,
    }),
  ).isRequired,

  center: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }),
  zoom: PropTypes.number.isRequired,
  timezone: PropTypes.string,
  consentlessTrackingStatus: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const websiteInfo = getWebsite(state);
  return {
    timezone: getWebsiteTimezone(state),
    consentlessTrackingStatus: websiteInfo.consentlessTracking,
  };
};
LeafletMap.contextType = LocalizationContext;
export default connect(mapStateToProps)(withTranslate(LeafletMap));
