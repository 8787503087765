import '@va/styles/tailwind-base.css';
import '@va/styles/tailwind-components.css';
import '@va/styles/tailwind-utilities.css';
import { createStore } from '@va/store';
import { PropsWithChildren, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import rootReducer from './reducers';
import rootSaga from './sagas';
import Routes from './router/routes';

import { nonTranslatableText } from '@va/constants';
import { setIgnoreVisitsLink } from '@va/dashboard/actions/app';
import { setCustomizationOptions, setLocale, setWindowWidth } from '@va/dashboard/actions/ui';
import { ChatwootWidget, NotificationV2 } from '@va/dashboard/components';
import { DashboardGlobalFiltersConfigProvider } from '@va/dashboard/shared/filters';
import { Config as BaseConfig } from '@va/http-client';
import { getTranslationsUrl, TranslationsProvider, useLocalizationContext } from '@va/localization';
import { ConfirmationDialogContextProvider } from '@va/shared/util-confirmation';
import { getAgencyUi, initialize, setPaddleCallbackEvent } from '@va/standalone/shared/actions';
import { storageItems } from '@va/standalone/shared/constants';
import { isUserLoggedInWithCustomUrl } from '@va/standalone/shared/helpers';
import { appHistory } from '@va/standalone/shared/router';
import { LoaderBar } from '@va/ui/design-system';
import { ErrorBoundary } from '@va/util/components';
import { debounce, handleAAASQueryParams, LocalStorage } from '@va/util/helpers';
import { DashboardAppWorker } from '@va/util/misc';
import Config from 'Config';
import { SkeletonTheme } from 'react-loading-skeleton';
import { SWRConfig } from 'swr';

const Paddle = window.Paddle;

// VISA-5819 - internal functionality for the Marketing team
handleAAASQueryParams();

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const getAuthenticateHeaders = (store: any) => () => {
  const token = LocalStorage.getItem(storageItems.loginToken);
  const headers = {} as Record<string, unknown>;

  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  return headers;
};

const store = createStore(rootReducer, rootSaga, null);
BaseConfig.setAuthProvider(getAuthenticateHeaders(store) as any);
BaseConfig.setApiBaseUrl(Config.apiUrl);
window.addEventListener(
  'resize',
  debounce(() => {
    store.dispatch(setWindowWidth(window.innerWidth));
  }, 150),
);
store.dispatch(
  setCustomizationOptions({
    providerName: nonTranslatableText.appName,
    supportCenterDomain: 'https://www.twipla.com',
    chatwootToken: Config.chatwootToken,
  }),
);
store.dispatch(initialize());
// Needed for wix websites claimed in standalone.
store.dispatch(setIgnoreVisitsLink(Config.wixIgnoreVisitsUrl));
store.dispatch(setWindowWidth(window.innerWidth));

if (isUserLoggedInWithCustomUrl()) {
  store.dispatch(getAgencyUi());
}

const fetchTranslationsFn = async (locale = 'en') => {
  const response = await fetch(getTranslationsUrl(locale));

  const responseText = await response.text();

  return JSON.parse(responseText.replace(/\\n/g, '<br/>'));
};

const StandaloneApp = () => {
  //TODO Move Paddle.setup to UpgradePlan component when deleting old checkout code
  useEffect(() => {
    Paddle.Setup({
      vendor: Config.paddleVendorId,
      eventCallback: (data: any) => {
        store.dispatch(setPaddleCallbackEvent(data));
      },
    });
    Config && Config.paddleSandbox && Paddle.Environment.set('sandbox');
  }, []);

  useEffect(() => {
    //call run method on worker instance to track all visits on visa app
    DashboardAppWorker.run();
  }, []);

  return (
    <Providers>
      <App />
    </Providers>
  );
};

export default StandaloneApp;

const Providers: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <SWRConfig value={{ revalidateIfStale: false, revalidateOnFocus: false }}>
      <SkeletonTheme baseColor='#F9F9F9' highlightColor='#EEEEEE'>
        <TranslationsProvider
          onLocaleChange={(locale) => {
            store.dispatch(setLocale(locale));
          }}
          fetchTranslations={fetchTranslationsFn}
        >
          <Provider store={store}>
            <ConfirmationDialogContextProvider>
              <DashboardGlobalFiltersConfigProvider>{children}</DashboardGlobalFiltersConfigProvider>
            </ConfirmationDialogContextProvider>
          </Provider>
        </TranslationsProvider>
      </SkeletonTheme>
    </SWRConfig>
  );
};

const App = () => {
  const { isLoading: translationsLoading } = useLocalizationContext();
  if (translationsLoading) return <LoaderBar />;

  return (
    <Router history={appHistory}>
      <ErrorBoundary>
        <ChatwootWidget />
        <NotificationV2 />
        <Routes />
      </ErrorBoundary>
    </Router>
  );
};
