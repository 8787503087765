export enum paragraphSizes {
  extraTiny = 'extraTiny',
  tiny = 'tiny',
  normal = 'normal',
  large = 'large',
}

const sizeToClassMap = {
  [paragraphSizes.extraTiny]: 'text-xxs leading-15px',
  [paragraphSizes.tiny]: 'text-xs leading-18',
  [paragraphSizes.normal]: 'text-15 leading-24 ',
  [paragraphSizes.large]: 'text-18 leading-24 tracking',
};

export const paragraphSizeClassNames = (size: paragraphSizes) => sizeToClassMap[size];
