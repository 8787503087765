import classNames from 'classnames';

import { LockClosed, StatusIcon } from '@va/icons';
import { fontWeights, IconWithBackground, Paragraph } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';

type BadgeProps = {
  message: string;
  actionText: string;
  color?: 'warning' | 'danger';
  onActionClick: () => void;
  isActionEnabled?: boolean;
  actionTooltip?: string;
};

const colors = {
  warning: {
    icon: '#B78430',
    statusIcon: '#F66F1E',
  },
  danger: {
    icon: '#FA3B19',
    statusIcon: undefined,
  },
};

export const PreHeaderBadge = ({
  actionText,
  color = 'warning',
  message,
  onActionClick,
  isActionEnabled = true,
  actionTooltip,
}: BadgeProps) => {
  return (
    <div
      className={classNames('w-full order-second rounded-full flex justify-between items-center p-6px pr-18px', {
        'bg-yellow-golden': color === 'warning',
        'text-gray-charcoal': color === 'warning',
        'bg-red-pure': color === 'danger',
        'text-white': color === 'danger',
      })}
    >
      <div className='flex items-center gap-15px'>
        <IconWithBackground
          className='bg-white-snow !w-9 !h-9 relative'
          icon={() => (
            <>
              <LockClosed color={colors[color].icon} />
              <StatusIcon color={colors[color].statusIcon} className='absolute -right-2' />
            </>
          )}
        />
        <Paragraph className='!font-medium'>
          {message}
          &nbsp;
        </Paragraph>
      </div>
      <TooltipWrapper content={actionTooltip} disabled={!actionTooltip}>
        <Paragraph
          weight={fontWeights.semibold}
          className={classNames('underline', {
            'cursor-not-allowed': !isActionEnabled,
            'cursor-pointer': isActionEnabled,
          })}
          onClick={() => isActionEnabled && onActionClick()}
        >
          {actionText} &rarr;
        </Paragraph>
      </TooltipWrapper>
    </div>
  );
};
