import { UpgradeBadge } from '@va/dashboard/components';
import { LimitReachedBadge } from '../misc/PreHeaderBadges';

export const LayoutPreHeader = () => {
  return (
    <div className='flex flex-col pt-18px pl-18px pb-6px pr-6px gap-3 order-second'>
      <LimitReachedBadge />
      <UpgradeBadge />
    </div>
  );
};
