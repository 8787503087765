import { CONSENT_FLAG, tabNames } from '@va/constants';
import { setActiveTab } from '@va/dashboard/actions/ui';
import { ConsentSettingsItem } from '@va/dashboard/feature/consent';
import { PageContent } from '@va/dashboard/shared/ui-layout';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import PropTypes from 'prop-types';
import { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';

class ConsentPage extends PureComponent {
  componentDidMount() {
    this.props.setActiveTab(tabNames.consent);
  }

  render() {
    return (
      <Fragment>
        <PageContent className='account-tab'>
          <ConsentSettingsItem priorConsent={CONSENT_FLAG.YOUTUBE} />
        </PageContent>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  setActiveTab: setActiveTab,
};

ConsentPage.propTypes = {
  // connect
  setActiveTab: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(withTranslate(ConsentPage));
