import { mobileOS } from '@va/constants';
import { getMobileOperatingSystem, isInWebApp, logVersion, setDefaultTheme } from '@va/util/helpers';
import { createRoot } from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
import App from './app/app';
import './index.css';
import { registerOfflineWorker, unregisterAndClearCache } from './registerServiceWorker';

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//     collapseGroups: true
//   });
// }
logVersion();
setDefaultTheme();

const root = createRoot(document.getElementById('root')!);
root.render(<App />);

const os = getMobileOperatingSystem();
if (isInWebApp() && (os === mobileOS.android || os === mobileOS.ios)) {
  registerOfflineWorker();
} else {
  unregisterAndClearCache();
}
