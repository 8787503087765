import Immutable from 'immutable';
import { createSelector } from 'reselect';

export const getLatestVisitorsMap = createSelector(
  [
    (state) => state.getIn(['core', 'api', 'latestVisitorsMap'], Immutable.Map()),
    (state) => state.getIn(['core', 'api', 'getVisitor'], Immutable.Map()),
  ],
  (latestVisitorsMap, visitor) => {
    let rawData = latestVisitorsMap.get('items', Immutable.List());
    return {
      inProgress: latestVisitorsMap.get('inProgress', false),
      items: rawData.toJS(),
      visitor: {
        inProgress: visitor.get('inProgress', false),
        information: visitor.get('information', Immutable.Map()).toJS(),
      },
    };
  },
);
