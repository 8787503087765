import { tabNames, websiteCreationSteps, websiteFlagKeys } from '@va/constants';
import { updateWebsiteFlag } from '@va/dashboard/actions/api';
import { DataPrivacyStep } from '@va/dashboard/feature/privacy-center';
import { useActiveTab } from '@va/dashboard/util-hooks';
import { BackIconRounded } from '@va/icons';
import { useTranslate } from '@va/localization';
import { InstallTrackingCodeStep } from '@va/standalone/core/shared/onboarding';
import { setWebsiteCreationStep } from '@va/standalone/shared/actions';
import { getWebsiteCreationStep } from '@va/standalone/shared/selectors';
import { Button, Heading5, MultiStepWizard, Paragraph, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import AddWebsiteUrlStep from './AddWebsiteUrlStep';
import ChooseWebsitePlatformStep from './ChooseWebsitePlatformStep';

export const AddWebsitePage = () => {
  const [stepErrors, setStepErrors] = useState<Record<number, boolean>>({});

  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const activeStep = useSelector(getWebsiteCreationStep);

  const changeActiveStep = useCallback(
    (step: number) => {
      dispatch(setWebsiteCreationStep(step));
    },
    [dispatch],
  );

  useActiveTab(tabNames.addWebsite);

  const onStepChange = useCallback(
    (step: number) => {
      if (activeStep === websiteCreationSteps.websiteUrl && step === websiteCreationSteps.choosePlatform) {
        changeActiveStep(step);
      }

      if (activeStep === websiteCreationSteps.trackingCodeInstallation && step === websiteCreationSteps.privacyLevels) {
        changeActiveStep(step);
      }
    },
    [activeStep, changeActiveStep],
  );

  const isBackButtonVisible = useMemo(() => websiteCreationSteps.privacyLevels !== activeStep, [activeStep]);

  const onBackClick = useCallback(() => {
    if (activeStep === websiteCreationSteps.choosePlatform) {
      history.push('/my-account/websites');
      return;
    }
    changeActiveStep(activeStep - 1);
  }, [activeStep, changeActiveStep, history]);

  const steps = useMemo(() => {
    return [
      {
        label: translate('standalone.websiteCreation.step1.title'),
        component: (
          <ChooseWebsitePlatformStep
            setActiveStep={() => {
              changeActiveStep(websiteCreationSteps.websiteUrl);
            }}
          />
        ),
      },
      {
        label: translate('standalone.websiteCreation.step2.title'),
        component: (
          <AddWebsiteUrlStep
            onSuccess={() => {
              changeActiveStep(websiteCreationSteps.privacyLevels);
            }}
          />
        ),
      },
      {
        label: translate('standalone.websiteCreation.step3.title'),
        component: (
          <DataPrivacyStep
            onSuccess={() => {
              dispatch(updateWebsiteFlag(websiteFlagKeys.CREATION_PROCESS_COMPLETED, true));
              changeActiveStep(websiteCreationSteps.trackingCodeInstallation);
            }}
          />
        ),
      },
      {
        label: translate('standalone.websiteCreation.step4.title'),
        hasError: stepErrors[websiteCreationSteps.trackingCodeInstallation],
        component: (
          <InstallTrackingCodeStep
            onError={() => {
              setStepErrors({ [websiteCreationSteps.trackingCodeInstallation]: true });
            }}
            onClickRetryInstallation={() => {
              setStepErrors({});
            }}
          />
        ),
      },
    ];
  }, [changeActiveStep, dispatch, stepErrors, translate]);

  useEffect(() => {
    return () => {
      changeActiveStep(websiteCreationSteps.choosePlatform);
    };
  }, [changeActiveStep]);

  return (
    <div className='min-h-[90vh] flex flex-col'>
      <div className='grow'>
        <div className='px-3 h-[82.5px] flex justify-center items-center w-full border-b-[1.5px] border-gray-mercury'>
          <Button
            className={classNames({
              invisible: !isBackButtonVisible,
            })}
            onClick={onBackClick}
            color='tertiary'
            icon={(_, color) => <BackIconRounded color={color} />}
          />
          <Heading5 className='!text-15 grow !font-525 text-center sm-initial:!text-21 sm-initial:!font-medium'>
            {translate('standalone.websiteCreation.pageTitle')}
          </Heading5>
        </div>
        <div className='mt-6 px-3 md:mt-[72px]'>
          <MultiStepWizard onStepChange={onStepChange} activeStep={activeStep} steps={steps} />
        </div>
      </div>
      <div>
        <Paragraph className='text-center p-3 text-gray-devil' size={paragraphSizes.tiny}>
          {translate('app.copyrightText')}
        </Paragraph>
      </div>
    </div>
  );
};
