import { TEST_IDS, tabNames } from '@va/constants';
import { setTabProperty } from '@va/dashboard/actions/ui';
import { NavMilestone } from '@va/icons';
import { useTranslate } from '@va/localization';
import { TooltipWrapper } from '@va/ui/tooltips';
import { Flag, LocationType } from '@va/util/components';
import { isValidCountryCode } from '@va/util/helpers';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

const PROPERTY_MAP_CENTER = 'mapCenter';

export const CountryLocationIcon: React.FC<{ location: LocationType; countryCode: string }> = ({
  location,
  countryCode,
}) => {
  const history = useHistory();
  const translate = useTranslate();
  const { onClickLocation, hasLocation } = useClickLocation(location);
  const [isFlagHovered, setFlagHovered] = useState(false);

  const isCountryCodeValid = isValidCountryCode(countryCode);

  const ICON_WRAPPER_CLASS =
    'h-12 w-12 rounded-full bg-white-snow group-hover:bg-white flex justify-center items-center';

  const icon = () => {
    if (isFlagHovered && hasLocation) {
      return (
        <TooltipWrapper
          content={
            isCountryCodeValid
              ? translate('panels.latestVisitors.locationPinTooltip')
              : translate('panels.latestVisitors.noCountryInfo')
          }
        >
          <button
            data-testid={TEST_IDS.generic.links.location}
            onClick={() => isCountryCodeValid && [onClickLocation(location), history.push('/visitors/map')]}
            className={ICON_WRAPPER_CLASS}
          >
            <NavMilestone />
          </button>
        </TooltipWrapper>
      );
    }

    return (
      <div data-testid={TEST_IDS.generic.links.location} className={ICON_WRAPPER_CLASS}>
        <Flag countryCode={countryCode} size='1x1' className='w-18 h-18 rounded-full' />
      </div>
    );
  };

  return (
    <div
      className='cursor-pointer'
      onMouseEnter={() => setFlagHovered(true)}
      onMouseLeave={() => setFlagHovered(false)}
    >
      {icon()}
    </div>
  );
};

export const useClickLocation = (location: LocationType) => {
  const hasLocation = Number.isFinite(location.lat) && Number.isFinite(location.lng);
  const dispatch = useDispatch();
  const onClickLocation = ({ lat, lng }: LocationType) => {
    const location = { latitude: lat, longitude: lng };
    dispatch(setTabProperty(tabNames.latestVisitorsMap, PROPERTY_MAP_CENTER, location));
  };
  return { hasLocation, onClickLocation };
};
