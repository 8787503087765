import { TEST_IDS } from '@va/constants';
import { Clock } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { TimeInterval } from '@va/types/time-series';
import { CloseButton, useTooltipContext } from '@va/ui/tooltips';
import { appHasDarkTheme, getLocalizedDate, getPercentageChange, getTimestampFromIso } from '@va/util/helpers';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import { useMemo } from 'react';
import { Paragraph, fontWeights, paragraphSizes } from '../../typography';
import { TooltipGridRow } from './TooltipGridRow';
import { TooltipSubtitle } from './TooltipSubtitle';
import { TooltipTitle } from './TooltipTitle';

function getPercentageChanges(
  currentValues: { [key: string]: { value: number } },
  previousValues: { [key: string]: { value: number } },
) {
  const percentageChangeValues: { [key: string]: number | '-' } = {};
  for (const k of Object.keys(currentValues)) {
    if (!previousValues) {
      percentageChangeValues[k] = '-';
      continue;
    }

    if (!previousValues[k] || previousValues[k].value === 0) {
      percentageChangeValues[k] = '-';
      continue;
    }

    percentageChangeValues[k] = getPercentageChange(previousValues[k].value, currentValues[k].value);
  }
  return percentageChangeValues;
}

export type RawTooltipRow = {
  value: number;
  color: string;
  linkTo?: string;
};
export type ChartTooltipValues = Record<string, RawTooltipRow>;

export type ChartTooltipProps = {
  currentValues: ChartTooltipValues;
  previousValues: ChartTooltipValues;
  title?: string;
  subtitle?: string;
  currentPeriod: TimeInterval;
  previousPeriod: TimeInterval;
  showPrevious: boolean;
  reversePercentageColors?: boolean;
  closeTooltip?: () => void;
  valueTransformer?: (val: any) => any;
};

export const ChartTooltipComponent = ({
  currentValues,
  previousValues,
  subtitle,
  title,
  currentPeriod,
  previousPeriod,
  showPrevious,
  reversePercentageColors,
  closeTooltip,
  valueTransformer,
}: ChartTooltipProps) => {
  const { isMobile } = useWindowDimensions();
  const percentageChanges = getPercentageChanges(currentValues, previousValues);
  const lineKeys = Object.keys(currentValues);
  const isDarkThemeApplied = appHasDarkTheme();
  const { context } = useTooltipContext();

  const gridStyle = useMemo(() => {
    if (!showPrevious) return 'auto repeat(2, minmax(0, 100px))';
    if (isMobile) return '125px minmax(60px, 1fr) repeat(2, minmax(0px, 60px))';
    return '135px minmax(120px, 1fr) repeat(2, minmax(0, 100px))';
  }, [isMobile, showPrevious]);

  return (
    <div
      data-testid={TEST_IDS.generic.chart.tooltipContainer}
      className={classNames('text-12 sm-initial:text-15 pb-6px', {
        'text-white': !isDarkThemeApplied,
      })}
    >
      <div className='flex justify-between gap-2'>
        <TooltipTitle title={title} />
        <CloseButton
          onClose={() => [context.onOpenChange(false), closeTooltip?.()]}
          className={classNames({
            'top-2': !title,
          })}
        />
      </div>
      <div
        className={classNames('pt-2 grid gap-x-15px gap-y-3 sm-initial:items-center', {
          'grid-cols-3': !showPrevious,
          'grid-cols-4': showPrevious,
        })}
        style={{
          gridTemplateColumns: gridStyle,
        }}
      >
        {/* Header */}
        <TooltipSubtitle subtitle={subtitle} elementsCount={lineKeys.length} />
        <PeriodHeadline
          className={classNames({
            'col-span-2': !showPrevious,
          })}
          period={currentPeriod}
        />
        {showPrevious && <PeriodHeadline className='col-span-2' isPreviousInterval={true} period={previousPeriod} />}
        {lineKeys.map((key) => (
          <TooltipGridRow
            data-testid={TEST_IDS.helpers.createListItemId(key)}
            key={key}
            rowKey={key}
            showPrevious={showPrevious}
            currentValue={currentValues[key]}
            previousValue={previousValues[key] || { value: '-' }}
            percentageChange={percentageChanges[key]}
            reversePercentageColors={reversePercentageColors}
            valueTransformer={valueTransformer}
          />
        ))}
      </div>
    </div>
  );
};

function PeriodHeadline({
  period,
  className,
  isPreviousInterval,
}: {
  period: TimeInterval;
  className?: string;
  isPreviousInterval?: boolean;
}) {
  const isDarkThemeApplied = appHasDarkTheme();
  const translate = useTranslate();
  const { locale } = useLocale();
  return (
    <div
      className={classNames(
        'flex flex-col sm-initial:flex-row sm-initial:items-center gap-9px sm-initial:flex-nowrap',
        className,
        {
          'text-white-83': !isDarkThemeApplied,
        },
      )}
    >
      <Clock strokeColor={isDarkThemeApplied ? '#000000' : '#fff'} strokeOpacity={0.83} className='shrink-0' />
      <div className='flex flex-col'>
        {isPreviousInterval && (
          <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
            {translate('tooltips.comparedToPreviousPeriod')}
          </Paragraph>
        )}
        {period.from === period.until ? (
          <span className='text-12'>
            {getLocalizedDate(getTimestampFromIso(period.from), locale, { weekday: 'short' })}
          </span>
        ) : (
          <>
            <span className='text-12'>
              {getLocalizedDate(getTimestampFromIso(period.from), locale, { weekday: 'short' })}
            </span>
            <span className='text-12'>{`- ${getLocalizedDate(getTimestampFromIso(period.until), locale, {
              weekday: 'short',
            })}`}</span>
          </>
        )}
      </div>
    </div>
  );
}
