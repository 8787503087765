import { FilterIcon, MinusIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { FilterSelectDropdown } from './FilterSelectDropdown';
import './applied-filter-block.scss';
import { FilterOperators } from './filter-types';
import { useFiltersContext } from './filters-context';

type FilterBlockProps = {
  filterId: string;
  fieldName: string;
  operator?: FilterOperators;
  input: React.ReactNode;
  operatorOptions?: FilterOperators[];
};

export const FilterBlock = ({ fieldName, operator, input, operatorOptions, filterId }: FilterBlockProps) => {
  const { deleteAppliedFilter } = useFiltersContext();
  const translate = useTranslate();

  return (
    <div className='applied-filter-block h-12 rounded-12 p-3px inline-block bg-white-snow hover:bg-gray-concrete overflow-hidden'>
      <div className='flex items-center gap-[1.5px] h-full'>
        <div className='field-name-wrapper'>
          <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
            {fieldName}
          </Paragraph>
        </div>
        {operator && (
          <OperatorBlock filterId={filterId} operatorOptions={operatorOptions ?? []} selectedOperator={operator} />
        )}
        {input}
        <TooltipWrapper placement='top' content={translate('filters.removeThisFilter')}>
          <button
            className='h-full px-3 active:bg-gray-mercury rounded-12'
            onClick={() => {
              deleteAppliedFilter(filterId);
            }}
          >
            <MinusIcon color='#3C3C3C' />
          </button>
        </TooltipWrapper>
      </div>
    </div>
  );
};

const OperatorBlock = ({
  operatorOptions,
  selectedOperator,
  filterId,
}: {
  operatorOptions: FilterOperators[];
  selectedOperator: FilterOperators;
  filterId: string;
}) => {
  const translate = useTranslate();
  const { updateAppliedFilter } = useFiltersContext();

  if (operatorOptions.length === 0) {
    return (
      <Paragraph className='operator-wrapper uppercase' size={paragraphSizes.tiny} weight={fontWeights.medium}>
        {translate(`filters.operators.${selectedOperator}`)}
      </Paragraph>
    );
  }

  return (
    <FilterSelectDropdown
      triggerClassName='operator-wrapper'
      options={operatorOptions.map((operator) => ({
        label: translate(`filters.operators.${operator}`),
        value: operator,
        icon: <FilterIcon className='w-4 h-4' color='#3C3C3C' />,
      }))}
      showSearchInput={false}
      showArrowIcon={false}
      selectedOptions={{ [selectedOperator]: true }}
      onChange={(option) => {
        updateAppliedFilter(filterId, { operator: option.value as FilterOperators });
      }}
    >
      <Paragraph className='uppercase' size={paragraphSizes.tiny} weight={fontWeights.medium}>
        {translate(`filters.operators.${selectedOperator}`)}
      </Paragraph>
    </FilterSelectDropdown>
  );
};
