import { LAYOUT_PORTAL_ID } from '@va/constants';
import { PageHeaderTooltip } from '@va/dashboard/ui/components';
import { NestedNavigationLink, NestedPageNavigation, NestedPageNavigationProps } from '@va/ui/design-system';
import React, { useCallback } from 'react';

export const VisaNestedPageNavigation: React.FC<Omit<NestedPageNavigationProps, 'containerElementId'>> = (props) => {
  const renderCustomLinkComponent = useCallback(
    ({
      url,
      label,
      isActive,
      className,
      onRouteChange,
      icon,
      tooltip,
      contextMenu,
      contextMenuElement,
      ...rest
    }: any) => {
      return (
        <PageHeaderTooltip
          title={label}
          trigger='click'
          disabled={!isActive}
          baseKey={tooltip ?? ''}
          render={(shouldShow: boolean) => (
            <div>
              <NestedNavigationLink
                data-testid={rest['data-testid']}
                onRouteChange={onRouteChange}
                label={label}
                isActive={isActive}
                url={url}
                icon={icon}
                contextMenu={contextMenu}
                className={className}
                hasTooltip={shouldShow}
                contextMenuElement={contextMenuElement}
              />
            </div>
          )}
          key={url}
        />
      );
    },
    [],
  );

  return (
    <NestedPageNavigation
      {...props}
      containerElementId={LAYOUT_PORTAL_ID}
      renderCustomLinkComponent={renderCustomLinkComponent}
    />
  );
};
