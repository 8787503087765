import { useTranslate } from '@va/localization';
import { useWindowDimensions } from '@va/util/hooks';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import LitepieDatepicker from './LitepieDatepicker';

const DatePicker = (props) => {
  const {
    startDate,
    endDate,
    locale,
    minDate,
    maxDate,
    onPeriodChange,
    tooltipContent,
    shouldRefreshDatePicker,
    placement,
  } = props;

  const translate = useTranslate();
  const { isMobile, height } = useWindowDimensions();
  const initialValues = [dayjs(startDate).tz(window.timezone), dayjs(endDate).tz(window.timezone).endOf('day')];

  return (
    <div className='h-12 overflow-hidden'>
      <LitepieDatepicker
        tooltipContent={tooltipContent}
        i18n={locale}
        placement={placement}
        initialValues={initialValues}
        updatedValues={onPeriodChange}
        minDate={minDate}
        maxDate={maxDate}
        shouldRefreshDatePicker={shouldRefreshDatePicker}
        translate={translate}
        isMobile={isMobile}
        windowHeight={height}
      />
    </div>
  );
};

DatePicker.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  locale: PropTypes.string,
  minDate: PropTypes.instanceOf(dayjs),
  maxDate: PropTypes.instanceOf(dayjs),
  tooltipContent: PropTypes.string,
  shouldRefreshDatePicker: PropTypes.bool,
  onPeriodChange: PropTypes.func.isRequired,
  placement: PropTypes.string,
};

export default DatePicker;
