import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@va/aaas/shared/types';
import { Website } from '@visitor-analytics/3as-sdk';

export const selectAllowUpgrade = (state: RootState) => state.get('app').allowUpgrade;

export const selectIntpWebsite = (state: RootState) => (state.getIn(['app'])?.intpWebsite as Website) ?? {};

export const getIntpUpgradeMessage = createSelector(selectIntpWebsite, (website) => {
  const { intpWebsiteId, packageName, packageId, intpCustomerId, inTrial, expiresAt, billingInterval } = website;
  return {
    intpWebsiteId,
    packageName,
    packageId,
    intpCustomerId,
    inTrial,
    expiresAt,
    billingInterval,
  };
});
