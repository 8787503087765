// TODO Fix circular dependency
// eslint-disable-next-line @nx/enforce-module-boundaries
import { addTransparencyToHexColor } from '@va/util/helpers';
import './circular-progress.scss';

type CircularProgressProps = {
  radius?: number;
  stroke?: number;
  progress: number;
  strokeColor?: string;
  gradientColor?: string;
};

export function CircularProgress({
  radius = 18,
  stroke = 6,
  progress = 50,
  strokeColor = '#F0F0F0',
  gradientColor = window.PRIMARY_COLOR,
}: CircularProgressProps) {
  const normalizedRadius = radius - stroke;
  const circumference = normalizedRadius * 2 * Math.PI;
  const size = radius * 2;
  const strokeDashoffset = circumference - (getValidProgressValue(progress) / 100) * circumference;

  return (
    <svg id='circular-progress' className='progress-ring' width={size} height={size} color={gradientColor}>
      <defs>
        <radialGradient id={`gradient1-${gradientColor}`} cx='100%' cy='100%' r='100%' fx='60%' fy='75%'>
          <stop offset='0%' style={{ stopColor: addTransparencyToHexColor(gradientColor, 1) }} />
          <stop offset='50%' style={{ stopColor: addTransparencyToHexColor(gradientColor, 0.8) }} />
          <stop offset='100%' style={{ stopColor: addTransparencyToHexColor(gradientColor, 0.6) }} />
        </radialGradient>
      </defs>
      <g>
        <circle
          stroke={strokeColor}
          fill='transparent'
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          strokeLinecap='round'
          strokeWidth={stroke - 0.6}
        />
        <circle
          strokeWidth={stroke}
          className='progress-ring-circle'
          stroke={`url(#gradient1-${gradientColor})`}
          strokeDashoffset={strokeDashoffset}
          strokeDasharray={`${circumference} ${circumference}`}
          fill='transparent'
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </g>
    </svg>
  );
}

function getValidProgressValue(progress: number) {
  if (progress > 100) return 100;
  if (progress < 0) return 0;
  return progress;
}
