import { Button } from '@va/ui/design-system';
import PropTypes from 'prop-types';
import { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

export default class CopyToClipboardBtn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCopied: false,
    };

    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  copyToClipboard() {
    this.setState({ isCopied: true });
    setTimeout(() => {
      this.setState({ isCopied: false });
    }, 2000);
  }

  render() {
    return (
      <div className={this.props.containerClassName}>
        <CopyToClipboard text={this.props.content} onCopy={this.copyToClipboard}>
          <Button
            className={this.props.btnClass}
            text={this.state.isCopied && this.props.onCopyText ? this.props.onCopyText : this.props.btnText}
          />
        </CopyToClipboard>
      </div>
    );
  }
}

CopyToClipboardBtn.propTypes = {
  containerClassName: PropTypes.string,
  content: PropTypes.string.isRequired,
  colorTheme: PropTypes.oneOf(['theme-green', 'theme-red', 'theme-black']),
  btnText: PropTypes.node,
  btnClass: PropTypes.string,
  onCopyText: PropTypes.node,
};

CopyToClipboardBtn.defaultProps = {
  colorTheme: 'theme-green',
  onCopyText: false,
  btnClass: '',
};
