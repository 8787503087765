import { useTranslate } from '@va/localization';
import { TextInput } from '@va/ui/design-system';
import { isNil } from 'lodash';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { filterValidationKeys } from '../../constants';
import { FilterInputComponentRefType } from '../../filter-types';
import { useFiltersContext } from '../../filters-context';
import { useFilterValidation } from '../../useFilterValidation';
import { PickOperatorBtn } from '../PickOperatorBtn';
import { useOperator } from '../useOperator';
import { IntervalFilter, IntervalFilterInputProps } from './IntervalFilter';

export const IntervalFilterInput = forwardRef<
  FilterInputComponentRefType,
  { filter: IntervalFilter } & IntervalFilterInputProps
>(({ filter, minLabel, maxLabel }, ref) => {
  const [minValue, setMinValue] = useState<number | undefined>(undefined);
  const [maxValue, setMaxValue] = useState<number | undefined>(undefined);

  const { operator, setOperator } = useOperator(filter.operator);

  const { applySingleNewFilter } = useFiltersContext();

  const translate = useTranslate();

  const onSubmit = useCallback(() => {
    const clone = Object.assign({}, filter);
    clone.values = [{ min: minValue ?? 0, max: maxValue ?? 0 }];
    clone.operator = operator;
    applySingleNewFilter(clone);
  }, [applySingleNewFilter, filter, maxValue, minValue, operator]);

  const { validate, handleSubmit, error } = useFilterValidation<
    { min: number | undefined; max: number | undefined },
    { min: string; max: string }
  >({
    onSubmit,
    value: { min: minValue, max: maxValue },
    validationFunc: (val) => {
      const err = { min: '', max: '' };
      if (isNil(val.max)) {
        err.max = filterValidationKeys.required;
      }

      if (isNil(val.min)) {
        err.min = filterValidationKeys.required;
      }

      if (!err.min || !err.max) {
        return;
      }

      return err;
    },
  });

  useImperativeHandle(ref, () => ({ submit: handleSubmit }));

  return (
    <div className='space-y-1'>
      <PickOperatorBtn
        onOperatorChange={setOperator}
        operatorOptions={filter.operatorOptions}
        selectedOperator={operator}
      />
      <TextInput
        type='number'
        value={minValue}
        label={minLabel}
        onChange={(e) => {
          const val = Number(e.target.value);
          setMinValue(val);
          validate({ min: val, max: maxValue });
        }}
        clearField={() => {
          setMinValue(undefined);
        }}
        error={translate(error?.min ?? '')}
      />
      <TextInput
        type='number'
        value={maxValue}
        label={maxLabel}
        error={translate(error?.max ?? '')}
        onChange={(e) => {
          const val = Number(e.target.value);
          setMaxValue(val);
          validate({ min: minValue, max: val });
        }}
        clearField={() => {
          setMaxValue(undefined);
        }}
      />
    </div>
  );
});
