import { FEATURE_TOUR_IDS } from '@va/constants';
import { GoToWebsiteButton } from '@va/dashboard/components';
import { isTabletDevice } from '@va/dashboard/selectors/ui';
import { useTranslate } from '@va/localization';
import { setActiveWebsite } from '@va/standalone/shared/actions';
import { isAccountSettingsPageSelected } from '@va/standalone/shared/helpers';
import { getActiveWebsiteId, getActiveWebsiteV2 } from '@va/standalone/shared/selectors';
import { Button } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import { getUrlWithoutProtocolAndTrailingSlash } from '@va/util/helpers';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import CheckTrackingCodeStatusV2 from './CheckTrackingCodeStatusV2';
import WebsiteSelectorDropdown from './WebsiteSelectorDropdown';
import './WebsiteSelectorV2.scss';

export const WebsiteSelectorV2 = () => {
  const dispatch = useDispatch();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [totalWebsites, setTotalWebsites] = useState(0);

  const activeWebsite = useSelector(getActiveWebsiteV2);
  const { pathname } = useLocation();

  const url = useMemo(() => getUrlWithoutProtocolAndTrailingSlash(activeWebsite?.url ?? ''), [activeWebsite?.url]);

  const translate = useTranslate();
  const isTablet = useSelector(isTabletDevice);

  const activeWebsiteId = useSelector(getActiveWebsiteId);

  const closeDropdown = useCallback(() => {
    setIsDropdownVisible(false);
  }, []);

  const initializeTotalWebsites = useCallback((total: number) => {
    setTotalWebsites(total);
  }, []);

  const isAccountSettingsPage = isAccountSettingsPageSelected(pathname);

  const websitesNumberText = useMemo(
    () => translate('dropdown.manageWebsites', { count: totalWebsites }),
    [totalWebsites, translate],
  );

  return (
    <div className='relative flex items-center gap-3'>
      <TooltipWrapper
        placement={isTablet ? 'bottom' : 'bottom-start'}
        interactive={true}
        arrow={false}
        outsidePress={() => {
          if (window.disableClickOutside) return false;
          return true;
        }}
        content={
          <WebsiteSelectorDropdown
            initializeTotalWebsites={initializeTotalWebsites}
            closeDropdown={closeDropdown}
            activeWebsiteId={activeWebsiteId}
            onWebsiteSelect={(websiteId, _) => dispatch(setActiveWebsite(websiteId, true))}
          />
        }
        tooltipClassNames='w-350px'
        trigger='click'
        useDefaultStyle={false}
        open={isDropdownVisible}
        onOpenChange={(open) => setIsDropdownVisible(open)}
      >
        <div className='flex w-full truncate'>
          <Button
            className={classNames('w-full lg:w-[300px]', { isOpen: isDropdownVisible })}
            color='tertiary'
            dataTourId={FEATURE_TOUR_IDS.websiteSelectorBtn}
            icon={() => <CheckTrackingCodeStatusV2 />}
            text={isAccountSettingsPage ? websitesNumberText : url}
            rightSideIcon={<DropdownArrow className='mr-auto ml-4' open={isDropdownVisible} />}
          />
        </div>
      </TooltipWrapper>
      {!isAccountSettingsPage && <GoToWebsiteButton />}
    </div>
  );
};
