import React, { memo, FC } from 'react';
import { IconProps } from '.';

export const AddTagIcon: FC<IconProps> = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.1887 1.81L2.2494 9.74934C1.66361 10.3351 1.66361 11.2849 2.2494 11.8707L5.50676 15.128C6.09254 15.7138 7.04229 15.7138 7.62808 15.128L7.82017 14.9359C7.41826 14.1936 7.19006 13.3434 7.19006 12.44C7.19006 9.54051 9.54057 7.19 12.4401 7.19C13.3435 7.19 14.1936 7.4182 14.936 7.82011L15.5674 7.18868V1.81H10.1887ZM16.151 8.72637L17.0674 7.81V1.81V0.309998H15.5674H9.56742L1.18874 8.68868C0.0171654 9.86025 0.0171653 11.7597 1.18874 12.9313L4.4461 16.1887C5.61767 17.3603 7.51717 17.3603 8.68874 16.1887L8.72643 16.151C9.6766 17.1018 10.9896 17.69 12.4401 17.69C15.3396 17.69 17.6901 15.3395 17.6901 12.44C17.6901 10.9896 17.1019 9.67653 16.151 8.72637ZM14.0674 4.81C14.0674 5.63842 13.3958 6.31 12.5674 6.31C11.739 6.31 11.0674 5.63842 11.0674 4.81C11.0674 3.98157 11.739 3.31 12.5674 3.31C13.3958 3.31 14.0674 3.98157 14.0674 4.81ZM16.1901 12.44C16.1901 14.5111 14.5111 16.19 12.4401 16.19C10.369 16.19 8.69006 14.5111 8.69006 12.44C8.69006 10.3689 10.369 8.69 12.4401 8.69C14.5111 8.69 16.1901 10.3689 16.1901 12.44ZM11.6901 11.69V10.19H13.1901V11.69H14.6901V13.19H13.1901V14.69H11.6901V13.19H10.1901V11.69H11.6901Z'
        fill='black'
      />
    </svg>
  );
};


