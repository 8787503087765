import classNames from 'classnames';
import React from 'react';
import { IconProps } from '.';

export const ExternalLinkIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.5 2H5C3.34315 2 2 3.34315 2 5V11C2 12.6569 3.34315 14 5 14H11C12.6569 14 14 12.6569 14 11V9.5H15.5V11C15.5 13.4853 13.4853 15.5 11 15.5H5C2.51472 15.5 0.5 13.4853 0.5 11V5C0.5 2.51472 2.51472 0.5 5 0.5H6.5V2ZM10.25 0.5H14.75H15.5V1.25V5.75H14V3.06066L7.78033 9.28033L6.71967 8.21967L12.9393 2H10.25V0.5Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};


