import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { ReportBlockTitle } from './ReportBlockTitle';
import { useReportBlockCtx } from './report-block-ctx';
import { getReportBlockPaginationId } from './report-block.helpers';

export const ReportBlockHeader = memo(() => {
  const { nodes, id } = useReportBlockCtx();

  const paginationId = useMemo(() => getReportBlockPaginationId(id), [id]);

  return (
    <div
      className={classNames('flex gap-3 justify-between flex-wrap', {
        'flex-row items-center': !nodes?.bulkActions,
        'flex-col': nodes?.bulkActions,
      })}
    >
      <ReportBlockTitle />
      <div
        className={classNames('invisible-on-screenshot flex items-center gap-18px flex-wrap', {
          'justify-end': nodes?.bulkActions,
        })}
      >
        {nodes?.bulkActions && <div className='mr-auto'>{nodes?.bulkActions}</div>}
        {nodes?.rightSide}
        {nodes?.downloadButton}
        {nodes?.compareTo}
        {nodes?.segmentsButton}
        {nodes?.filterButton}
        {nodes?.pagination ?? <div id={paginationId}></div>}
        {nodes?.dataViewButton}
        {nodes?.productSelector}
      </div>
    </div>
  );
});

export const ReportBlockSubHeader = ({ className }: { className?: string }) => {
  const { nodes } = useReportBlockCtx();
  return (
    <div className={classNames('mb-18px', className)}>
      {nodes?.segments}
      {nodes?.appliedFilters}
    </div>
  );
};
