import { createColumnHelper } from '@tanstack/react-table';
import { CompanyRevealLabel, CompanyRevealPreview, CompanyVisitItem } from '@va/dashboard/shared/visitors';
import { useLocalizationContext } from '@va/localization';
import { useSelector } from 'react-redux';
import { isCompanyRevealFeatureEnabled } from '@va/dashboard/selectors/app';
import { useMemo } from 'react';
import {
  fontWeights,
  IconWithBackground,
  ListCardItemWithIcon,
  Paragraph,
  paragraphSizes,
  ProgressBar,
} from '@va/ui/design-system';
import { LinkIcon } from '@va/icons';
import { addNumberSeparator } from '@va/util/helpers';

const columnHelper = createColumnHelper<CompanyVisitItem>();
export const useVisitsByCompanyColumns = () => {
  const { locale, translate } = useLocalizationContext();
  const showIp2Company = useSelector(isCompanyRevealFeatureEnabled);
  return useMemo(
    () => [
      columnHelper.display({
        id: 'title',
        meta: {
          useDefaultCellStyle: false,
          cellClassName: 'justify-start',
        },
        cell: ({
          row: {
            original: { companyOrgName, companyType },
          },
        }) => (
          <ListCardItemWithIcon
            icon={<IconWithBackground className='!bg-white-snow group-hover:!bg-white' icon={() => <LinkIcon />} />}
            topText={
              showIp2Company ? (
                <CompanyRevealLabel companyName={companyOrgName} companyType={companyType} />
              ) : (
                <CompanyRevealPreview />
              )
            }
          />
        ),
      }),
      columnHelper.display({
        id: 'progress',
        cell: ({
          row: {
            original: { visitsPercentage },
          },
        }) => (
          <div className='w-full'>
            <ProgressBar progress={[{ percentage: visitsPercentage * 100, color: window.PRIMARY_COLOR }]} />
          </div>
        ),
      }),
      columnHelper.display({
        id: 'percentage',
        meta: {
          useDefaultCellStyle: false,
          cellClassName: 'justify-end',
        },
        cell: ({
          row: {
            original: { visitsPercentage, visits },
          },
        }) => {
          const percentage = visitsPercentage * 100;
          return (
            <div className={'text-right'}>
              <Paragraph weight={fontWeights.medium}>
                {addNumberSeparator(percentage, locale, { style: 'percent', signDisplay: 'never' })}
              </Paragraph>
              <Paragraph weight={fontWeights.medium} size={paragraphSizes.tiny}>
                {translate('all.companyVisits.visitsCount', { count: addNumberSeparator(visits, locale) })}
              </Paragraph>
            </div>
          );
        },
      }),
    ],
    [locale, showIp2Company, translate],
  );
};
