import classNames from 'classnames';
import { CSSProperties, forwardRef, ReactNode, useCallback } from 'react';

type IconWithBackgroundType = {
  className?: string;
  type?: 'circle' | 'square';
  icon: () => ReactNode;
  style?: CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const IconWithBackground = forwardRef<HTMLDivElement, IconWithBackgroundType>(
  ({ className, type = 'circle', icon, style, onClick }, ref) => {
    const getClass = useCallback((type: string) => {
      switch (type) {
        case 'circle':
          return 'rounded-full';
        case 'square':
          return 'rounded-12';
        default:
          return '';
      }
    }, []);

    return (
      <div
        ref={ref}
        onClick={onClick}
        style={style}
        className={classNames('flex items-center shrink-0 justify-center h-12 w-12', getClass(type), className)}
      >
        {icon && icon()}
      </div>
    );
  },
);

export default IconWithBackground;
