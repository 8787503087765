import { IconProps } from '..';

export const LinuxLogo = ({ className }: IconProps) => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 24 24'
      width={18}
      height={18}
      className={className}
    >
      <path
        id='XMLID_9_'
        d='M21.1,19.6c-0.6-0.3-1.2-0.7-1.1-1.5c0-0.8-0.6-1.3-0.6-1.3s0.5-1.7,0-3.1s-2.1-3.7-3.3-5.4
    C14.9,6.5,16,4.6,14.8,2c-1.1-2.5-4-2.4-5.6-1.3s-1.1,3.8-1,5c0.1,1.3,0,2.2-0.1,2.5C8,8.6,7,9.8,6.3,10.8s-1.2,3.1-1.6,4
    c-0.5,0.9-0.1,1.6-0.1,1.6S4.3,16.5,4,17.1s-0.8,0.8-1.7,1s-0.9,0.8-0.7,1.5s0,1-0.3,1.9s1,1.1,2.3,1.3c1.3,0.2,2.7,1,3.9,1.1
    C8.6,24,9,23,9,23s1.3-0.3,2.8-0.3c1.4,0,2.8,0.3,2.8,0.3s0.3,0.6,0.7,0.9c0.5,0.3,1.5,0.3,2.2-0.4c0.7-0.7,2.5-1.6,3.5-2.2
    C21.9,20.7,21.8,19.9,21.1,19.6z M12.4,3.1c0.6,0,1.2,0.6,1.2,1.4c0,0.6-0.3,1-0.6,1.3c-0.1,0-0.2-0.1-0.3-0.1
    C12.8,5.6,13,5.3,13,4.9s-0.3-0.8-0.6-0.8s-0.6,0.4-0.6,0.8c0,0.2,0,0.3,0.1,0.4c-0.2-0.1-0.4-0.2-0.5-0.2c-0.1-0.2-0.1-0.4-0.1-0.6
    C11.2,3.7,11.7,3.1,12.4,3.1z M10.8,5.6c0.3,0,1.2,0.4,1.5,0.5s0.7,0.3,0.6,0.5c0,0.2-0.2,0.2-0.6,0.5c-0.4,0.3-1.4,0.8-1.7,0.9
    c-0.3,0-0.5-0.1-0.8-0.3C9.5,7.5,8.9,7,9,6.7c0,0,0.5-0.4,0.7-0.5C9.9,5.9,10.5,5.5,10.8,5.6z M9.4,3.3c0.5,0,0.9,0.6,0.9,1.3
    c0,0.1,0,0.3,0,0.4C10.2,5,10,5.1,9.9,5.2c0,0.1-0.1,0.2-0.2,0.2C9.8,5.2,9.8,5,9.8,4.8c0-0.4-0.3-0.7-0.6-0.6
    C8.9,4.2,8.8,4.6,8.9,5C9,5.4,9.2,5.7,9.5,5.7l0,0C9.4,5.8,9.3,5.9,9.1,6C8.8,5.8,8.5,5.3,8.5,4.7C8.5,3.9,8.9,3.3,9.4,3.3z
     M8.4,22.2c-0.1,0.5-0.7,0.9-0.7,0.9c-0.6,0.2-2.1-0.5-2.9-0.8C4.1,22,2.3,21.9,2,21.7c-0.2-0.3,0.1-0.9,0.2-1.4
    C2.3,19.7,2,19.4,2.1,19s1.3-0.4,1.7-0.7c0.4-0.3,0.5-1,0.9-1.3c0.4-0.2,1,0.5,1.3,1c0.3,0.4,1.3,2.2,1.7,2.7
    C8.2,21.1,8.5,21.7,8.4,22.2z M15,17c-0.1,0.5-0.1,2.4-0.1,2.4s-1.2,1.6-3,1.9s-2.7,0.1-2.7,0.1l-1-1.2c0,0,0.8-0.1,0.7-0.9
    s-2.4-1.9-2.8-2.8c-0.4-1-0.1-2.6,0.5-3.4c0.5-0.8,0.9-2.6,1.4-3.2C8.5,9.2,8.9,8,8.7,7.4c0,0,1.1,1.3,1.9,1.1
    c0.8-0.2,2.5-1.5,2.8-1.3s2.5,5.2,2.7,6.7c0.2,1.6-0.2,2.8-0.2,2.8S15.1,16.5,15,17z M20.8,20.5c-0.4,0.3-2.3,1.1-2.9,1.7
    s-1.3,1.1-1.8,1s-0.9-0.8-0.7-1.7c0.2-0.9,0.4-1.9,0.4-2.5s-0.1-1.4,0-1.5s0.4-0.1,0.4-0.1s-0.1,1.1,0.6,1.4s1.6-0.1,1.9-0.4
    s0.5-0.7,0.5-0.7s0.3,0.1,0.3,0.6s0.2,1.1,0.6,1.4C20.4,19.9,21.1,20.2,20.8,20.5z'
      />
    </svg>
  );
};
