import { IconProps } from '.';

export const Clock = ({ strokeOpacity = 1, strokeColor = 'white', ...props }) => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='9' cy='9' r='8.25' stroke={strokeColor} strokeOpacity={strokeOpacity} strokeWidth='1.5' />
      <path d='M9 3V9H13.5' stroke={strokeColor} strokeOpacity={strokeOpacity} strokeWidth='1.5' />
    </svg>
  );
};

export const ClockIcon = ({ className, color }: IconProps) => {
  return <Clock strokeColor={color} className={className} />;
};
