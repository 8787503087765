import { useLocale, useTranslate } from '@va/localization';
import { TEST_IDS } from '@va/constants';
import { ButtonLoader } from '@va/svg-visa-icons';
import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { addNumberSeparator, computePercentage } from '@va/util/helpers';
import classNames from 'classnames';
import { useMemo } from 'react';

type CounterProps = {
  consumed: number;
  total: number;
  label: string;
  className?: string;
  hasInfiniteLimit?: boolean;
};

export function Counter({ className, consumed = 0, total = 100, label, hasInfiniteLimit }: CounterProps) {
  const translate = useTranslate();
  const { locale } = useLocale();

  const remaining = useMemo(() => {
    const left = total - consumed;
    if (left < 0) return 0;
    return left;
  }, [total, consumed]);

  const progressBarWidth = useMemo(() => {
    if (consumed === 0) return 0;
    return Number.parseFloat(computePercentage(consumed, total));
  }, [consumed, total]);

  const limit = useMemo(() => {
    if (total === -1) return addNumberSeparator(consumed, locale);
    if (total === 0) {
      return <ButtonLoader color='var(--color-primary)' />;
    }
    return translate('feature.counter.counting', {
      behaviourCount: addNumberSeparator(remaining, locale),
      featureLimit: addNumberSeparator(total, locale),
    });
  }, [total, consumed, locale, translate, remaining]);

  const wrapperClasses = classNames('flex flex-col lg:min-w-[270px] cursor-pointer', className);

  return (
    <div data-testid={TEST_IDS.specific.featureCounter.btn} className={wrapperClasses}>
      <div className='flex justify-between items-center flex-row flex-nowrap gap-1'>
        <div className='flex xs:flex-row md:flex-col items-center md:items-start truncate'>
          <Paragraph
            className='truncate mr-2'
            colorClassName='text-gray-devil'
            size={paragraphSizes.tiny}
            weight={fontWeights.semibold}
          >
            {label}
          </Paragraph>

          <Paragraph
            size={paragraphSizes.extraTiny}
            colorClassName='text-primary'
            weight={fontWeights.medium}
            className={'whitespace-nowrap'}
          >
            {translate('label.moreDetails')} &rarr;
          </Paragraph>
        </div>
        <Paragraph
          colorClassName={classNames(
            { 'text-gray-charcoal': !hasInfiniteLimit },
            { 'text-orange-dark': hasInfiniteLimit },
          )}
          size={paragraphSizes.tiny}
          weight={fontWeights.semibold}
          className='flex items-center whitespace-pre'
        >
          {hasInfiniteLimit ? '∞' : limit}
        </Paragraph>
      </div>

      <div className='mt-2 h-2 rounded-sm relative'>
        {/* Progress bar */}
        <div
          className='absolute bg-orange-dark h-full z-1 top-0 lg:max-w-inherit max-w-full'
          style={{
            left: 0,
            width: `${progressBarWidth}%`,
          }}
        />
        {/* Background bar */}
        <div className='absolute bg-gray-concrete w-full h-full inset-0 z-0 lg:max-w-inherit' />
      </div>
    </div>
  );
}
