import { TEST_IDS } from '@va/constants';
import { useTranslate } from '@va/localization';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { defaultShortcuts } from '../lib/fn';

const Shortcut = ({ isRange, asSingle, shortcuts, checkifDisable, checkIfSelected, setToDate, tooltipContent }) => {
  const translate = useTranslate();

  const getShortcut = useCallback(() => {
    let newArr = [];
    if (!Array.isArray(shortcuts)) return defaultShortcuts;
    defaultShortcuts.forEach((d, i) => {
      if (shortcuts.length > 0 && shortcuts.includes(d.key)) {
        newArr.push({ ...defaultShortcuts[i], index: shortcuts.indexOf(d.key) });
      }
    });
    if (newArr.length === 0) return defaultShortcuts;
    return newArr.sort((a, b) => (a.index < b.index ? -1 : a.index > b.index ? 1 : 0));
  }, [shortcuts]);

  const onClickCustom = (e, item, key) => {
    e.preventDefault();
    setToDate(key, item);
  };

  return (
    isRange &&
    !asSingle && (
      <div className='btn-class pb-2 shortcut-wrapper relative w-full border-r border-r-black/10 lg:mr-1 lg:mb-0 lg:mx-0'>
        <ol className='shortcut grid grid-cols-2 gap-1 lg:block w-full pr-0 mt-1.5 lg:mb-0'>
          {getShortcut().map((item, i) => {
            const disable = checkifDisable(item.key, item.diff);
            return (
              <TooltipWrapper disabled={!disable} content={!!tooltipContent && tooltipContent} key={i}>
                <li data-testid={TEST_IDS.helpers.createListItemId(item.key)} key={i} className={'relative mt-[1px]'}>
                  <div
                    href='/'
                    className={classNames(
                      'flex litepie-shortcuts text-sm lg:text-xs px-2 py-2 shortcut-lineheight whitespace-nowrap font-semibold rounded text-primary transition-colors hover:bg-white-snow focus:bg-primary-100 focus:text-primary dark:hover:bg-primary-700 dark:hover:text-primary dark:text-primary dark:focus:bg-primary-700 dark:focus:text-primary    focus:bg-primary-50 focus:border-primary focus:ring focus:ring-1 focus:ring-primary  focus:ring-opacity-10 focus:outline-none btn-class cursor-pointer',
                      { 'hover:transparent text-gray-disabled cursor-not-allowed': disable },
                      {
                        'shortcut-class ring ring-primary ring-opacity-10 outline-none': checkIfSelected(
                          item.key,
                          item.diff,
                        ),
                      },
                    )}
                    onClick={(e) => !disable && onClickCustom(e, item.diff || false, item.key)}
                  >
                    {translate(`${item.transKey}`, { interval: item.diff })}
                    {item.isDefault && <div className='ml-1'>({translate('datePicker.default')})</div>}
                  </div>
                </li>
              </TooltipWrapper>
            );
          })}
        </ol>
      </div>
    )
  );
};

export default Shortcut;

Shortcut.propTypes = {
  setToCustomShortcut: PropTypes.func,
  shortcuts: PropTypes.array,
  asSingle: PropTypes.bool,
  isRange: PropTypes.bool,
  checkifDisable: PropTypes.func,
  checkIfSelected: PropTypes.func,
  setToDate: PropTypes.func,
  tooltipContent: PropTypes.string,
};
