import { useTranslate } from '@va/localization';
import { FilterInputComponentRefType } from '@va/types/filters';
import { TextInput } from '@va/ui/design-system';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { filterValidationKeys } from '../../constants';
import { useFiltersContext } from '../../filters-context';
import { useFilterValidation } from '../../useFilterValidation';
import { KeyValueFilter, KeyValueFilterInputProps } from './KeyValueFilter';

export const KeyValueFilterInput = forwardRef<
  FilterInputComponentRefType,
  { filter: KeyValueFilter } & KeyValueFilterInputProps
>(({ filter, keyLabel, valueLabel }, ref) => {
  const [keyData, setKeyData] = useState<string | undefined>(undefined);
  const [valueData, setValueData] = useState<string | undefined>(undefined);

  const { applySingleNewFilter } = useFiltersContext();

  const translate = useTranslate();

  const onSubmit = useCallback(() => {
    const clone = Object.assign({}, filter);

    clone.values = [{ [keyData as string]: valueData ?? '' }];
    applySingleNewFilter(clone);
  }, [applySingleNewFilter, filter, valueData, keyData]);

  const { validate, handleSubmit, error } = useFilterValidation<
    { key: string | undefined; value: string | undefined },
    { key: string; value: string }
  >({
    onSubmit,
    value: { key: keyData, value: valueData },
    validationFunc: (val) => {
      const err = { key: '', value: '' };
      if (!val.key) {
        err.value = filterValidationKeys.required;
      }

      if (!val.value) {
        err.key = filterValidationKeys.required;
      }

      if (!err.key || !err.value) {
        return;
      }

      return err;
    },
  });

  useImperativeHandle(ref, () => ({ submit: handleSubmit }));

  return (
    <div className='space-y-1'>
      <TextInput
        value={keyData}
        label={keyLabel}
        onChange={(e) => {
          const val = e.target.value;
          setKeyData(val);
          validate({ key: val, value: valueData });
        }}
        clearField={() => {
          setKeyData(undefined);
        }}
        error={translate(error?.key ?? '')}
      />
      <TextInput
        value={valueData}
        label={valueLabel}
        error={translate(error?.value ?? '')}
        onChange={(e) => {
          const val = e.target.value;
          setValueData(val);
          validate({ key: keyData, value: val });
        }}
        clearField={() => {
          setValueData(undefined);
        }}
      />
    </div>
  );
});
