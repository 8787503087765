type Filter = {
  from: moment.Moment;
  until: moment.Moment;
  search: string;
  start: number;
  length: number;
  onlySsrSessions: boolean;
};

/** @deprecated */
export function areFiltersEqual(filterA: Filter, filterB: Filter) {
  const fromDiff = filterA.from.diff(filterB.from);
  const untilDiff = filterA.until.diff(filterB.until);

  // TODO use lodash.isEqual
  return (
    fromDiff === 0 &&
    untilDiff === 0 &&
    filterA.search === filterB.search &&
    filterA.start === filterB.start &&
    filterA.length === filterB.length &&
    filterA.onlySsrSessions === filterB.onlySsrSessions
  );
}

/**
 * @deprecated Only used in few places, can probably be deleted
 */
export const nFormatter = (num: number, digits = 0) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const reg = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(num) >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(reg, '$1') + si[i].symbol;
};
