export function makeAction(type: string, ...argNames: (string | undefined)[]) {
  return function (...args: any) {
    const action: { type: string; [key: string]: any } = { type };
    argNames.forEach((arg, index) => {
      if (!arg) return;
      action[arg] = args[index];
    });
    return action;
  };
}
