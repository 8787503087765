import { makeAction } from '@va/store';

export const VISITOR_REQUEST = 'VISITOR_REQUEST';
export const VISITOR_SUCCEEDED = 'VISITOR_REQUEST_SUCCEEDED';
export const VISITOR_FAILED = 'VISITOR_REQUEST_FAILED';

export const EXPORT_VISITS_REQUEST = 'EXPORT_VISITS_REQUEST';
export const EXPORT_VISITS_SUCCEEDED = 'EXPORT_VISITS_SUCCEEDED';
export const EXPORT_VISITS_FAILED = 'EXPORT_VISITS_FAILED';

export const EXPORT_VISITS_PAGE_REQUEST = 'EXPORT_VISITS_PAGE_REQUEST';
export const EXPORT_VISITS_PAGE_SUCCEEDED = 'EXPORT_VISITS_PAGE_SUCCEEDED';
export const EXPORT_VISITS_PAGE_FAILED = 'EXPORT_VISITS_PAGE_FAILED';

export const EXPORT_VISITS_CANCEL = 'EXPORT_VISITS_CANCEL';

export const exportVisits = makeAction(EXPORT_VISITS_REQUEST, 'filter', 'format', 'translate');
export const exportVisitsSucceeded = makeAction(EXPORT_VISITS_SUCCEEDED, 'data');
export const exportVisitsFailed = makeAction(EXPORT_VISITS_FAILED, 'error');

export const exportVisitsPage = makeAction(EXPORT_VISITS_PAGE_REQUEST, 'filter');
export const exportVisitsPageSucceeded = makeAction(EXPORT_VISITS_PAGE_SUCCEEDED, 'data');
export const exportVisitsPageFailed = makeAction(EXPORT_VISITS_PAGE_FAILED, 'error');

export const exportVisitsCancel = makeAction(EXPORT_VISITS_CANCEL);
