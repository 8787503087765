import { isCompanyRevealFeatureEnabled } from '@va/dashboard/selectors/app';
import { useCheckPrivacySetting } from '@va/dashboard/util-hooks';
import { GlobeIcon, LoaderIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { PRIVACY_FEATURES } from '@va/standalone/shared/types';
import { Button, fontWeights, ListCardItemWithIcon, Paragraph } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { addNumberSeparator, appHasDarkTheme, isDefaultThemeApplied } from '@va/util/helpers';
import { useCheckOverflow } from '@va/util/hooks';
import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { TEST_IDS } from '@va/constants';
import { CompanyRevealLabel, CompanyRevealPreview, CompanyType } from '../company-reveal';
import { useVisitsByCompany } from '../useVisitsByCompany';

type IpCellProps = {
  value: string;
  companyName: string | null;
  companyType: CompanyType | null;
  countryCode: string;
};

export function IpCell({ value, companyName, companyType, countryCode }: IpCellProps) {
  return (
    <ListCardItemWithIcon
      icon={<GlobeIcon color='#696969' />}
      topText={<IpValueNode ip={value} countryCode={countryCode} />}
      bottomNode={<Ip2CompanyInfo companyName={companyName} companyType={companyType} />}
    />
  );
}

export const IpValueNode = ({
  ip,
  className,
  countryCode,
}: {
  ip: string;
  className?: string;
  countryCode: string;
}) => {
  const translate = useTranslate();
  const { isTrackingEnabled } = useCheckPrivacySetting();
  const isPageHistoryTrackingEnabled = isTrackingEnabled(PRIVACY_FEATURES.individualPageHistory, countryCode);
  if (!(ip === '-' || isPageHistoryTrackingEnabled))
    return (
      <TooltipWrapper content={translate('dashboard.latestVisitors.consentless.ip.hiddenInfo')}>
        <span className={className}>{translate('dashboard.latestVisitors.ip.hidden')}</span>
      </TooltipWrapper>
    );

  return <span className={className}>{translate('dashboard.latestVisitors.ip.value', { ip })}</span>;
};

export function Ip2CompanyInfo({ companyName, companyType }: Pick<IpCellProps, 'companyName' | 'companyType'>) {
  const [shouldFetchData, setShouldFetchData] = useState(false);

  const showIp2Company = useSelector(isCompanyRevealFeatureEnabled);
  const queryFilter = { companyOrgName: companyName ?? '', pageSize: 1, page: 1, hideCompanyOrgName: false };
  const { isLoading, data, isValidating } = useVisitsByCompany(queryFilter, shouldFetchData);
  const visitsCount = useMemo(() => data?.items[0]?.visits ?? 0, [data?.items]);

  const pRef = useRef<HTMLParagraphElement>(null);

  const isOverflowing = useCheckOverflow(pRef);

  if (!showIp2Company) return <CompanyRevealPreview />;
  if (!companyName) return null;

  return (
    <TooltipWrapper
      interactive
      tooltipClassNames='!rounded-3xl'
      content={
        <CompanyVisitsTooltip
          company={isOverflowing ? { name: companyName, type: companyType } : undefined}
          isLoading={isLoading}
          companyVisits={visitsCount}
        />
      }
    >
      <div
        data-testid={TEST_IDS.generic.companyReveal.label}
        onMouseEnter={() => !isValidating && setShouldFetchData(true)}
      >
        <CompanyRevealLabel ref={pRef} className='truncate' companyName={companyName} companyType={companyType} />
      </div>
    </TooltipWrapper>
  );
}

function CompanyVisitsTooltip({
  isLoading,
  companyVisits,
  company,
}: {
  isLoading: boolean;
  companyVisits: number;
  company?: { name: string; type: CompanyType | null };
}) {
  const isDarkThemeApplied = appHasDarkTheme();
  const translate = useTranslate();
  const history = useHistory();
  const { locale } = useLocale();

  if (isLoading)
    return (
      <div className='flex justify-center items-center w-screen max-w-[500px] py-4 lg:py-0 lg:w-auto'>
        <LoaderIcon color={!isDarkThemeApplied ? 'white' : undefined} />
      </div>
    );
  return (
    <div
      data-testid={TEST_IDS.generic.companyReveal.tooltipContent}
      className='flex flex-col items-center gap-3 overflow-hidden max-h-350 p-4 text-center'
    >
      {company && (
        <CompanyRevealLabel
          companyName={company.name}
          companyType={company.type}
          className={classNames({ '!text-white': isDefaultThemeApplied() })}
        />
      )}

      <Paragraph weight={fontWeights.medium}>
        {translate('all.ip2companyVisitsTooltip.visitsCount', { count: addNumberSeparator(companyVisits, locale) })}
      </Paragraph>
      <Button
        data-testid={TEST_IDS.generic.buttons.seeMore}
        text={translate('button.showMore')}
        onClick={() => history.push(`/visitors/company-visits`)}
      />
    </div>
  );
}
