import { platform, UPDATE_WEBSITES_EVENT, websiteFlagKeys } from '@va/constants';
import { requestWebsiteSucceeded, updateWebsiteFlag } from '@va/dashboard/actions/api';
import { setInstanceId } from '@va/dashboard/actions/app';
import { useAddNewWebsite } from '@va/dashboard/api-client/Website';
import { useTranslate } from '@va/localization';
import { AddWebsiteForm } from '@va/standalone/core/shared/onboarding';
import { mutateWebsites, setActiveWebsite } from '@va/standalone/shared/actions';
import { storageItems } from '@va/standalone/shared/constants';
import { LocalStorage } from '@va/util/helpers';
import { EventBus } from '@va/util/misc';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const AddWebsiteUrlStep = ({ onSuccess }: { onSuccess: () => void }) => {
  const { execute: addNewWebsite, isLoading, error, isSucceeded, data: createdWebsite } = useAddNewWebsite();

  const translate = useTranslate();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    ({ url }: { url: string }) => {
      addNewWebsite({ url, platform: platform.CUSTOM, source: 'dashboard' });
    },
    [addNewWebsite],
  );

  useEffect(() => {
    if (isSucceeded && createdWebsite) {
      dispatch(setInstanceId(createdWebsite.id));
      dispatch(setActiveWebsite(createdWebsite.id));
      dispatch(requestWebsiteSucceeded(createdWebsite));
      dispatch(updateWebsiteFlag(websiteFlagKeys.CREATION_PROCESS_COMPLETED, false));
      LocalStorage.setItem(storageItems['lastWebsiteId'], createdWebsite.id);
      EventBus.dispatch(UPDATE_WEBSITES_EVENT);
      onSuccess();
      dispatch(mutateWebsites());
    }
  }, [createdWebsite, dispatch, isSucceeded, onSuccess]);

  return (
    <AddWebsiteForm
      subtitle={translate('standalone.websiteCreation.websiteUrl.subtitle')}
      title={translate('standalone.websiteCreation.websiteUrl.title')}
      isLoading={isLoading}
      error={error}
      onSubmit={onSubmit}
    />
  );
};

export default AddWebsiteUrlStep;
