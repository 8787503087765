export const RadioButtonThick = ({ selected = false, className = '' }) => {
  const outerCircleColor = selected ? 'var(--color-primary)' : '#C3C3C3';
  const innerCircleColor = selected ? 'var(--color-primary)' : '#FFFFFF';
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12' r='11.25' stroke={outerCircleColor} strokeWidth='1.5' />
      <circle cx='12' cy='12' r='9' fill={innerCircleColor} />
    </svg>
  );
};
