import { fontWeightClassName, fontWeights } from '@va/ui/design-system';
import classNames from 'classnames';
import { forwardRef, PropsWithChildren } from 'react';
import { HeadingProps } from './types';

export const Heading5 = forwardRef<HTMLHeadingElement, PropsWithChildren<HeadingProps>>(
  ({ weight = fontWeights.normal, colorClassName = 'text-gray-code', className, children }, ref) => {
    const classes = classNames(
      `text-21 leading-30 -tracking-0021 font-primary`,
      fontWeightClassName(weight),
      colorClassName,
      className,
    );

    return (
      <h5 ref={ref} className={classes}>
        {children}
      </h5>
    );
  },
);
