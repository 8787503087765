import { apiStatus } from '@va/constants';
import Immutable from 'immutable';
import { createSelector } from 'reselect';

/**
 * @param state
 * @param {String} apiRequestName
 * @return {Object}
 *
 * @deprecated This selector is deprecated use {@link getApiRequestImmutable} selector - usage of .toJS() is strongly discouraged
 */
export const getApiRequest = (state, apiRequestName) =>
  state.getIn(['core', 'api', apiRequestName], Immutable.Map()).toJS();

export const getApiRequestImmutable = (state, apiRequestName) =>
  state.getIn(['core', 'api', apiRequestName], Immutable.Map());

export const getSettings = createSelector(
  [(state) => state.getIn(['core', 'api', 'settings'], Immutable.Map()).toJS()],
  (settings) => settings,
);

export const getNotificationSettings = (state) =>
  state.getIn(['core', 'api', 'notificationSettings'], Immutable.Map()).toJS();

export const getExportVisits = (state) => state.getIn(['core', 'api', 'exportVisits'], Immutable.Map());

export const getExportVisitors = (state) => state.getIn(['core', 'api', 'exportVisitors'], Immutable.Map());

export const getRequestStatus = (state, request) => state.getIn(['core', 'api', request, 'status'], apiStatus.NONE);

export const isInProgress = (state, request) => getRequestStatus(state, request) === apiStatus.IN_PROGRESS;

export const getAgreement = (state) => state.getIn(['core', 'api', 'getAgreement'], Immutable.Map());

export const getAnnouncementsSelector = (state) => state.getIn(['core', 'api', 'getAnnouncements', 'data'])?.toJS();

export const getAccountInformation = (state) =>
  state.getIn(['core', 'api', 'accountInformation'], Immutable.Map()).toJS();
